import React, { useEffect, useState } from "react";
import styles from "./Assessment.module.scss";
import Button from "../../components/Button/Button";
import useIsScreenWidth from "../../hooks/useIsScreenWidth";
import { ArrowRight } from "react-feather";

function AssessmentHelpWith(props) {
    const { setCurrentStage, helpWithFilters, setAssessmentSelection, preFill, mixpanelTrack } = props;
    const [currentHelpWith, setCurrentHelpWith] = useState([]);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (preFill["q1"]) {
            setCurrentHelpWith(preFill["q1"].helpWithIds);
            setDisabled(false);
        }
        mixpanelTrack("Assessment Q1", "Page View", true);
    }, []);

    useEffect(() => {
        if (currentHelpWith.length === 0) {
            return setDisabled(true);
        }

        return setDisabled(false);
    }, [currentHelpWith]);

    const selectHelpWith = (id) => {
        if (currentHelpWith?.includes(id)) {
            const index = currentHelpWith.indexOf(id);
            currentHelpWith.splice(index, 1);
            setCurrentHelpWith([...currentHelpWith]);
            return setAssessmentSelection("q1", { helpWithIds: [...currentHelpWith] });
        }
        setCurrentHelpWith([...currentHelpWith, id]);
        return setAssessmentSelection("q1", { helpWithIds: [...currentHelpWith, id] });
    };

    const nextStep = () => {
        console.log(currentHelpWith);
        const infoArray = currentHelpWith.map((id) => {
            const foundValue = helpWithFilters.find((filter) => filter?.id === id);
            if (foundValue) {
                return {
                    id: foundValue.id,
                    value: foundValue.value,
                };
            }
        });
        mixpanelTrack(
            "Assessment Q1",
            "User Response",
            infoArray.filter((item) => !!item),
        );
        setCurrentStage(2);
    };

    return (
        <>
            <div className={styles.helpWithContainer}>
                <h1>What do you need help with?</h1>
                <div className={styles.helpWithItems}>
                    {helpWithFilters.map((item, i) => {
                        return (
                            <div
                                className={
                                    currentHelpWith?.includes(item.id) ? styles.helpWithSelected : styles.helpWith
                                }
                                key={i}
                                onClick={() => selectHelpWith(item.id)}
                            >
                                <img
                                    className={styles.helpWithImg}
                                    key={`icon${item.id}`}
                                    src={`/app${item.icon}`}
                                    alt=""
                                />
                                {item.value}
                            </div>
                        );
                    })}
                </div>
                {useIsScreenWidth(720) && (
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                            marginTop: "30px",
                            justifyContent: "flex-end",
                            width: "100%",
                        }}
                    >
                        <Button onClick={() => nextStep()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                )}
            </div>
            {!useIsScreenWidth(720) && (
                <div className={styles.mobileBottomNav}>
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                            justifyContent: "flex-end",
                            width: "100%",
                        }}
                    >
                        <Button onClick={() => nextStep()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default AssessmentHelpWith;
