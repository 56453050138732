import React from "react";
import styles from "./ShareProfileForm.module.scss";
import { Copy, UserPlus } from "react-feather";
import Button from "../../Button/Button";
import useGetProfilePicture from "../../../hooks/useGetProfilePicture";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TwitterIcon,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon,
    FacebookIcon,
} from "react-share";

const classnames = require("classnames");

export default function ShareProfileForm(props) {
    const advisor = props.advisor;
    const profilePicture = useGetProfilePicture(
        advisor.firmId && advisor.firmId > 0 ? advisor.professionalId : advisor.cognitoUsername,
    );

    const body = "Hey! Check out this Profile on AdFi!";
    const title = "Check out this AdFi Profile";
    const shareUrl = window.location.href;

    function cancelButton() {
        props.onClose();
    }

    function copyLink() {
        navigator.clipboard.writeText(window.location.href.toString());
    }

    return (
        <>
            <div className={styles.intoFormContainer}>
                <div className={styles.profileContainer}>
                    {profilePicture && (
                        <img
                            loading="lazy"
                            className={classnames(
                                styles.profilePicture,
                                !(profilePicture === "/app/images/profilePictureDefault.svg")
                                    ? styles.gradientBorder
                                    : styles.pupleBorder,
                            )}
                            src={profilePicture}
                            alt="profile_picture"
                        ></img>
                    )}
                    <div className={styles.profileInfoContainer}>
                        <div className={styles.advisorNameText}>{advisor.displayName}</div>
                        <div className={styles.locationText}>
                            {advisor.city}, {advisor.state}
                        </div>
                        <div className={styles.verifiedAdFi}>
                            <UserPlus size={20} />
                            <div>Financial Advisor</div>
                        </div>
                    </div>
                </div>

                <span className={styles.shareText}>Share {advisor.firstName}'s profile with friends and family</span>
                <div className={styles.linkBox}>
                    {window.location.href}
                    <Copy
                        size={20}
                        color="var(--adfi_blue500)"
                        style={{ cursor: "pointer" }}
                        onClick={() => copyLink()}
                    />
                </div>
                <span className={styles.shareText}>Share over social media</span>
                <div className={styles.socialMediaContainer}>
                    <TwitterShareButton url={shareUrl} title={title} className={styles.shareButton}>
                        <TwitterIcon size={40} round />
                    </TwitterShareButton>

                    <LinkedinShareButton url={shareUrl} className={styles.shareButton}>
                        <LinkedinIcon size={40} round />
                    </LinkedinShareButton>

                    <FacebookShareButton url={shareUrl} quote={title} className={styles.shareButton}>
                        <FacebookIcon size={40} round />
                    </FacebookShareButton>

                    <EmailShareButton url={shareUrl} subject={title} body={body} className={styles.shareButton}>
                        <EmailIcon size={40} round />
                    </EmailShareButton>
                </div>
            </div>
            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>
                    Close
                </Button>
                <Button className="modal-cancel-button" color="gradient-button" onClick={() => copyLink()}>
                    Copy Link
                    <Copy size={20} />
                </Button>
            </div>
        </>
    );
}
