import styles from "./CalendlyInfo.module.scss";
import { React } from "react";
export default function CalendlyInfo(props) {
    const text = props.text;

    return (
        <>
            <div className={styles.serviceContainer}>
                <div className={styles.serviceDescription}>{text}</div>
            </div>
        </>
    );
}
