import React from "react";
import styles from "./Assessment.module.scss";

function AssessmentProgressBar(props) {
    const stageToWidthMap = {
        1: 0,
        2: 15,
        3: 30,
        4: 45,
        5: 70,
        6: 85,
        7: 100,
    };

    const fillerStyles = {
        transition: "width 1s ease-in-out",
        width: `${stageToWidthMap[props.currentStage]}%`,
    };

    return (
        <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={fillerStyles} />
        </div>
    );
}

export default AssessmentProgressBar;
