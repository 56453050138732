import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useParams } from "react-router-dom";
import { signUp } from "aws-amplify/auth";
import { useNavigate } from "react-router";
import { ArrowRight } from "react-feather";
import validateConfirmPassword from "../../Utils/validateConfirmPassword";
import advisorProfileService from "../../services/advisorProfile.service";
import firmAdminService from "../../services/firmAdmin.service";
import ErrorBanner from "../../components/ErrorBanner/ErrorBanner";
import "./Register.scss";
import ClipLoader from "react-spinners/ClipLoader";
import FormModalNotCloseable from "../../components/Modals/FormModalNotCloseable";
import CalendlyInfo from "../../components/Forms/CalendlyInfo/CalendlyInfo";

function Register() {
    let { professionalId, email } = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [inputDisabled, setInputDisabled] = useState(true);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [infoModalText, setInfoModalText] = useState("");
    const [modalText, setModalText] = useState("");
    const [errorText, setErrorText] = useState("Something went wrong. Please try again.");
    const [isAdvisor, setIsAdvisor] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        setShowErrorBanner(false);

        const fetchData = async () => {
            try {
                const [advisorResponse, firmAdminResponse] = await Promise.allSettled([
                    advisorProfileService.getOne(professionalId),
                    firmAdminService.getOne(professionalId),
                ]);
                if (advisorResponse.value || firmAdminResponse.value) {
                    advisorResponse.value ? setIsAdvisor(true) : setIsAdvisor(false);
                    if (
                        advisorResponse?.value?.appStatus === "unverified" ||
                        firmAdminResponse?.value?.appStatus === "unverified"
                    ) {
                        setInfoModalText("Not Yet Verified");
                        setModalText(
                            "Your application has not yet been verified. Please return to this page once you receive an email saying you have been approved.",
                        );
                        setShowInfoModal(true);
                    } else if (
                        advisorResponse?.value?.appStatus === "denied" ||
                        firmAdminResponse?.value?.appStatus === "denied"
                    ) {
                        setInfoModalText("Application Denied");
                        setModalText("Your application has been denied.");
                        setShowInfoModal(true);
                    } else {
                        setInputDisabled(false);
                    }
                } else {
                    setInfoModalText("Application Not Yet Created");
                    setModalText(
                        "You have not yet applied to AdFi - please visit https://advisorfinder.com/app/advisor-apply to submit your application",
                    );
                    setShowInfoModal(true);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [professionalId]);

    useEffect(() => {
        if (password === "" || confirmPassword === "") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [password, confirmPassword]);

    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        const validate = validateConfirmPassword({ password, confirmPassword });

        if (Object.keys(validate).length === 0) {
            try {
                setDisabled(true);
                setShowErrorBanner(false);
                await signUpUser();
            } catch (error) {
                setShowErrorBanner(true);
                throw new Error(error);
            } finally {
                setLoading(false);
            }
        } else {
            setErrors(validate);
        }
        setLoading(false);
    }

    async function signUpUser() {
        setShowErrorBanner(false);
        try {
            const username = email;
            await signUp({
                username,
                password,
                options: {
                    userAttributes: {
                        "custom:id": professionalId,
                    },
                },
            });
            isAdvisor
                ? navigate(`/register/verify/${"Advisor"}/${email}/`, { replace: true })
                : navigate(`/register/verify/${"FirmAdmin"}/${email}/`, { replace: true });
        } catch (error) {
            console.log(error.name);
            if (error.name === "UsernameExistsException") {
                isAdvisor
                    ? navigate(`/register/verify/${"Advisor"}/${email}/`, { replace: true })
                    : navigate(`/register/verify/${"FirmAdmin"}/${email}/`, { replace: true });
            } else {
                console.log("error signing up:", error);
                setErrorText(error.message);
                setShowErrorBanner(true);
            }
        }
    }

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            <FormModalNotCloseable show={showInfoModal} title={infoModalText}>
                <CalendlyInfo text={modalText}></CalendlyInfo>
            </FormModalNotCloseable>
            <div className="page-container">
                <div className="register-container left">
                    <div className="logo-container">
                        <a href="https://www.advisorfinder.com" rel="noopener">
                            <img
                                loading="lazy"
                                src="/app/AdFi-logo-name.svg"
                                alt="/AdFi-logo-name.svg"
                                className="register-logo"
                            />
                        </a>
                    </div>
                    <div className="form-container">
                        <img src="/app/sign-up-email.svg" alt="/sign-up-email.svg" />
                        <h1 className="register-header">Congratulations on being accepted into the AdFi network</h1>
                        <p className="register-paragraph">
                            We are excited that you've chosen us to help you kickstart your digital business development
                            journey. Please complete your account creation form below.
                        </p>
                        <p className="register-paragraph">
                            Already created an account?{" "}
                            <Link
                                to={`/register/verify/${isAdvisor ? "Advisor" : "FirmAdmin"}/${email}/?resendCode=true`}
                            >
                                Verify email here
                            </Link>
                        </p>
                        <form className="register-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <input
                                    className="input full"
                                    type="email"
                                    id="email"
                                    disabled={true}
                                    placeholder={email}
                                />
                            </div>
                            <div className="input-container half-container">
                                <input
                                    className="input half"
                                    type="password"
                                    id="password"
                                    placeholder="Password"
                                    disabled={inputDisabled}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <input
                                    className="input half"
                                    type="password"
                                    id="confirmpassword"
                                    placeholder="Confirm password"
                                    disabled={inputDisabled}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            <div style={{ color: "grey", opacity: "90%", textAlign: "left" }}>
                                Must consist of 8+ characters, upper and lowercase characters, 1 digit and 1 special
                                symbol
                            </div>
                            {errors.password && <p className="error-message">{errors.password}</p>}
                            <div className="input-container" style={{ marginBottom: "20px" }}>
                                <Button color="clear-button" type="submit" disabled={disabled}>
                                    Join the AdFi Platform
                                    <ArrowRight size={20} />
                                </Button>
                            </div>
                            <span>
                                If you are having trouble logging in, please email support here: team@joinadfi.com
                            </span>
                        </form>
                        {showErrorBanner && (
                            <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errorText}</ErrorBanner>
                        )}
                    </div>
                </div>
                <div className="register-container right">
                    <div className="right-side-text">
                        <h1 className="register-header">Welcome to the modern way of growing your business</h1>
                        <p className="register-paragraph">
                            You’re one step away from beginning your digital business development journey. We’re excited
                            to help you elevate your entire online presence and start having future clients come to you!
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
