import { DragOverlay, defaultDropAnimationSideEffects } from "@dnd-kit/core";
import { React } from "react";
const dropAnimationConfig = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: "0.4",
            },
        },
    }),
};

export function SortableOverlay({ children }) {
    return <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>;
}
