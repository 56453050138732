import { useEffect, useState } from "react";

export default function useIsScreenWidth(width) {
    const [isScreenWidth, setIsScreenWidth] = useState(window.innerWidth >= width);

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const updateMedia = () => {
        setIsScreenWidth(window.innerWidth >= width);
    };

    return isScreenWidth;
}
