import React, { useState, useEffect } from "react";
import styles from "./AdminDashboard.module.scss";
import Button from "../../components/Button/Button";
import { User, CheckCircle, XCircle } from "react-feather";
import { useNavigate } from "react-router";
import advisorProfileService from "../../services/advisorProfile.service";
import firmAdminService from "../../services/firmAdmin.service";
import FormModal from "../../components/Modals/FormModal";
import ConfirmForm from "../../components/Forms/ConfirmForm/ConfirmForm";
import ClipLoader from "react-spinners/ClipLoader";

function AdminDashboard() {
    const navigate = useNavigate();

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [unverifiedAdvisors, setUnverifiedAdvisors] = useState([]);
    const [notLoggedInAdvisors, setNotLoggedInAdvisors] = useState([]);
    const [verifiedAdvisors, setVerifiedAdvisors] = useState([]);
    const [unverifiedFirmAdmin, setUnverifiedFirmAdmin] = useState([]);
    const [verifiedFirmAdmin, setVerifiedFirmAdmin] = useState([]);

    const [appStatusDecision, setAppStatusDecision] = useState("verified");
    const [advisorApp, setAdvisorApp] = useState({});
    const [isAdvisor, setIsAdvisor] = useState(true);
    const [loading, setLoading] = useState(true);

    // const [unverifiedPageCount, setUnverifiedPageCount] = useState(0);
    // const [verifiedPageCount, setVerifiedPageCount] = useState(0);

    const advisorLimit = 200;

    useEffect(async () => {
        if (!JSON.parse(localStorage.getItem("user")).groups.includes("Admin")) {
            navigate(`/404`, { replace: true }); // create access denied page
        }
        setLoading(true);

        const [unverifiedProfessionals, notLoggedInProfessionals, verifiedProfessionals, firmAdmin] = await Promise.all(
            [
                advisorProfileService.getAll("unverified", `?limit=${advisorLimit}&offset=0`),
                advisorProfileService.getAll("noLoggedIn", `?limit=${advisorLimit}&offset=0`),
                advisorProfileService.getAll("active", `?limit=${advisorLimit}&offset=0`),
                firmAdminService.getAll(`?limit=${advisorLimit}&offset=0`),
            ],
        );
        let filterUnverifiedFirmAdmin = (firmAdmin.unverifiedFirmAdmin ?? []).sort(
            (a, b) => b.memberSince - a.memberSince,
        );
        let filterVerifiedFirmAdmin = (firmAdmin.activeFirmAdmin ?? []).sort((a, b) => b.memberSince - a.memberSince);

        setUnverifiedAdvisors(unverifiedProfessionals.professionals);
        setNotLoggedInAdvisors(notLoggedInProfessionals.professionals);
        setVerifiedAdvisors(verifiedProfessionals.professionals);
        setUnverifiedFirmAdmin(filterUnverifiedFirmAdmin);
        setVerifiedFirmAdmin(filterVerifiedFirmAdmin);
        setLoading(false);
    }, []);

    function handleDecision(application, status, isAdvisor) {
        setAppStatusDecision(status);
        setAdvisorApp(application);
        setShowConfirmModal(true);
        setIsAdvisor(isAdvisor);
    }

    // const handlePageClick = async (type, pageCount) => {
    //     //Send changed page and offset back to parent
    //     if (type === 'unverified') {
    //         const response = await advisorProfileService.getAll('unverified', `?limit=${advisorLimit}&offset=${advisorLimit * pageCount}`);
    //         setUnverifiedAdvisors(response.professionals);
    //         setUnverifiedPageCount(pageCount);
    //     }
    //     if (type === 'active') {
    //         const response = await advisorProfileService.getAll('active', `?limit=${advisorLimit}&offset=${advisorLimit * pageCount}`);
    //         setVerifiedAdvisors(response.professionals);
    //         setVerifiedPageCount(pageCount);
    //     }
    // };

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            <div className={`${styles.pageContainer} ${styles.sideNavMargin}`}>
                <FormModal
                    onClose={() => setShowConfirmModal(false)}
                    show={showConfirmModal}
                    title={"Confirm Application Decision"}
                >
                    <ConfirmForm
                        status={appStatusDecision}
                        advisorApp={advisorApp}
                        setUnverifiedAdvisors={setUnverifiedAdvisors}
                        unverifiedAdvisors={unverifiedAdvisors}
                        setUnverifiedFirmAdmin={setUnverifiedFirmAdmin}
                        unverifiedFirmAdmin={unverifiedFirmAdmin}
                        isAdvisor={isAdvisor}
                    ></ConfirmForm>
                </FormModal>
                <div className={styles.adminHeader}>
                    <User size={24} color={"var(--adfi_blue500)"} />
                    <div className={styles.adminHeaderText}>Admin Dashboard</div>
                </div>

                <div className={styles.applicationListContainer}>
                    <div className={styles.adminHeaderText}>Unverified Advisors</div>
                    {unverifiedAdvisors.length > 0 &&
                        unverifiedAdvisors.map((advisor, index) => (
                            <div key={index} className={styles.applicationContainer}>
                                <div className={styles.profileInfoContainer}>
                                    <div className={styles.locationText}>
                                        {advisor.city}, {advisor.state}
                                    </div>
                                    <div className={styles.advisorNameText}>
                                        {advisor.firstName} {advisor.lastName}
                                    </div>
                                    <div className={styles.locationText}>Email: {advisor.email}</div>
                                    <div className={styles.locationText}>CRD: {advisor.crd}</div>
                                    <div className={styles.jobTitle}>{advisor.jobTitle} at</div>
                                    <div className={styles.employerText}>{advisor.companyName}</div>
                                    <label className={styles.optionRow}>
                                        <input
                                            value="Finra Match?"
                                            checked={advisor.finraMatch}
                                            disabled
                                            type="checkbox"
                                        />
                                        <span className={styles.checkmark}></span>
                                        <span>Finra Match?</span>
                                    </label>
                                </div>
                                <div className={styles.appButtons}>
                                    <Button
                                        color="grey-button green"
                                        onClick={() => handleDecision(advisor, "verified", true)}
                                    >
                                        <CheckCircle size={20} />
                                        Approve
                                    </Button>
                                    <Button
                                        color="grey-button red"
                                        onClick={() => handleDecision(advisor, "denied", true)}
                                    >
                                        <XCircle size={20} />
                                        Deny
                                    </Button>
                                </div>
                            </div>
                        ))}
                    <div>
                        {/* <Button color='gradient-button' onClick={() => handlePageClick('unverified', unverifiedPageCount - 1)}>
                            <ArrowLeft size={20} />
                            Last Page
                        </Button>
                        <Button color='gradient-button' onClick={() => handlePageClick('unverified', unverifiedPageCount + 1)}>
                            Next Page
                            <ArrowRight size={20} />
                        </Button> */}
                    </div>
                </div>

                <div className={styles.applicationListContainer}>
                    <div className={styles.adminHeaderText}>Unverified FIRM ADMINS</div>
                    {unverifiedFirmAdmin.length > 0 &&
                        unverifiedFirmAdmin.map((firmAdmin, index) => (
                            <div key={index} className={styles.applicationContainer}>
                                <div className={styles.profileInfoContainer}>
                                    <div className={styles.advisorNameText}>
                                        {firmAdmin.firstName} {firmAdmin.lastName}
                                    </div>
                                    <div className={styles.locationText}>Email: {firmAdmin.email}</div>
                                    <div className={styles.locationText}>Phone Number: {firmAdmin.phoneNumber}</div>
                                    <div className={styles.employerText}>{firmAdmin.firmId}</div>
                                </div>
                                <div className={styles.appButtons}>
                                    <Button
                                        color="grey-button green"
                                        onClick={() => handleDecision(firmAdmin, "verified", false)}
                                    >
                                        <CheckCircle size={20} />
                                        Approve
                                    </Button>
                                    <Button
                                        color="grey-button red"
                                        onClick={() => handleDecision(firmAdmin, "denied", false)}
                                    >
                                        <XCircle size={20} />
                                        Deny
                                    </Button>
                                </div>
                            </div>
                        ))}
                </div>

                <div className={styles.applicationListContainer}>
                    <div className={styles.adminHeaderText}>Verified FIRM ADMINS</div>
                    <div className={styles.verifiedAdvisorsContainer}>
                        {verifiedFirmAdmin.length > 0 &&
                            verifiedFirmAdmin.map((firmAdmin, index) => (
                                <div key={index} className={styles.verifiedAdvisorContainer}>
                                    <div className={styles.profileInfoContainer}>
                                        <div className={styles.advisorNameText}>
                                            {firmAdmin.firstName} {firmAdmin.lastName}
                                        </div>
                                        <div className={styles.locationText}>Email: {firmAdmin.email}</div>
                                        <div className={styles.locationText}>Phone Number: {firmAdmin.phoneNumber}</div>
                                        <div className={styles.employerText}>{firmAdmin.firmId}</div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <div className={styles.applicationListContainer}>
                    <div className={styles.adminHeaderText}>Verified Advisors Who Have Not Logged In</div>
                    <div className={styles.verifiedAdvisorsContainer}>
                        {notLoggedInAdvisors.length > 0 &&
                            notLoggedInAdvisors.map((advisor, index) => (
                                <div key={index} className={styles.verifiedAdvisorContainer}>
                                    <div className={styles.profileInfoContainer}>
                                        <div className={styles.advisorNameText}>
                                            {advisor.firstName} {advisor.lastName}
                                        </div>
                                        <div className={styles.locationText}>Email: {advisor.email}</div>
                                        <div className={styles.locationText}>
                                            ProfessionalID: {advisor.professionalId}
                                        </div>
                                        <div className={styles.locationText}>
                                            Sign Up Link: https://www.advisorfinder.com/app/register/
                                            {advisor.professionalId}/{advisor.email}{" "}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <div className={styles.applicationListContainer}>
                    <div className={styles.adminHeaderText}>Verified Advisors</div>
                    <div className={styles.verifiedAdvisorsContainer}>
                        {verifiedAdvisors.length > 0 &&
                            verifiedAdvisors.map((advisor, index) => (
                                <div key={index} className={styles.verifiedAdvisorContainer}>
                                    <div className={styles.profileInfoContainer}>
                                        <div className={styles.advisorNameText}>
                                            {advisor.firstName} {advisor.lastName}
                                        </div>
                                        <div className={styles.locationText}>Email: {advisor.email}</div>
                                        <div className={styles.locationText}>CRD: {advisor.crd}</div>
                                        <div className={styles.jobTitle}>
                                            {advisor.jobTitle} at {advisor.companyName}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        {/* <Button color='gradient-button' onClick={() => handlePageClick('active', verifiedPageCount - 1)}>
                            <ArrowLeft size={20} />
                            Last Page
                        </Button>
                        <Button color='gradient-button' onClick={() => handlePageClick('active', verifiedPageCount + 1)}>
                            Next Page
                            <ArrowRight size={20} />
                        </Button> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDashboard;
