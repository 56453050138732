import validator from "validator";

function validateAdvisorApply(values) {
    let errors = {};

    if (!validator.matches(values.firstName, "^[a-zA-Z-,.' -]*$")) {
        errors.name = "Enter valid first and last name";
    }

    if (!validator.matches(values.lastName, "^[a-zA-Z-,.' -]*$")) {
        errors.name = "Enter valid first and last name";
    }

    if (!validator.isEmail(values.email)) {
        errors.email = "Enter valid email";
    }

    if (!validator.isNumeric(values.zipCode) || values.zipCode.length !== 5) {
        errors.zipCode = "Enter valid zip code (5 digits)";
    }

    if (!validator.isMobilePhone(values.phoneNumber)) {
        errors.phoneNumber = "Enter valid phone number";
    }

    if (!validator.isNumeric(values.crd) || values.crd.length > 8 || values.crd.length < 6) {
        errors.crd = "Enter valid CRD number";
    }

    return errors;
}

export default validateAdvisorApply;
