import React from "react";
import styles from "./TextInput.module.scss";
import classNames from "classnames";

export default function TextInput(props) {
    const { value, onChange, disabled, type, placeholder, error, className } = props;
    let height;
    const errorStyle = error ? styles.hasError : "";

    switch (props.height) {
        case "xs":
            height = styles.heightXS;
            break;
        case "s":
        case "small":
            height = styles.heightS;
            break;
        case "l":
        case "large":
            height = styles.heightL;
            break;
        case "xl":
            height = styles.heightXL;
            break;
        default:
            height = styles.heightDefault;
            break;
    }

    return (
        <div className={classNames(className, styles.inputContainer)}>
            <input
                className={classNames(styles.input, height, errorStyle)}
                type={type}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
            />
            {error && <p className={styles.errorMessage}>{error}</p>}
        </div>
    );
}
