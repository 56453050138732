import validator from "validator";

function validateExternalLinks(values) {
    let errors = {};

    if (values.linkedInText) {
        if (!validator.isURL(values.linkedInText) || !values.linkedInText.includes("linkedin")) {
            errors.linkedInText = "Enter a valid LinkedIn url";
        }
    }

    if (values.twitterText) {
        if (!validator.isURL(values.twitterText) || !values.twitterText.includes("twitter")) {
            errors.twitterText = "Enter a valid Twitter url";
        }
    }

    if (values.websiteText) {
        if (!validator.isURL(values.websiteText)) {
            errors.websiteText = "Enter a valid personal website link";
        }
    }

    return errors;
}

export default validateExternalLinks;
