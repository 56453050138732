import React from "react";

export const advisorFinderDisclosure = (
    <div>
        To maintain a verified listing on this Website, a financial firm or financial professional must pay a
        subscription fee to AdvisorFinder (
        <a href="https://www.advisorfinder.com/for-financial-advisors/pricing" target="_blank" rel="noreferrer">
            please click here for details
        </a>
        ). AdvisorFinder is not a client of any firm or advisor listed on this Website (
        <a href="https://www.advisorfinder.com/license-agreement" target="_blank" rel="noreferrer">
            advisors are subject to this agreement
        </a>
        ). Fees are not specifically payable in consideration of any "endorsement". AdvisorFinder search results are
        conferred based on standardized and automated factors without regard to any financial firm compensation to
        AdvisorFinder. However, the compensation paid by a financial firm or financial professional to AdvisorFinder
        creates a material conflict of interest of which prospective clients of a financial firm should be aware.
    </div>
);
