import React from "react";
import styles from "./Dropdown.module.scss";
import classNames from "classnames";

export default function Dropdown(props) {
    const { value, onChange, children, disabled, error } = props;
    let height;

    switch (props.height) {
        case "xs":
            height = styles.heightXS;
            break;
        case "s":
        case "small":
            height = styles.heightS;
            break;
        case "l":
        case "large":
            height = styles.heightL;
            break;
        case "xl":
            height = styles.heightXL;
            break;
        default:
            height = styles.heightDefault;
            break;
    }

    return (
        <>
            <select
                className={classNames(styles.dropdown, height)}
                type="dropdown"
                value={value}
                disabled={disabled}
                onChange={onChange}
            >
                {children}
            </select>
            {error && <p className={styles.errorMessage}>{error}</p>}
        </>
    );
}
