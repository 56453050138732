import React, { useState, useEffect } from "react";
import styles from "./Assessment.module.scss";
import { ICON_MAPPINGS } from "../Marketplace/Filters/FilterMappings";
import Button from "../../components/Button/Button";
import { ArrowRight, ArrowLeft, Info } from "react-feather";
import useIsScreenWidth from "../../hooks/useIsScreenWidth";

function AssessmentIndustry(props) {
    const { setCurrentStage, industryFilters, setAssessmentSelection, preFill, mixpanelTrack } = props;
    const [currentIndustryType, setCurrentIndustryType] = useState(0);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (preFill["q2"]) {
            setCurrentIndustryType(preFill["q2"].industryTypeIds[0]);
            setDisabled(false);
        }
        mixpanelTrack("Assessment Q2", "Page View", true);
    }, []);

    const selectIndustryType = (id) => {
        if (currentIndustryType === id) {
            setDisabled(true);
            return setCurrentIndustryType(0);
        }

        setDisabled(false);
        setCurrentIndustryType(id);
        setAssessmentSelection("q2", { industryTypeIds: [id] });
    };

    function mapIcon(id) {
        const mapping = ICON_MAPPINGS.find((mapping) => id === mapping.key);
        return mapping.icon;
    }

    const nextStep = () => {
        console.log(currentIndustryType);

        const foundValue = industryFilters.find((filter) => filter.id === currentIndustryType);
        const infoArray = {
            id: foundValue.id,
            value: foundValue.value,
        };

        mixpanelTrack("Assessment Q2", "User Response", infoArray);
        setCurrentStage(3);
    };

    return (
        <>
            <div className={styles.industryContainer}>
                <h1 className={styles.verticaloffsetmedium}>What industry do you work in?</h1>
                <div className={styles.industryTypesContainer}>
                    {industryFilters.map((item) => {
                        return (
                            <div
                                className={
                                    currentIndustryType === item.id ? styles.industryTypeSelected : styles.industryType
                                }
                                key={item.id}
                                onClick={() => selectIndustryType(item.id)}
                            >
                                {mapIcon(item.icon)}
                                {item.value}
                            </div>
                        );
                    })}
                </div>
                {useIsScreenWidth(720) && (
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                            marginTop: "30px",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Button color="clear-button white" onClick={() => setCurrentStage(1)}>
                            <ArrowLeft size={20} />
                            Back
                        </Button>
                        <Button onClick={() => nextStep()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.infoMessageContainer}>
                <Info size={20} color="#ABAAB3" />{" "}
                <span>
                    This will help you narrow down your choices of financial advisors who likely have clients who work
                    in the same industry as you. Remember, not all financial advisors have the same industry knowledge.
                </span>
            </div>
            {!useIsScreenWidth(720) && (
                <div className={styles.mobileBottomNav}>
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Button color="clear-button white" onClick={() => setCurrentStage(1)}>
                            <ArrowLeft size={20} />
                            Back
                        </Button>
                        <Button onClick={() => nextStep()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default AssessmentIndustry;
