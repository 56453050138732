import React from "react";
import styles from "./ErrorBanner.module.scss";
import { X, AlertTriangle } from "react-feather";

export default function ErrorBanner(props) {
    return (
        <div className={props.flex ? styles.bannerContainer : styles.bannerContainerAbsolute}>
            <AlertTriangle size={16} color="var(--adfi_red500)" className={styles.triange} />
            {props.children}
            <X size={16} color="var(--adfi_dark200)" className={styles.bannerX} onClick={props.onClick}></X>
        </div>
    );
}
