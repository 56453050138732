import React, { useState, useEffect } from "react";
import styles from "./Filters.module.scss";
import States from "./States";
//import AccountSize from './AccountSize'
import HelpWith from "./HelpWith";
import Industrytype from "./IndustryType";
import LifeStage from "./LifeStage";
import Button from "../../../components/Button/Button";
import { RotateCcw } from "react-feather";

export default function Filters(props) {
    const forceHelpWith = props.forceHelpWith;
    const { currentState, currentIndustryType, currentLifeStage, currentHelpWith } = props;
    const { setCurrentState, setCurrentIndustryType, setCurrentLifeStage, setCurrentHelpWith } = props;
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (
            !currentState &&
            !currentIndustryType &&
            !currentLifeStage &&
            (!currentHelpWith || currentHelpWith.length === 0)
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [currentHelpWith, currentIndustryType, currentLifeStage, currentState]);

    const clearFilters = () => {
        setCurrentState(undefined);
        if (!props.hideIndustry) setCurrentIndustryType(0);
        if (!props.hideLifeStage) setCurrentLifeStage(0);
        setCurrentHelpWith(forceHelpWith ? [forceHelpWith] : []);
        props.handleClearFilters();
    };

    const applyFilters = () => {
        props.handleSelectedFilters();
    };

    return (
        <>
            <div className={styles.formContainer}>
                {!props.hideStates && <States currentState={currentState} setCurrentState={setCurrentState} />}
                <div className={styles.greyLineDivider} />
                <HelpWith
                    currentHelpWith={currentHelpWith}
                    setCurrentHelpWith={setCurrentHelpWith}
                    helpWithFilters={props.helpWithFilters}
                    forceHelpWith={forceHelpWith}
                />
                <div className={styles.greyLineDivider} />
                {!props.hideIndustry && (
                    <Industrytype
                        currentIndustryType={currentIndustryType}
                        setCurrentIndustryType={setCurrentIndustryType}
                        industryFilters={props.industryFilters}
                    />
                )}
                <div className={styles.greyLineDivider} />
                {!props.hideLifeStage && (
                    <LifeStage
                        currentLifeStage={currentLifeStage}
                        setCurrentLifeStage={setCurrentLifeStage}
                        lifeStageFilters={props.lifeStageFilters}
                    />
                )}
            </div>
            <div className={styles.footerContainer}>
                <Button color="clear-button" onClick={() => clearFilters()}>
                    <RotateCcw size={20} />
                    Clear Filters
                </Button>
                <Button onClick={() => applyFilters()} disabled={disabled}>
                    Apply Filters
                </Button>
            </div>
        </>
    );
}
