import React, { useState, useEffect } from "react";
import styles from "./ContactAdvisorForm.module.scss";
import { ArrowRight } from "react-feather";
import Button from "../../Button/Button";
import consumerProfileService from "../../../services/consumerProfile.service";
import validateContactAdvisor from "../../../Utils/validateContactAdvisor";
import useMixPanelEvent from "../../../Utils/MixPanel";
import ClipLoader from "react-spinners/ClipLoader";

//const classnames = require('classnames');

export default function ContactAdvisorForm(props) {
    const mixpanelTrack = useMixPanelEvent();

    const advisor = props.advisor;
    const currentUser = JSON.parse(localStorage.getItem("user"));

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [meetingDetails, setMeetingDetails] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [submited, setSubmited] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (currentUser) {
            if (currentUser.firstName && currentUser.lastName)
                setFullName(`${currentUser.firstName} ${currentUser.lastName}`);
            setEmail(currentUser.email);
        }
    }, []);

    useEffect(() => {
        if (!fullName || !email || !meetingDetails) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [fullName, email, meetingDetails]);

    const validateInput = (e) => {
        e.preventDefault();
        setErrors({});
        const validate = validateContactAdvisor({ fullName, email, meetingDetails });

        if (Object.keys(validate).length !== 0) {
            setErrors(validate);
            return;
        } else {
            onSubmit();
        }
    };

    async function onSubmit() {
        setSubmitDisabled(true);
        setLoading(true);
        const myInit = {
            body: {
                consumerId: currentUser?.id,
                professionalId: advisor.professionalId,
                advisorEmail: advisor.email,
                consumerName: fullName,
                consumerEmail: email,
                meetingFocus: meetingDetails,
                meetingFormat: "messageAdvisor",
            },
        };

        const fromMarketplace = window.location.href.includes("contact=true")
            ? "- Marketplace CTA"
            : "- Advisor Profile CTA";

        mixpanelTrack(`Advisor Contacted ${fromMarketplace}`, "Meeting Info", myInit.body);

        try {
            await consumerProfileService.createMeetingRequest(myInit);
            setSubmited(true);
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitDisabled(false);
            setLoading(false);
        }
    }

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            {!submited && (
                <>
                    <div className={styles.formContainer}>
                        <div className={styles.headerContainer}>
                            <h1>
                                Contact {advisor.firstName} {advisor.lastName}
                            </h1>
                            <ol>
                                <li>The Advisor will receive your message and contact information.</li>
                                <li>The advisor will reach out within a few days to schedule a follow-up meeting.</li>
                            </ol>
                        </div>

                        <div className={styles.inputContainer}>
                            <span>Please enter your full name</span>
                            <input
                                className={styles.input}
                                type="name"
                                id="name"
                                placeholder="Your full name"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                            {errors.fullName && <p className="error-message">{errors.fullName}</p>}
                        </div>
                        <div className={styles.inputContainer}>
                            <span>Please enter your email</span>
                            <input
                                className={styles.input}
                                type="email"
                                id="email"
                                value={email}
                                placeholder="Email Address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <p className="error-message">{errors.email}</p>}
                        </div>
                        <div className={styles.inputContainer}>
                            <div>Enter your message to the advisor</div>
                            <textarea
                                className={styles.infoTextArea}
                                placeholder="Enter your message to the advisor"
                                onChange={(e) => setMeetingDetails(e.target.value)}
                            ></textarea>
                            {errors.meetingDetails && <p className="error-message">{errors.meetingDetails}</p>}
                        </div>
                    </div>
                    <div className={styles.formFooter}>
                        <span>
                            By submitting this form, I confirm I read the{" "}
                            <a href="https://www.advisorfinder.com/terms-of-service">Terms of Service</a> and agree to
                            them.
                        </span>
                        <Button
                            className="modal-cancel-button"
                            color="gradient-button"
                            onClick={(e) => validateInput(e)}
                            disabled={submitDisabled}
                            type="submit"
                        >
                            Submit
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                </>
            )}
            {submited && (
                <div className={styles.formContainerCompleted}>
                    <img
                        loading="lazy"
                        src="/app/images/meetingRequested.svg"
                        alt="meetingRequest"
                        className={styles.completeImg}
                    ></img>
                    <h1 className={styles.submitText}>Thank you for contacting {advisor.firstName}</h1>
                    <span className={styles.submitSubtext}>
                        The advisor will receive your message and contact information, and will reach out within a few
                        days to schedule a follow-up meeting.
                    </span>
                    <Button onClick={() => props.setShowContactAdvisorModal(false)}>Back to profile</Button>
                </div>
            )}
        </>
    );
}
