import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import advisorProfileService from "../../services/advisorProfile.service";
import { useNavigate } from "react-router";
import ClipLoader from "react-spinners/ClipLoader";

export default function AdvisorConfirmMeeting() {
    const navigate = useNavigate();
    const [status, setStatus] = useState(null);
    const [meetingId, setMeetingId] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    //meetingConfirm?status=denied&id=4

    useEffect(() => {
        if (
            !(
                JSON.parse(localStorage.getItem("user")).groups.includes("Advisor") ||
                JSON.parse(localStorage.getItem("user")).groups.includes("FirmAdmin")
            )
        ) {
            navigate(`/404`, { replace: true });
        } else {
            const queryParams = new URLSearchParams(location.search);
            const tempStatus = queryParams.get("status");
            const tempMeetingID = queryParams.get("id");
            setMeetingId(tempMeetingID);
            setStatus(tempStatus);
        }
    }, []);

    useEffect(async () => {
        if (status === "denied" || status === "approved") {
            const columnData = [
                {
                    status,
                },
            ];
            const myInit = {
                body: {
                    columnData,
                },
            };
            try {
                await advisorProfileService.updateMeetingRequest(meetingId, myInit);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    }, [meetingId, status]);

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            {status === "approved" ? (
                <div className="form-container apply">
                    <img src="/app/images/MeetingApprovedImg.svg" alt="meeting-approved"></img>
                    <h1 className="register-header">Your Meeting Request has been successfully confirmed!</h1>
                </div>
            ) : (
                <div className="form-container apply">
                    <img src="/app/images/MeetingDeniedImg.svg" alt="meeting-denied"></img>
                    <h1 className="register-header">Your Meeting Request has been declined.</h1>
                </div>
            )}
        </>
    );
}
