const Reducer = (state, action) => {
    switch (action.type) {
        case "SET_SPECIALTIES":
            return {
                ...state,
                specialties: action.payload,
            };
        case "SET_CLIENTELE":
            return {
                ...state,
                clientele: action.payload,
            };
        case "SET_PRICING":
            return {
                ...state,
                pricing: action.payload,
            };
        case "SET_SERVICES":
            return {
                ...state,
                services: action.payload,
            };
        case "SET_ASSETRANGES":
            return {
                ...state,
                assetRanges: action.payload,
            };
        case "SET_CREDENTIALS":
            return {
                ...state,
                credentials: action.payload,
            };
        case "SET_MARKETPLACE_MAPPINGS":
            return {
                ...state,
                marketplaceMappings: action.payload,
            };
        case "SET_ERROR":
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default Reducer;
