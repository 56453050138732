import React, { useContext, useState } from "react";
import styles from "./DisableAccountForm.module.scss";
import { Info, Check } from "react-feather";
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import consumerProfileService from "../../../services/consumerProfile.service";
import firmAdminService from "../../../services/firmAdmin.service";
import { UserContext } from "../../../Utils/UserContext";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router";
import { signOut } from "aws-amplify/auth";

const classnames = require("classnames");

export default function DisableAccountForm(props) {
    const user = props.user;
    const { setUser } = useContext(UserContext);
    const enable = props.enable;
    const isAdvisor = user.groups.includes("Advisor");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function cancelButton() {
        props.onClose();
    }

    async function onSubmit() {
        setLoading(true);
        const columnData = [
            {
                accountEnabled: enable,
            },
        ];

        const myInit = {
            body: {
                columnData,
            },
        };

        try {
            if (user.groups.includes("Advisor")) {
                await advisorProfileService.update(user.id, myInit);
            } else if (user.groups.includes("FirmAdmin")) {
                await firmAdminService.update(user.id, myInit);
            } else {
                await consumerProfileService.update(user.id, myInit);
            }

            if (!enable) {
                await signOut();
                setUser(null);
                navigate(`/`, { replace: true });
            }
            setLoading(false);
            props.onClose();
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    function modalText() {
        if (isAdvisor) {
            return (
                <>
                    <div>Your profile will no longer be displayed in The Marketplace.</div>
                    <div>You will be able to enable your account when you log back in.</div>
                </>
            );
        }

        return `You will be able to enable your account when you log back in.`;
    }

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}

            {!enable && (
                <div className={styles.intoFormContainer}>
                    <div className={classnames(styles.bannerContainer, styles.redBackground)}>
                        <Info size={20} color={"var(--adfi_red500)"} />{" "}
                        <span className={styles.bannerText}>Are you sure you want to disable your account?</span>
                    </div>
                    <h3>{modalText()}</h3>
                </div>
            )}

            {enable && (
                <div className={styles.intoFormContainer}>
                    <div className={classnames(styles.bannerContainer, styles.greenBackground)}>
                        <Info size={20} color={"var(--adfi_green500)"} />{" "}
                        <span className={styles.bannerText}>Are you sure you want to enable your account?</span>
                    </div>
                </div>
            )}

            <div className="modal-footer">
                {!enable && (
                    <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>
                        Cancel
                    </Button>
                )}
                <Button className="modal-cancel-button" color="gradient-button" onClick={onSubmit}>
                    <Check size={20} />
                    Confirm
                </Button>
            </div>
        </>
    );
}
