import { getUrl } from "aws-amplify/storage";
import { useEffect, useState } from "react";

export default function useGetProfilePicture(cognitoUsername) {
    const [url, setUrl] = useState(null);

    useEffect(() => {
        if (!cognitoUsername) setUrl("/app/images/profilePictureDefault.svg");
        else {
            getUrl({
                key: `profilePictures/${cognitoUsername}_profilePicture.png`,
                options: {
                    validateObjectExistence: true,
                },
            })
                .then((response) => {
                    setUrl(response.url.href);
                })
                .catch(() => {
                    setUrl("/app/images/profilePictureDefault.svg");
                });
        }
    }, [cognitoUsername]);

    return url;
}
