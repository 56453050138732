import React from "react";
import styles from "./ScheduleCallNoUserForm.module.scss";
import Button from "../../Button/Button";
import { Calendar } from "react-feather";

export default function ScheduleCallComplete(props) {
    const scheduleCallRedirect = () => {
        props.setShowScheduleCallInfoClicked(false);
        props.setShowScheduleCallModal(true);
    };

    return (
        <>
            <div className={styles.scheduleSection}>
                <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Meeting Expectations Explained</h1>
                <div className={styles.meetingsListContainer}>
                    <div className={styles.meetingItemContainer}>
                        <div className={styles.infoClickedListNumber}>
                            <b>1</b>
                        </div>
                        <div>
                            <h2>No Obligations</h2>
                            <p>
                                The intro call is meant to help you and your advisor get to know each other and see if
                                there's a sense of compatibility.
                            </p>
                        </div>
                    </div>
                    <div className={styles.meetingItemContainer}>
                        <div className={styles.infoClickedListNumber}>
                            <b>2</b>
                        </div>
                        <div>
                            <h2>Questions from You</h2>
                            <p>
                                You'll use the intro meeting to ask the advisor questions about themselves, their
                                business, and their personal life to help you determine if there's a connection there.
                            </p>
                        </div>
                    </div>
                    <div className={styles.meetingItemContainer}>
                        <div className={styles.infoClickedListNumber}>
                            <b>3</b>
                        </div>
                        <div>
                            <h2>Questions from the Advisor</h2>
                            <p>
                                The advisor will ask you similar questions to help them get to know you. You can expect
                                to be asked questions about why you wanted to meet with them, your job, and your
                                personal life.
                            </p>
                        </div>
                    </div>
                    <div className={styles.meetingItemContainer}>
                        <div className={styles.infoClickedListNumber}>
                            <b>4</b>
                        </div>
                        <div>
                            <h2>Scheduling the Next Meeting</h2>
                            <p>
                                If you and the advisor get along, you'll likely schedule a second meeting to talk more
                                about finances and what you need help with.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.modalFooterInfoClicked}>
                <Button className="clear-button white" onClick={() => scheduleCallRedirect()}>
                    <Calendar size={20} />
                    Schedule a Call
                </Button>
            </div>
        </>
    );
}
