import React, { useState, useEffect, useContext } from "react";
import ReactPlayer from "react-player";
import styles from "./ProfessionalProfile.module.scss";
import { getCurrentUser } from "aws-amplify/auth";
import { uploadData } from "aws-amplify/storage";
import { ChevronDown, Edit2, UserPlus, Camera, PlusCircle } from "react-feather";
import FormModal from "../Modals/FormModal";
import IntroForm from "../Forms/IntroForm/IntroForm";
import DisclosureTextForm from "../Forms/DisclosureTextForm/DisclosureTextForm";
import ProfPicForm from "../Forms/ProfPicForm/ProfPicForm";
import QuickFacts from "./QuickFacts/QuickFacts";
import Specialties from "./Specialties/Specialties";
import SpecialitiesForm from "../Forms/SpecialitiesForm/SpecialitiesForm";
import ServiceAddForm from "../Forms/ServiceAddForm/ServiceAddForm";
import EducationAddForm from "../Forms/EducationAddForm/EducationAddForm";
import JobHistoryAddForm from "../Forms/JobHistoryAddForm/JobHistoryAddForm";
import JobHistory from "./JobHistory/JobHistory";
import ServicesProvided from "./ServicesProvided/ServicesProvided";
import Credentials from "./Credentials/Credentials";
import Education from "./Education/Education";
import VerifiedByAdfi from "../Forms/VerifiedByAdfi/VerifiedByAdfi";
import { Context } from "../../Utils/GlobalStore/Store";
import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import advisorProfileService from "../../services/advisorProfile.service";
import AdditionalDetailsForm from "../Forms/AdditionalDetailsForm/AdditionalDetailsForm";

const classnames = require("classnames");

export default function ProfessionalProfile(props) {
    const [state] = useContext(Context);

    const [showMore, setShowMore] = useState(false);
    const [showMore2, setShowMore2] = useState(false);
    const [showIntroModal, setShowIntroModal] = useState(false);
    const [showDisclosureModal, setShowDisclosureModal] = useState(false);
    const [showProfPicModal, setShowProfPicModal] = useState(false);
    const [showSpecialitiesModal, setShowSpecialitiesModal] = useState(false);
    const [showClienteleModal, setShowClienteleModal] = useState(false);
    const [showAddServiceModal, setShowAddServiceModal] = useState(false);
    const [showVerifiedByAdfiModal, setShowVerifiedByAdfiModal] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [showAddCredentialsModal, setShowAddCredentialsModal] = useState(false);
    const [selectedCredential, setSelectedCredential] = useState(null);
    const [showAddEducationModal, setShowAddEducationModal] = useState(false);
    const [selectedUniversity, setSelectedUniversity] = useState(null);
    const [showAddJobHistoryModal, setShowAddJobHistoryModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [showAdditionalDetailsModal, setShowAdditionalDetailsModal] = useState(false);

    const editMode = props.editMode;
    const firmAdminAuth = props.firmAdminAuth;
    const [advisor, setAdvisor] = useState(props.advisor);
    const profilePictureURL = useGetProfilePicture(
        advisor.firmId && advisor.firmId > 0 ? advisor.professionalId : advisor.cognitoUsername,
    );
    const [specialties, setSpecialities] = useState(advisor.specialties);
    const [clientele, setClientele] = useState(advisor.clientele);
    const [services, setServices] = useState(advisor.services);
    const [credentials, setCredentials] = useState(advisor.credentials);
    const [education, setEducation] = useState(advisor.education);
    const [jobHistory, setJobHistory] = useState(advisor.jobHistory);
    const [clientNumber, setClientNumber] = useState(advisor.clientNumber ? advisor.clientNumber : "");
    const [averageAccountSize, setAverageAccountSize] = useState(
        advisor.averageAccountSize ? advisor.averageAccountSize : "",
    );

    const [profilePicture, setProfilePicture] = useState("");
    const [profilePictureFile, setProfilePictureFile] = useState("");
    const [showImgOverlay, setShowImgOverlay] = useState(false);

    const [isDesktop, setDesktop] = useState(window.innerWidth > 1040);

    let fileInput = React.createRef();

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1040);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    }, []);

    useEffect(async () => {
        if (profilePictureFile) {
            try {
                await onProcessFile();
                const columnData = [
                    {
                        profilePicture: true,
                    },
                ];

                const myInit = {
                    body: {
                        columnData: columnData,
                    },
                };
                await Promise.all([
                    advisorProfileService.update(advisor.professionalId, myInit, firmAdminAuth),
                    updateCompletenessScore(),
                ]);
            } catch (error) {
                console.log(error);
            }
        }
    }, [profilePictureFile]);

    useEffect(() => {
        let tempAdvisor = advisor;
        tempAdvisor.services = services;
        props.setAdvisor(tempAdvisor);
    }, [services]);

    useEffect(() => {
        if (selectedService) {
            setShowAddServiceModal(true);
        } else if (selectedCredential) {
            setShowAddCredentialsModal(true);
        } else if (selectedUniversity) {
            setShowAddEducationModal(true);
        } else if (selectedJob) {
            setShowAddJobHistoryModal(true);
        }
    }, [selectedService, selectedCredential, selectedUniversity, selectedJob]);

    useEffect(() => {
        setProfilePicture(profilePictureURL);
    }, [profilePictureURL]);

    async function updateCompletenessScore() {
        const completenessScore = await advisorProfileService.getCompletenessScore(advisor.professionalId);
        props.setCompletenessScore(completenessScore?.toString());
        props.setCompletenessStyle({ width: `${completenessScore}%` });
    }

    function handleCloseAddServiceModal() {
        setShowAddServiceModal(false);
        setSelectedService(null);
    }

    function handleCloseCredentialsModal() {
        setShowAddCredentialsModal(false);
        setSelectedCredential(null);
    }

    function handleCloseEducationModal() {
        setShowAddEducationModal(false);
        setSelectedUniversity(null);
    }

    function handleCloseJobModal() {
        setShowAddJobHistoryModal(false);
        setSelectedJob(null);
    }

    function handleCloseVerifiedByAdfiModal() {
        setShowVerifiedByAdfiModal(false);
    }

    function hasSocialLinks() {
        if (advisor.advisorWebsiteURL || advisor.linkedInURL || advisor.twitterURL) {
            return true;
        }

        return false;
    }

    function socialRedirect(link) {
        if (link.includes("https://")) {
            return window.open(link, "_blank");
        }

        return window.open("https://" + link, "_blank");
    }

    async function onProcessFile() {
        let reader = new FileReader();
        try {
            reader.readAsDataURL(profilePictureFile);
        } catch (err) {
            console.log(err);
        }
        reader.onloadend = () => {
            setProfilePicture(reader.result);
        };
        let { username } = await getCurrentUser();
        if (firmAdminAuth || (advisor.firmId && advisor.firmId > 0)) username = advisor.professionalId;

        try {
            uploadData({
                key: `profilePictures/${username}_profilePicture.png`,
                data: profilePictureFile,
                options: {
                    contentType: "image/png",
                },
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <div className={styles.profileContainer}>
                {showIntroModal && (
                    <FormModal
                        onClose={() => setShowIntroModal(false)}
                        show={showIntroModal}
                        title={"Your Introduction"}
                        setUser={setAdvisor}
                    >
                        <IntroForm
                            user={advisor}
                            isAdvisor={true}
                            updateCompletenessScore={updateCompletenessScore}
                            firmAdminAuth={firmAdminAuth}
                        ></IntroForm>
                    </FormModal>
                )}
                {showDisclosureModal && (
                    <FormModal
                        onClose={() => setShowDisclosureModal(false)}
                        show={showDisclosureModal}
                        title={"Advisor Disclosure"}
                        setUser={props.setAdvisor}
                    >
                        <DisclosureTextForm
                            user={props.advisor}
                            isAdvisor={true}
                            firmAdminAuth={firmAdminAuth}
                        ></DisclosureTextForm>
                    </FormModal>
                )}
                {showProfPicModal && (
                    <FormModal
                        onClose={() => setShowProfPicModal(false)}
                        show={showProfPicModal}
                        title="Profile Picture"
                    >
                        <ProfPicForm
                            setProfilePictureFile={setProfilePictureFile}
                            currentProfilePicture={
                                profilePicture === "/app/images/profilePictureDefault.svg" ? "" : profilePicture
                            }
                        ></ProfPicForm>
                    </FormModal>
                )}

                {showSpecialitiesModal && (
                    <FormModal
                        onClose={() => setShowSpecialitiesModal(false)}
                        show={showSpecialitiesModal}
                        title="Specialties and Expertise"
                    >
                        <SpecialitiesForm
                            advisor={advisor}
                            firmAdminAuth={firmAdminAuth}
                            data={specialties}
                            setData={setSpecialities}
                            updateCompletenessScore={updateCompletenessScore}
                            optionsList={state.specialties}
                            title="Specialties and Expertise"
                            bannerText="Inform customers of your areas of expertise."
                        ></SpecialitiesForm>
                    </FormModal>
                )}
                {showClienteleModal && (
                    <FormModal onClose={() => setShowClienteleModal(false)} show={showClienteleModal} title="Clientele">
                        <SpecialitiesForm
                            advisor={advisor}
                            firmAdminAuth={firmAdminAuth}
                            data={clientele}
                            setData={setClientele}
                            updateCompletenessScore={updateCompletenessScore}
                            optionsList={state.clientele}
                            title="Clientele"
                            bannerText="Let customers know some of the demographics you commonly work with."
                        ></SpecialitiesForm>
                    </FormModal>
                )}
                {showAddServiceModal && (
                    <FormModal
                        onClose={() => handleCloseAddServiceModal()}
                        show={showAddServiceModal}
                        title="Services Provided"
                    >
                        <ServiceAddForm
                            advisor={advisor}
                            firmAdminAuth={firmAdminAuth}
                            data={services}
                            setData={setServices}
                            updateCompletenessScore={updateCompletenessScore}
                            optionsList={state.services}
                            selectedService={selectedService}
                            setSelectedService={setSelectedService}
                            title="Add Service"
                            isService={true}
                        ></ServiceAddForm>
                    </FormModal>
                )}
                {showAddCredentialsModal && (
                    <FormModal
                        onClose={() => handleCloseCredentialsModal()}
                        show={showAddCredentialsModal}
                        title="Add Credentials and Designations"
                    >
                        <ServiceAddForm
                            advisor={advisor}
                            firmAdminAuth={firmAdminAuth}
                            data={credentials}
                            setData={setCredentials}
                            updateCompletenessScore={updateCompletenessScore}
                            optionsList={state.credentials}
                            selectedService={selectedCredential}
                            setSelectedService={setSelectedCredential}
                            title="Add Credentials and Designations"
                            isService={false}
                        ></ServiceAddForm>
                    </FormModal>
                )}
                {showAddEducationModal && (
                    <FormModal
                        onClose={() => handleCloseEducationModal()}
                        show={showAddEducationModal}
                        title="Add Education"
                    >
                        <EducationAddForm
                            advisor={advisor}
                            firmAdminAuth={firmAdminAuth}
                            data={education}
                            setData={setEducation}
                            updateCompletenessScore={updateCompletenessScore}
                            selectedUniversity={selectedUniversity}
                            setSelectedUniversity={setSelectedUniversity}
                        ></EducationAddForm>
                    </FormModal>
                )}
                {showAddJobHistoryModal && (
                    <FormModal
                        onClose={() => handleCloseJobModal()}
                        show={showAddJobHistoryModal}
                        title="Add Job History"
                    >
                        <JobHistoryAddForm
                            advisor={advisor}
                            firmAdminAuth={firmAdminAuth}
                            data={jobHistory}
                            setData={setJobHistory}
                            selectedJob={selectedJob}
                            setSelectedJob={setSelectedJob}
                        ></JobHistoryAddForm>
                    </FormModal>
                )}
                {showVerifiedByAdfiModal && (
                    <FormModal onClose={() => handleCloseVerifiedByAdfiModal()} show={showVerifiedByAdfiModal}>
                        <VerifiedByAdfi />
                    </FormModal>
                )}
                {showAdditionalDetailsModal && (
                    <FormModal
                        onClose={() => setShowAdditionalDetailsModal(false)}
                        show={showAdditionalDetailsModal}
                        noPadding={true}
                        title="Add Clientele Information"
                    >
                        <AdditionalDetailsForm
                            advisor={advisor}
                            setAdvisor={setAdvisor}
                            firmAdminAuth={firmAdminAuth}
                            clientNumber={clientNumber}
                            setClientNumber={setClientNumber}
                            averageAccountSize={averageAccountSize}
                            setAverageAccountSize={setAverageAccountSize}
                        />
                    </FormModal>
                )}
                <div className={styles.profileHeadshotContainer}>
                    <div className={styles.headshotInfoContainer}>
                        <a href="#">
                            <input type="file" onChange={onProcessFile} ref={fileInput} hidden={true} />
                        </a>

                        <>
                            <div className={styles.profilePicContainer}>
                                <img
                                    loading="lazy"
                                    onClick={() => (editMode ? setShowProfPicModal(true) : undefined)}
                                    className={classnames(
                                        styles.picturePreviewImg,
                                        editMode ? styles.cursorPointer : "",
                                        !(profilePicture === "/app/images/profilePictureDefault.svg")
                                            ? styles.gradientBorder
                                            : styles.pupleBorder,
                                    )}
                                    src={profilePicture ?? "/app/images/profilePictureDefault.svg"}
                                    alt={`Profile img for ${advisor.firstName} ${advisor.lastName}, financial advisor at ${advisor.companyName}`}
                                    onMouseEnter={() => editMode && setShowImgOverlay(true)}
                                ></img>
                                {showImgOverlay && (
                                    <div
                                        className={styles.imgOverlay}
                                        onClick={() => setShowProfPicModal(true)}
                                        onMouseLeave={() => editMode && setShowImgOverlay(false)}
                                    >
                                        <Camera size={40} color="var(--adfi_white)" />
                                    </div>
                                )}
                            </div>
                        </>

                        <div className={styles.profileInfoContainer}>
                            <div className={styles.locationText}>
                                {advisor.city}, {advisor.state}
                            </div>
                            <div className={styles.advisorNameText}>{advisor.displayName}</div>
                            <div className={styles.purpleText}>
                                <UserPlus size={20} />
                                <div>Financial Advisor</div>
                            </div>
                            {advisor.jobTitle && <div className={styles.jobTitle}>{advisor.jobTitle} at</div>}
                            <div className={styles.employerText}>{advisor.companyName}</div>
                        </div>
                    </div>
                    <div className={styles.advisorVerifiedSocials}>
                        <div className={styles.verifiedByAdfi} onClick={() => setShowVerifiedByAdfiModal(true)}>
                            <img loading="lazy" src="/app/shield.png" alt="" className={styles.socialLogo} />
                            <h3>Verified Identity</h3>
                        </div>
                        {hasSocialLinks() && (
                            <div className={styles.socialMediaButtonsContainer}>
                                {advisor.twitterURL && (
                                    <div
                                        className={styles.twitterButton}
                                        onClick={() => socialRedirect(advisor.twitterURL)}
                                    >
                                        <img
                                            loading="lazy"
                                            src="/app/twitterLogo.png"
                                            alt=""
                                            className={styles.socialLogo}
                                        />
                                    </div>
                                )}
                                {advisor.linkedInURL && (
                                    <div
                                        className={styles.linkedInButton}
                                        onClick={() => socialRedirect(advisor.linkedInURL)}
                                    >
                                        <img
                                            loading="lazy"
                                            src="/app/linkedinlogo.png"
                                            alt=""
                                            className={styles.socialLogoLinkedIn}
                                        />
                                    </div>
                                )}
                                {advisor.advisorWebsiteURL && (
                                    <div
                                        className={styles.socialMediaButton}
                                        onClick={() => socialRedirect(advisor.advisorWebsiteURL)}
                                    >
                                        <img loading="lazy" src="/app/globe.png" alt="" className={styles.socialLogo} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {!isDesktop && (
                    <QuickFacts
                        advisor={advisor}
                        editMode={editMode}
                        clientNumber={clientNumber}
                        averageAccountSize={averageAccountSize}
                        setShowAdditionalDetailsModal={setShowAdditionalDetailsModal}
                    />
                )}

                {(editMode || advisor.bioVideoLink || advisor.bio) && (
                    <div className={styles.advisorIntroContainer}>
                        <div>
                            <div className={styles.advisorIntroTitle}>
                                Allow Me to Introduce Myself
                                {editMode && (
                                    <Edit2
                                        onClick={() => setShowIntroModal(true)}
                                        size={16}
                                        style={{ ...{ marginLeft: 16 }, ...{ cursor: "pointer" } }}
                                    />
                                )}
                            </div>
                            {editMode && (
                                <span className={styles.headerSubText}>
                                    Provide some background details for your potential clients.
                                </span>
                            )}
                        </div>

                        <div className={styles.bioContainer}>
                            <div className={classnames(styles.advisorIntroText, !showMore && styles.clamp)}>
                                {advisor.bio}
                            </div>
                            {editMode && (
                                <div className={styles.addVideoContainer}>
                                    <div>Share social media and website links</div>
                                    <div
                                        className={styles.addVideoContainerText}
                                        onClick={() => setShowIntroModal(true)}
                                    >
                                        <PlusCircle size={16} />
                                        Add Links
                                    </div>
                                </div>
                            )}
                            {advisor.bioVideoLink && (
                                <ReactPlayer
                                    controls={true}
                                    className={styles.videoContainer}
                                    width="100%"
                                    height="100%"
                                    url={advisor.bioVideoLink}
                                />
                            )}
                        </div>
                        <div className={styles.showMore} onClick={() => setShowMore(!showMore)}>
                            <ChevronDown
                                className={classnames(styles.showMoreArrow, showMore && styles.open)}
                                size={16}
                            />
                            <div>{!showMore ? "More" : "Less"}</div>
                        </div>
                    </div>
                )}

                {/* Specialties and Expertise */}
                {(editMode || specialties?.length > 0) && (
                    <Specialties
                        advisor={advisor}
                        editMode={editMode}
                        title="Specialties and Expertise"
                        subtext="Inform customers of your areas of expertise."
                        data={specialties}
                        professionalId={advisor.professionalId}
                        firmAdminAuth={firmAdminAuth}
                        setData={setSpecialities}
                        onClick={() => {
                            setShowSpecialitiesModal(true);
                        }}
                    ></Specialties>
                )}

                {/* Clientele */}
                {(editMode || clientele?.length > 0) && (
                    <Specialties
                        advisor={advisor}
                        editMode={editMode}
                        title="Clientele"
                        subtext="Let customers know some of the demographics you commonly work with."
                        data={clientele}
                        professionalId={advisor.professionalId}
                        firmAdminAuth={firmAdminAuth}
                        setData={setClientele}
                        onClick={() => {
                            setShowClienteleModal(true);
                        }}
                    ></Specialties>
                )}

                {editMode && (
                    <ServicesProvided
                        advisor={advisor}
                        editMode={editMode}
                        data={services}
                        professionalId={advisor.professionalId}
                        firmAdminAuth={firmAdminAuth}
                        setData={setServices}
                        onClick={() => {
                            setShowAddServiceModal(true);
                        }}
                        onEdit={(service) => {
                            setSelectedService(service);
                        }}
                    ></ServicesProvided>
                )}

                {(editMode || credentials?.length > 0) && (
                    <Credentials
                        editMode={editMode}
                        data={credentials ? credentials : []}
                        professionalId={advisor.professionalId}
                        firmAdminAuth={firmAdminAuth}
                        setData={setCredentials}
                        onClick={() => {
                            setShowAddCredentialsModal(true);
                        }}
                        onEdit={(credential) => {
                            setSelectedCredential(credential);
                        }}
                    ></Credentials>
                )}

                {(editMode || education?.length > 0) && (
                    <Education
                        editMode={editMode}
                        data={education ? education : []}
                        onClick={() => {
                            setShowAddEducationModal(true);
                        }}
                        onEdit={(education, index) => {
                            setSelectedUniversity({ ...education, index });
                        }}
                    ></Education>
                )}

                {(editMode || jobHistory?.length > 0) && (
                    <JobHistory
                        editMode={editMode}
                        data={jobHistory ? jobHistory : []}
                        onClick={() => {
                            setShowAddJobHistoryModal(true);
                        }}
                        onEdit={(jobHistory) => {
                            setSelectedJob(jobHistory);
                        }}
                    ></JobHistory>
                )}

                {editMode && (
                    <div className={styles.advisorIntroContainer}>
                        <div>
                            <div className={styles.advisorIntroTitle}>
                                Advisor Disclosure
                                {editMode && (
                                    <Edit2
                                        onClick={() => setShowDisclosureModal(true)}
                                        size={16}
                                        style={{ ...{ marginLeft: 16 }, ...{ cursor: "pointer" } }}
                                    />
                                )}
                            </div>
                            {editMode && <span className={styles.headerSubText}>Add Advisor Disclosure</span>}
                        </div>

                        <div className={styles.bioContainer}>
                            <div className={classnames(styles.advisorIntroText, !showMore2 && styles.clamp)}>
                                {props.advisor.disclosureText}
                            </div>
                        </div>
                        <div className={styles.showMore} onClick={() => setShowMore2(!showMore2)}>
                            <ChevronDown
                                className={classnames(styles.showMoreArrow, showMore2 && styles.open)}
                                size={16}
                            />
                            <div>{!showMore2 ? "More" : "Less"}</div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
