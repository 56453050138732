import validator from "validator";

function validateConsumerDetails(values) {
    let errors = {};

    if (!validator.matches(values.firstName, "^[a-zA-Z-,.' -]*$")) {
        errors.firstName = "Enter valid first name";
    }

    if (!validator.matches(values.lastName, "^[a-zA-Z-,.' -]*$")) {
        errors.lastName = "Enter valid last name";
    }

    return errors;
}

export default validateConsumerDetails;
