import React, { useEffect, useState } from "react";
import { Reviews } from "./Reviews";
import "./ReviewsCarousel.scss";

export function ReviewsCarousel() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentReview, setCurrentReview] = useState({
        currentText: Reviews[0].currentText,
        currentRating: Reviews[0].currentRating,
        currentImg: "",
        currentName: Reviews[0].currentName,
    });

    const switchReview = (currentSlide) => {
        setCurrentSlide(currentSlide);
        setCurrentReview({
            currentText: Reviews[currentSlide].currentText,
            currentRating: Reviews[currentSlide].currentRating,
            currentName: Reviews[currentSlide].currentName,
        });
    };

    useEffect(() => {
        if (currentSlide === 3) {
            setTimeout(() => switchReview(0), 10000);
        } else {
            setTimeout(() => switchReview(currentSlide + 1), 10000);
        }
    }, [currentSlide]);

    return (
        <>
            <div className="carousel-container">
                <h2 style={{ color: "white", paddingBottom: "20px" }}>Trusted by advisors and clients like you</h2>
                <div className="carousel">
                    <div className="carousel-img"></div>
                    <div className="carousel-content-container">
                        <div className="carousel-review">
                            <p>{currentReview.currentText}</p>
                        </div>
                        <div className="carousel-name">
                            <p>
                                <b>-{currentReview.currentName}</b>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="carousel-buttons-container">
                    <span className={`circle ${currentSlide === 0 ? "active" : ""}`} />
                    <span className={`circle ${currentSlide === 1 ? "active" : ""}`} />
                    <span className={`circle ${currentSlide === 2 ? "active" : ""}`} />
                    <span className={`circle ${currentSlide === 3 ? "active" : ""}`} />
                </div>
            </div>
        </>
    );
}
