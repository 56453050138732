import PropTypes from "prop-types";

export const advisorProp = {
    professionalId: PropTypes.string.isRequired,
    profileCompletenessScore: PropTypes.number.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string,
    firmEmail: PropTypes.string,
    zipCode: PropTypes.string.isRequired,
    rsnipDisclosure: PropTypes.bool.isRequired,
    finraMember: PropTypes.bool.isRequired,
    profilePicture: PropTypes.bool.isRequired,
    agreedToBetaAgreement: PropTypes.bool.isRequired,
    secUrl: PropTypes.string.isRequired,
    accountEnabled: PropTypes.bool.isRequired,
    hasLoggedIn: PropTypes.bool.isRequired,
    emailStatus: PropTypes.string.isRequired,
    appStatus: PropTypes.string.isRequired,
    crd: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    city: PropTypes.string,
    memberSince: PropTypes.string,
    profession: PropTypes.string,
    bio: PropTypes.string,
    jobTitle: PropTypes.string,
    companyName: PropTypes.string,
    state: PropTypes.string,
    cognitoUsername: PropTypes.string,
    yearsOfExperience: PropTypes.number,
    calendlyUser: PropTypes.bool,
    finraMatch: PropTypes.bool,
    calendlyUrl: PropTypes.string,
    finraUrl: PropTypes.string,
    advisorOrder: PropTypes.number,
    bioVideoLink: PropTypes.string,
    disclosureText: PropTypes.string,
    distance: PropTypes.number,
    displayDistance: PropTypes.string,
    twitterURL: PropTypes.string,
    linkedInURL: PropTypes.string,
    advisorWebsiteURL: PropTypes.string,
    averageAccountSize: PropTypes.string,
    aum: PropTypes.string,
    clientNumber: PropTypes.string,
    minAccountSize: PropTypes.string,
    firmId: PropTypes.string,
    hasInsuranceLicense: PropTypes.bool,
    clientDescription: PropTypes.string,
    // credentials?: ProfessionalCredentials[];
    // education?: ProfessionalEducation[];
    // jobHistory?: ProfessionalJobHistory[];
    // pricing?: ProfessionalPricingModel[];
    // pricingV2?: PricingModels[];
    // advisorPrompts?: AdvisorPrompt[];
};
