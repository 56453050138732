import React, { useState, useEffect } from "react";
import { updateUserAttributes, resendSignUpCode, confirmUserAttribute } from "aws-amplify/auth";
import styles from "./EmailForm.module.scss";
import { Check } from "react-feather";
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import consumerProfileService from "../../../services/consumerProfile.service";
import ErrorBanner from "../../ErrorBanner/ErrorBanner";
import OtpCodeInput from "../../OtpCodeInput/OtpCodeInput";
import firmAdminService from "../../../services/firmAdmin.service";

export default function NameForm(props) {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [email, setEmail] = useState(props.user.email);
    const [otpCode, setOtpCode] = useState("");
    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [showOtpCode, setShowOtpCode] = useState(false);
    const [codeResent, setCodeResent] = useState("");

    function cancelButton() {
        props.onClose();
    }

    useEffect(() => {
        if (email === props.user.email) {
            setSubmitDisabled(true);
        } else if (!showOtpCode && email) {
            setSubmitDisabled(false);
        } else if (showOtpCode && otpCode) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }, [email, otpCode]);

    async function onSubmit() {
        setSubmitDisabled(true);
        setShowErrorBanner(false);

        try {
            if (!showOtpCode) {
                await updateUserAttributes({
                    userAttributes: {
                        email: email,
                    },
                });
                setShowOtpCode(true);
            } else {
                await confirmUserAttribute({ userAttributeKey: "email", confirmationCode: otpCode });
                const columnData = [
                    {
                        email,
                    },
                ];
                const myInit = {
                    body: {
                        columnData,
                    },
                };

                if (props.user.groups.includes("Advisor")) {
                    await advisorProfileService.update(props.user.id, myInit);
                } else if (props.user.groups.includes("FirmAdmin")) {
                    await firmAdminService.update(props.user.id, myInit);
                } else {
                    await consumerProfileService.update(props.user.id, myInit);
                }
                props.setUser({
                    ...props.user,
                    email,
                });
                setShowOtpCode(false);
                setOtpCode("");
                setSubmitDisabled(false);
                props.onClose();
            }
        } catch (error) {
            console.log(error);
            setErrors(error.message?.toString() ?? error.toString());
            setSubmitDisabled(false);
            setShowErrorBanner(true);
        }
    }

    async function resendConfirmationCode() {
        try {
            setCodeResent("");
            await resendSignUpCode({ username: email });
            setCodeResent("Code Resent");
        } catch (err) {
            console.log("error resending code: ", err);
            setCodeResent("Error resending code");
        }
    }

    return (
        <>
            <div className={styles.intoFormContainer}>
                {!showOtpCode && (
                    <div className="input-container justify-left mobile-padding">
                        <label htmlFor="email">Email</label>
                        <input
                            className="input full"
                            placeholder="Email"
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
                        />
                    </div>
                )}
                {showOtpCode && (
                    <div className={`input-container justify-left mobile-padding ${styles.otpCodeContainer}`}>
                        <p className="register-paragraph">
                            We've just sent you an email to {email}. Please check your email for a one-time code and
                            enter below.
                        </p>
                        <OtpCodeInput otp={otpCode} setOtp={setOtpCode} />
                        <div className="input-container" style={{ marginBottom: "5px" }}>
                            <Button color="clear-button no-border" onClick={resendConfirmationCode}>
                                Resend code
                            </Button>
                        </div>
                        {codeResent && <p style={{ color: "green" }}>{codeResent}</p>}
                    </div>
                )}
            </div>
            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>
                    Cancel
                </Button>
                <Button
                    className="modal-cancel-button"
                    color="gradient-button"
                    onClick={onSubmit}
                    disabled={submitDisabled}
                >
                    <Check size={20} />
                    Confirm
                </Button>
            </div>
            {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
        </>
    );
}
