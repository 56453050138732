import validator from "validator";

function validateScheduleCall(values) {
    let errors = {};

    if (!validator.matches(values.fullName, "^[a-zA-Z-,.' -]*$")) {
        errors.fullName = "Enter valid name";
    }

    if (!validator.isEmail(values.email)) {
        errors.email = "Enter valid email";
    }

    return errors;
}

export default validateScheduleCall;
