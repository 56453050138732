export const Reviews = [
    {
        currentText:
            "I’m a small independent RIA. So, I don’t have a big company with a huge marketing team behind me. AdvisorFinder is interesting to me because you guys are doing it a different way. And I’m just happy we can work together towards a common goal of bringing real financial planning to the average household, not just the top 1%",
        currentImg: "",
        currentName: "Andrea Clark, CFP®",
    },
    {
        currentText:
            "It seems like you guys give a lot more personalized touch and feel about helping advisors figure out their digital marketing strategies. Talking to you for 30 minutes could dramatically benefit an advisor’s digital marketing strategies. Your personalized touch matters more to me than anything",
        currentImg: "",
        currentName: "Blaine Thiederman, CFP®",
    },
    {
        currentText:
            "AdvisorFinder is changing the game when it comes to ‘finding a financial advisor’, glad to be a part of this! If you do not have a “brand”/following yet, then this is where AdvisorFinder can come in! Potential clients can find you here.",
        currentImg: "",
        currentName: "David Wanja Jr., CFP®",
    },
    {
        currentText:
            "AdvisorFinder is the way clients are going to find their ideal advisor match going forward. Referrals from friends and other professionals cannot compare to the depth and breadth of the advisors and services that your ideal clients can find on AdvisorFinder",
        currentImg: "",
        currentName: "Chris Randall ",
    },
];
