import styles from "./ServicesProvided.module.scss";
import { React, Edit2 } from "react-feather";

export const ServiceCard = ({ service, showServiceImg, onEdit, displayDescription, children }) => {
    return (
        <div className={`${styles.service_edit}`}>
            <Edit2
                size={16}
                className={styles.editPencil}
                onClick={() => {
                    onEdit(service);
                }}
            ></Edit2>
            {children}
            <div className={styles.serviceImg}>
                <img loading="lazy" src={showServiceImg(service.id)} alt="service_img"></img>
            </div>
            <div className={styles.serviceText_edit}>
                <span className={styles.serviceName}>{service.value}</span>
                <span className={styles.serviceDescription}>{displayDescription(service.description)}</span>
            </div>
        </div>
    );
};
