import React from "react";
import styles from "./Filters.module.scss";
import { states } from "../../../constants/states";

export default function States(props) {
    const { currentState, setCurrentState } = props;

    return (
        <>
            <div className={styles.formSection}>
                <h3>State</h3>
                <div className={styles.stateContentContainer}>
                    <div>
                        Filter by the State you want a financial advisor to live in. You can leave this blank if you are
                        open to working virtually with an advisor.
                    </div>
                    <div className={styles.filtersSelectContainer}>
                        <select
                            className={styles.filtersDropdown}
                            value={currentState ?? ""}
                            onChange={(e) =>
                                setCurrentState(parseInt(e.target.value) === 0 ? undefined : e.target.value)
                            }
                        >
                            <option className={styles.filtersDropdownValue} value={0}>
                                Please select a State
                            </option>
                            {states.map((key) => (
                                <option value={key} key={key}>
                                    {key}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
}
