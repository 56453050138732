import validator from "validator";

function validateConfirmPassword(values) {
    let errors = {};

    if (values.password !== values.confirmPassword) {
        errors.password = "Passwords do not match!";
    } else if (!validator.isStrongPassword(values.password)) {
        errors.password = "Password does not meet requirements";
    } else if (values.password === values.oldPassword) {
        errors.password = "New Password must be different than old password";
    }

    return errors;
}

export default validateConfirmPassword;
