import React, { useState, useEffect } from "react";
import styles from "./SpecialitiesForm.module.scss";
import { Search, Check, XCircle, Info } from "react-feather";
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import ErrorBanner from "../../ErrorBanner/ErrorBanner";

export default function SpecialitiesForm(props) {
    const data = props.data;
    const setData = props.setData;
    const optionsList = props.optionsList;
    const bannerText = props.bannerText;

    const [items, setItems] = useState(data);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([...optionsList]);
    const [optionsIsOpen, setOptionsIsOpen] = useState(false);

    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);

    const firmAdminAuth = props.firmAdminAuth;

    useEffect(() => {
        setFilteredOptions(optionsList);
    }, [optionsList]);

    function handleClickAway(e) {
        if (!e.relatedTarget) {
            setOptionsIsOpen(false);
        } else if (e.relatedTarget.id !== "optionsContainer") {
            setOptionsIsOpen(false);
        }
    }

    function handleChange(value) {
        setSearchText(value);
        filterOptions(value);
    }

    function cancelButton() {
        props.onClose();
    }

    function filterOptions(value) {
        let tempData = [];
        let tempFilterOptions = filteredOptions;
        tempData = tempFilterOptions.filter((filterData) => {
            const found = items.some((el) => el.value === filterData.value);
            if (found) {
                return false;
            } else if (filterData.value.toLowerCase().includes(value.toLowerCase())) {
                return filterData;
            }
            return false;
        });
        return tempData;
    }

    async function onSubmit() {
        setSubmitDisabled(true);
        setShowErrorBanner(false);
        let columnData;
        if (props.title === "Clientele") {
            columnData = [
                {
                    clientele: items,
                },
            ];
        } else {
            columnData = [
                {
                    specialties: items,
                },
            ];
        }
        const myInit = {
            body: {
                columnData,
            },
        };

        try {
            await Promise.all([
                advisorProfileService.update(props.advisor.professionalId, myInit, firmAdminAuth),
                props.updateCompletenessScore(),
            ]);
            setData(items);
            setSubmitDisabled(false);
            props.onClose();
        } catch (error) {
            setSubmitDisabled(false);
            setErrors(error.toString());
            setShowErrorBanner(true);
            console.log(error);
        }
    }

    function removeData(speciality) {
        setItems(items.filter((el) => el.value !== speciality.value));
    }

    function addData(speciality) {
        if (!speciality.sortingPriority) speciality.sortingPriority = items.length - 1;
        const tempDataSet = [...items, speciality];
        setSearchText("");
        setItems(tempDataSet);
        setOptionsIsOpen(false);
    }

    return (
        <>
            {filteredOptions && (
                <div className={styles.formContainer}>
                    <div className={styles.bannerContainer}>
                        <Info size={20} color="var(--adfi_green500)" />{" "}
                        <span className={styles.bannerText}>{bannerText}</span>
                    </div>
                    <div className={styles.searchBarWrapper}>
                        <Search className={styles.searchIcon} size={16} color="rgba(45, 43, 65, 1)" />
                        <input
                            className={styles.searchBar}
                            placeholder={`Search for ${props.title}`}
                            type="text"
                            value={searchText}
                            onChange={(e) => handleChange(e.target.value)}
                            onFocus={() => setOptionsIsOpen(true)}
                            onBlur={(e) => handleClickAway(e)}
                        />
                        <XCircle
                            className={styles.cancelIcon}
                            size={16}
                            color="rgba(171, 170, 179, 1)"
                            onClick={() => setSearchText("")}
                        />
                        {/* change to be when input is focused */}
                        {(optionsIsOpen || searchText.length > 0) && (
                            <div id="optionsContainer" className={styles.dropDownWrapper} tabIndex="0">
                                {filterOptions(searchText).map((option, index) => (
                                    <div
                                        key={index}
                                        id={`option_${index}`}
                                        className={styles.option}
                                        onClick={() => addData(option)}
                                    >
                                        {option.value}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className={styles.sectionWrapper}>
                        {items.map((speciality, index) => (
                            <div key={index} className={styles.speciality}>
                                {speciality.value}
                                <XCircle
                                    size={16}
                                    color="rgba(171, 170, 179, 1)"
                                    onClick={() => removeData(speciality)}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>
                    Cancel
                </Button>
                <Button
                    className="modal-cancel-button"
                    color="gradient-button"
                    disabled={submitDisabled}
                    onClick={onSubmit}
                >
                    <Check size={20} />
                    Confirm
                </Button>
            </div>
        </>
    );
}
