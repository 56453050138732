import React, { useState, useContext, useEffect } from "react";
import styles from "./AdvisorPersonalityForm.module.scss";
import Button from "../../Button/Button";
import Dropdown from "../../Dropdown/Dropdown";
import TextInput from "../../TextInput/TextInput";
import TextArea from "../../TextArea/TextArea";
// import Checkbox from "../../Checkbox/Checkbox";
import { Check } from "react-feather";
import useMixPanelEvent from "../../../Utils/MixPanel";

import validateAdvisorPersonality from "../../../Utils/validateAdvisorPersonality";
import { UserContext } from "../../../Utils/UserContext";
import advisorProfileService from "../../../services/advisorProfile.service";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

export default function AdditionalDetailsForm() {
    const mixpanelTrack = useMixPanelEvent();

    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [advisor, setAdvisor] = useState();
    const [loading, setLoading] = useState(false);

    const [primaryPromptOption] = useState({
        id: 0,
        text: "One thing I want my clients to know about me is…",
    });
    const [primaryPrompt, setPrimaryPrompt] = useState(""); //TODO update with api config file
    const [optionalPromptOne, setOptionalPromptOne] = useState(-1);
    const [optionalPromptOneText, setOptionalPromptOneText] = useState("");
    const [optionalPromptTwo, setOptionalPromptTwo] = useState(-1);
    const [optionalPromptTwoText, setOptionalPromptTwoText] = useState("");
    const [optionalPromptThree, setOptionalPromptThree] = useState(-1);
    const [optionalPromptThreeText, setOptionalPromptThreeText] = useState("");
    const [prompts, setPrompts] = useState([]);

    const [clienteleText, setClienteleText] = useState("");

    const [flatFeeMin, setFlatFeeMin] = useState("");
    const [flatFeeMax, setFlatFeeMax] = useState("");
    const [AUMMin, setAUMMin] = useState("");
    const [AUMMax, setAUMMax] = useState("");
    const [oneTimePlan, setOneTimePlan] = useState("");
    const [hourlyRate, setHourlyRate] = useState("");

    const [showFlatFee, setShowFlatFee] = useState(false);
    const [showAUMFee, setShowAUMFee] = useState(false);
    const [showOneTimeFee, setShowOneTimeFee] = useState(false);
    const [showHourlyFee, setShowHourlyFee] = useState(false);

    const [averageAccountSize, setAverageAccountSize] = useState();
    const [numberOfClients, setNumberOfClients] = useState();

    const [errors, setErrors] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);

    const [aumFequency, setAumFequency] = useState(-1);

    const frequencyOptions = [
        { id: "MONTHLY", displayValue: "Monthly" },
        { id: "QUARTERLY", displayValue: "Quarterly" },
        { id: "YEARLY", displayValue: "Yearly" },
    ];

    useEffect(() => {
        async function getAdvisorInfo() {
            try {
                setLoading(true);
                const promptOptions = await advisorProfileService.getPromptOptions();
                setPrompts(promptOptions);
                const advisorResponse = await advisorProfileService.getOne(user.id);
                setAdvisor(advisorResponse);
                setAverageAccountSize(advisorResponse.averageAccountSize);
                setNumberOfClients(advisorResponse.clientNumber);
                setPrimaryPrompt(advisorResponse.advisorPrompts?.at(0)?.response);
                setClienteleText(advisorResponse.clientDescription);
                setOptionalPromptOne(advisorResponse.advisorPrompts?.at(1)?.promptId);
                setOptionalPromptOneText(advisorResponse.advisorPrompts?.at(1)?.response);
                setOptionalPromptTwo(advisorResponse.advisorPrompts?.at(2)?.promptId);
                setOptionalPromptTwoText(advisorResponse.advisorPrompts?.at(2)?.response);
                setOptionalPromptThree(advisorResponse.advisorPrompts?.at(3)?.promptId);
                setOptionalPromptThreeText(advisorResponse.advisorPrompts?.at(3)?.response);

                const advisorAUMResponse = advisorResponse.pricingV2?.find((pricing) => pricing.typeName === "AUM");
                setAUMMax(advisorAUMResponse?.maxRange);
                setAUMMin(advisorAUMResponse?.minRange);
                const advisorFlatResponse = advisorResponse.pricingV2?.find(
                    (pricing) => pricing.typeName === "FLAT_FEE",
                );
                setFlatFeeMax(advisorFlatResponse?.maxRange);
                setFlatFeeMin(advisorFlatResponse?.minRange);

                switch (advisorFlatResponse?.frequency) {
                    case "MONTHLY":
                        setAumFequency("MONTHLY");
                        break;
                    case "QUARTERLY":
                        setAumFequency("MONTHLY");
                        break;
                    case "YEARLY":
                        setAumFequency("MONTHLY");
                        break;
                }

                const advisorOneTimeResponse = advisorResponse.pricingV2?.find(
                    (pricing) => pricing.typeName === "ONE_TIME",
                );
                setOneTimePlan(advisorOneTimeResponse?.cost);

                const advisorHourlyTimeResponse = advisorResponse.pricingV2?.find(
                    (pricing) => pricing.typeName === "HOURLY",
                );
                setHourlyRate(advisorHourlyTimeResponse?.rate);

                if (advisorFlatResponse) {
                    setShowFlatFee(true);
                }
                if (advisorAUMResponse) {
                    setShowAUMFee(true);
                }
                if (advisorOneTimeResponse) {
                    setShowOneTimeFee(true);
                }
                if (advisorHourlyTimeResponse) {
                    setShowHourlyFee(true);
                }

                return advisorResponse;
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        if (!user || !user.groups.includes("Advisor")) {
            navigate(`/signin`, { replace: true });
        }
        getAdvisorInfo();
    }, [user?.id]);

    const isPromptSelected = (prompt) => {
        let currentPrompts = [parseInt(optionalPromptOne), parseInt(optionalPromptTwo), parseInt(optionalPromptThree)];
        let isSelected = currentPrompts.includes(prompt.id);

        return isSelected;
    };

    function validateFields(e) {
        e.preventDefault();
        setErrors({});
        const validate = validateAdvisorPersonality({
            primaryPrompt,
            clienteleText,
            flatFeeMin,
            flatFeeMax,
            AUMMin,
            AUMMax,
            oneTimePlan,
            hourlyRate,
            averageAccountSize,
            numberOfClients,
        });

        if (Object.keys(validate).length !== 0) {
            setErrors(validate);
            console.log(validate);
            return;
        } else {
            submitForm();
        }
    }

    const submitForm = async () => {
        const advisorPrompts = [];
        setLoading(true);
        setIsDisabled(true);

        if (primaryPrompt) {
            advisorPrompts.push({
                promptId: 0,
                response: primaryPrompt,
            });
        }

        if (optionalPromptOneText) {
            advisorPrompts.push({
                promptId: parseInt(optionalPromptOne),
                response: optionalPromptOneText,
            });
        }

        if (optionalPromptTwoText) {
            advisorPrompts.push({
                promptId: parseInt(optionalPromptTwo),
                response: optionalPromptTwoText,
            });
        }

        if (optionalPromptThreeText) {
            advisorPrompts.push({
                promptId: parseInt(optionalPromptThree),
                response: optionalPromptThreeText,
            });
        }

        const pricingV2 = [];
        if (showAUMFee) {
            const aumPricing = {
                typeName: "AUM",
                minRange: AUMMin,
                maxRange: AUMMax,
            };
            pricingV2.push(aumPricing);
        }
        if (showFlatFee) {
            let frequency = aumFequency;
            const flatFeePricing = {
                typeName: "FLAT_FEE",
                minRange: flatFeeMin ?? undefined,
                maxRange: flatFeeMax ?? undefined,
                frequency: frequency ?? undefined,
            };
            pricingV2.push(flatFeePricing);
        }
        if (showOneTimeFee) {
            const oneTimePricing = {
                typeName: "ONE_TIME",
                cost: oneTimePlan ?? undefined,
            };
            pricingV2.push(oneTimePricing);
        }

        if (showHourlyFee) {
            const hourlyPricing = {
                typeName: "HOURLY",
                rate: hourlyRate ?? undefined,
            };
            pricingV2.push(hourlyPricing);
        }

        const clientNumber = numberOfClients;
        const averageAccountSizeResp = averageAccountSize;
        const clientDescriptionResp = clienteleText;

        const columnData = [
            {
                advisorPrompts,
            },
            {
                pricingV2,
            },
            {
                clientNumber,
            },
            {
                averageAccountSize: averageAccountSizeResp,
            },
            {
                clientDescription: clientDescriptionResp,
            },
        ];
        const myInit = {
            body: {
                columnData,
            },
        };

        try {
            await advisorProfileService.update(advisor.professionalId, myInit);
            const mixpanelBody = {
                ...columnData,
                userId: user.professionalId,
                timeStamp: new Date().toISOString(),
            };
            mixpanelTrack(`Advisor Profile Form Submission`, "Submission Info", mixpanelBody);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setIsDisabled(false);
            navigate(`/advisor-profile/${advisor.professionalId}/${advisor.firstName}-${advisor.lastName}/`);
        }

        return;
    };

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            <div className={styles.pageContainer}>
                <div className={styles.contentContainer}>
                    <h1 className={styles.header}>
                        <b>Financial Advisor Information Form</b>
                    </h1>
                    <div className={styles.comboInputContainer}>
                        <h1 className={styles.questionHeader}>{primaryPromptOption.text}</h1>
                        <TextArea
                            rows={4}
                            maxLength={250}
                            onChange={(e) => setPrimaryPrompt(e.target.value)}
                            value={primaryPrompt}
                            characterCounter={true}
                            error={errors.primaryPrompt}
                        />
                    </div>

                    <div className={styles.comboInputContainer}>
                        <h1 className={styles.header}>Clientele Description</h1>
                        <h1 className={styles.questionHeader}>
                            Describe your typical clients comprehensively. Include key characteristics like age range,
                            professions, financial goals, common challenges, and any specialized niches you serve. This
                            overview helps potential clients determine if they align with your expertise and focus.
                        </h1>
                        <TextArea
                            rows={5}
                            maxLength={400}
                            value={clienteleText}
                            characterCounter={true}
                            error={errors.clienteleText}
                            onChange={(e) => setClienteleText(e.target.value)}
                        />
                        {advisor?.clientele?.length > 0 && (
                            <>
                                <h1 className={styles.questionHeader} style={{ marginBottom: "5px" }}>
                                    {" "}
                                    <b>Tip:</b> Your profile shows you work with:{" "}
                                </h1>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "20px",
                                        marginBottom: "10px",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {advisor?.clientele.map((type, index) => {
                                        return (
                                            <div className={styles.clienteleBox} key={index}>
                                                {type.value}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>

                    <h1 className={styles.header}>
                        <b>Personality Prompts (optional)</b>
                    </h1>
                    <h1 className={styles.questionHeader} style={{ marginBottom: "15px", textAlign: "left" }}>
                        Prompts are an easy way to illuminate more about your personality and what makes you different
                        from other advisors. <a href="">Learn more.</a>
                    </h1>

                    <div className={styles.comboInputContainer}>
                        <span style={{ backgroundColor: "white" }}>
                            <Dropdown value={optionalPromptOne} onChange={(e) => setOptionalPromptOne(e.target.value)}>
                                <option value={-1} disabled={false}>
                                    Select a prompt
                                </option>
                                {prompts.map((prompt, i) => (
                                    <option value={prompt.id} key={i} disabled={isPromptSelected(prompt)}>
                                        {prompt.text}
                                    </option>
                                ))}
                            </Dropdown>
                        </span>
                        {parseInt(optionalPromptOne) !== -1 && (
                            <TextArea
                                rows={4}
                                maxLength={250}
                                value={optionalPromptOneText}
                                characterCounter={true}
                                onChange={(e) => {
                                    setOptionalPromptOneText(e.target.value);
                                }}
                            />
                        )}
                    </div>

                    <div className={styles.comboInputContainer}>
                        <Dropdown value={optionalPromptTwo} onChange={(e) => setOptionalPromptTwo(e.target.value)}>
                            <option value={-1} disabled={false}>
                                Select a prompt
                            </option>
                            {prompts.map((prompt, i) => (
                                <option value={prompt.id} key={i} disabled={isPromptSelected(prompt)}>
                                    {prompt.text}
                                </option>
                            ))}
                        </Dropdown>
                        {parseInt(optionalPromptTwo) !== -1 && (
                            <TextArea
                                rows={4}
                                maxLength={250}
                                value={optionalPromptTwoText}
                                characterCounter={true}
                                onChange={(e) => {
                                    setOptionalPromptTwoText(e.target.value);
                                }}
                            />
                        )}
                    </div>

                    <div className={styles.comboInputContainer}>
                        <span style={{ backgroundColor: "white" }}>
                            <Dropdown
                                value={optionalPromptThree}
                                onChange={(e) => setOptionalPromptThree(e.target.value)}
                            >
                                <option value={-1} disabled={false}>
                                    Select a prompt
                                </option>
                                {prompts.map((prompt, i) => (
                                    <option value={prompt.id} key={i} disabled={isPromptSelected(prompt)}>
                                        {prompt.text}
                                    </option>
                                ))}
                            </Dropdown>
                        </span>
                        {parseInt(optionalPromptThree) !== -1 && (
                            <TextArea
                                rows={4}
                                maxLength={250}
                                value={optionalPromptThreeText}
                                characterCounter={true}
                                onChange={(e) => setOptionalPromptThreeText(e.target.value)}
                            />
                        )}
                    </div>

                    <h1 className={styles.header}>Pricing Model</h1>
                    <h1 style={{ marginBottom: "15px", textAlign: "left" }} className={styles.questionHeader}>
                        Help potential clients understand your pricing structure at a glance. This simple section allows
                        you to indicate an approximate fee range without committing to specific numbers.
                    </h1>
                    <h1 style={{ textAlign: "left" }} className={styles.questionHeader}>
                        <b>Why add pricing details?</b>
                    </h1>
                    <ul style={{ marginBottom: "15px", textAlign: "left" }}>
                        <li>
                            Transparency: Many clients appreciate knowing upfront if an advisor is within their budget.
                        </li>
                        <li>
                            Save time: Pre-qualify potential clients and reduce inquiries from those seeking services
                            outside your fee structure.
                        </li>
                        <li>
                            Flexibility: This range is an estimate, not a binding quote. You can always discuss exact
                            fees during consultations.
                        </li>
                    </ul>
                    <h1 className={styles.questionHeader} style={{ textAlign: "left" }}>
                        <b>Example:</b> For an AUM fee ranging from 0.75% to 1.05%, enter "0.75" as the minimum and
                        "1.05" as the maximum.
                    </h1>
                    <h1 className={styles.questionHeader} style={{ textAlign: "left", marginBottom: "15px" }}>
                        <b>Note:</b> You can update this information anytime. If your fee structure is complex, you may
                        leave this blank and explain fees in your profile description or during consultations.
                    </h1>

                    <div className={styles.pricingModelInputContainer}>
                        {showFlatFee && (
                            <div className={styles.checkCircle} onClick={() => setShowFlatFee(false)}>
                                <Check size={20} color={"var(--adfi_white)"}></Check>
                            </div>
                        )}
                        {!showFlatFee && (
                            <div className={styles.checkCircleUnclicked} onClick={() => setShowFlatFee(true)}></div>
                        )}
                        <div className={styles.sectionContainer}>
                            <h1 className={styles.pricingHeader}>Flat Fee</h1>
                            <span
                                style={{
                                    textAlign: "left",
                                    "font-size": "14px",
                                    "font-weight": "normal",
                                }}
                            >
                                Advisors who charge flat fees for their services charge specific dollar amounts, not
                                percentage points. This can be referred to as a retainer. Flat fees are often used for
                                ongoing services or comprehensive planning.
                            </span>

                            {showFlatFee && (
                                <div className={styles.inputContainer}>
                                    <span className={styles.inputLabel}>Flat fee price ranges:</span>
                                    <span>from</span>
                                    <TextInput
                                        placeholder="Minimum ($)"
                                        value={flatFeeMin ? "$" + flatFeeMin : ""}
                                        error={errors.flatFeeMin}
                                        className={styles.input}
                                        onChange={(e) => setFlatFeeMin(e.target.value.replace("$", ""))}
                                    />
                                    <span>to</span>
                                    <TextInput
                                        placeholder="Maximum ($)"
                                        value={flatFeeMax ? "$" + flatFeeMax : ""}
                                        error={errors.flatFeeMax}
                                        className={styles.input}
                                        onChange={(e) => setFlatFeeMax(e.target.value.replace("$", ""))}
                                    />
                                </div>
                            )}
                            {showFlatFee && (
                                <div className={styles.inputContainer}>
                                    <span className={styles.inputLabel}>Billing frequency:</span>
                                    <Dropdown value={aumFequency} onChange={(e) => setAumFequency(e.target.value)}>
                                        <option value={-1} disabled={false}>
                                            Select a frequency
                                        </option>
                                        {frequencyOptions.map((option, i) => (
                                            <option value={option.id} key={i}>
                                                {option.displayValue}
                                            </option>
                                        ))}
                                    </Dropdown>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={styles.pricingModelInputContainer}>
                        {showAUMFee && (
                            <div className={styles.checkCircle} onClick={() => setShowAUMFee(false)}>
                                <Check size={20} color={"var(--adfi_white)"}></Check>
                            </div>
                        )}
                        {!showAUMFee && (
                            <div className={styles.checkCircleUnclicked} onClick={() => setShowAUMFee(true)}></div>
                        )}
                        <div className={styles.sectionContainer}>
                            <h1 className={styles.pricingHeader}>AUM Fee (%)</h1>
                            <span
                                style={{
                                    textAlign: "left",
                                    "font-size": "14px",
                                    "font-weight": "normal",
                                }}
                            >
                                Advisors who charge an AUM fee, assets under management fee, charge a percentage fee
                                based on the amount of assets a client has. This fee typically begins at 1% of AUM, but
                                is negotiable between the advisor and client. It is not uncommon for advisors to charge
                                much less than 1% based on the amount of assets a client has.
                            </span>

                            {showAUMFee && (
                                <div className={styles.inputContainer}>
                                    <span className={styles.inputLabel}>AUM fee range:</span>
                                    <span>from</span>
                                    <TextInput
                                        placeholder="Minimum (%)"
                                        value={AUMMin}
                                        error={errors.AUMMin}
                                        className={styles.input}
                                        onChange={(e) => setAUMMin(e.target.value)}
                                    />
                                    <span>to</span>
                                    <TextInput
                                        placeholder="Maximum (%)"
                                        value={AUMMax}
                                        error={errors.AUMMax}
                                        className={styles.input}
                                        onChange={(e) => setAUMMax(e.target.value)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={styles.pricingModelInputContainer}>
                        {showOneTimeFee && (
                            <div className={styles.checkCircle} onClick={() => setShowOneTimeFee(false)}>
                                <Check size={20} color={"var(--adfi_white)"}></Check>
                            </div>
                        )}
                        {!showOneTimeFee && (
                            <div className={styles.checkCircleUnclicked} onClick={() => setShowOneTimeFee(true)}></div>
                        )}
                        <div className={styles.sectionContainer}>
                            <h1 className={styles.pricingHeader}>One-Time Financial Plan</h1>
                            <span
                                style={{
                                    textAlign: "left",
                                    "font-size": "14px",
                                    "font-weight": "normal",
                                }}
                            >
                                Advisors who charge a one-time financial plan fee provide a comprehensive financial
                                analysis and strategy for a set price. This fee covers the creation of a detailed
                                financial plan tailored to the client's specific goals and circumstances.
                            </span>

                            {showOneTimeFee && (
                                <div className={styles.inputContainer}>
                                    <span className={styles.inputLabel}>Fee:</span>
                                    <TextInput
                                        placeholder="Minimum ($)"
                                        value={oneTimePlan ? "$" + oneTimePlan : ""}
                                        error={errors.oneTimePlan}
                                        className={styles.input}
                                        onChange={(e) => setOneTimePlan(e.target.value.replace("$", ""))}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={styles.pricingModelInputContainer}>
                        {showHourlyFee && (
                            <div className={styles.checkCircle} onClick={() => setShowHourlyFee(false)}>
                                <Check size={20} color={"var(--adfi_white)"}></Check>
                            </div>
                        )}
                        {!showHourlyFee && (
                            <div className={styles.checkCircleUnclicked} onClick={() => setShowHourlyFee(true)}></div>
                        )}
                        <div className={styles.sectionContainer}>
                            <h1 className={styles.pricingHeader}>Hourly Rate</h1>
                            <span
                                style={{
                                    textAlign: "left",
                                    "font-size": "14px",
                                    "font-weight": "normal",
                                }}
                            >
                                Advisors who charge hourly fees bill clients based on the time spent working on their
                                financial matters. This fee structure is often used for specific tasks or consultations.
                                Hourly rates can vary widely depending on the advisor's experience and expertise. This
                                approach allows clients to pay only for the time and services they need, making it
                                flexible for those who don't require ongoing management.
                            </span>

                            {showHourlyFee && (
                                <div className={styles.inputContainer}>
                                    <span className={styles.inputLabel}>Rate:</span>
                                    <TextInput
                                        placeholder="$/hour"
                                        value={hourlyRate ? "$" + hourlyRate : ""}
                                        error={errors.hourlyRate}
                                        className={styles.input}
                                        onChange={(e) => setHourlyRate(e.target.value.replace("$", ""))}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <h1 className={styles.header} style={{ marginBottom: "10px", marginTop: "10px" }}>
                        Key Client Metrics
                    </h1>

                    <div className={styles.additionalInfoContainer}>
                        <div className={styles.inputContainer}>
                            <h1 className={styles.questionHeader}>Number of Clients</h1>
                            <div className={styles.pricingModelInputContainer} style={{ marginBottom: "20px" }}>
                                <TextInput
                                    placeholder="Enter numeric value"
                                    value={numberOfClients}
                                    error={errors.numberOfClients}
                                    onChange={(e) => setNumberOfClients(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className={styles.inputContainer}>
                            <h1 className={styles.questionHeader}>Average Account Size</h1>
                            <div className={styles.pricingModelInputContainer} style={{ marginBottom: "20px" }}>
                                <TextInput
                                    placeholder="Enter numeric value"
                                    value={averageAccountSize ? "$" + averageAccountSize : ""}
                                    error={errors.averageAccountSize}
                                    onChange={(e) => setAverageAccountSize(e.target.value.replace("$", ""))}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button onClick={(e) => validateFields(e)} disabled={isDisabled}>
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
