import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ArrowRight } from "react-feather";
import { signIn, getCurrentUser, fetchAuthSession, fetchUserAttributes, signOut } from "aws-amplify/auth";
import { useLocation } from "react-router";
import { UserContext } from "../../Utils/UserContext";
import consumerProfileService from "../../services/consumerProfile.service";
import "../Register/Register.scss";
import "@aws-amplify/ui-react/styles.css";
import advisorProfileService from "../../services/advisorProfile.service";
import firmAdminService from "../../services/firmAdmin.service";
import ErrorBanner from "../../components/ErrorBanner/ErrorBanner";
import ClipLoader from "react-spinners/ClipLoader";
import ForgotPasswordForm from "../../components/Forms/ForgotPasswordForm/ForgotPasswordForm";
import FormModal from "../../components/Modals/FormModal";
import FormModalNotCloseable from "../../components/Modals/FormModalNotCloseable";
import DisableAccountForm from "../../components/Forms/DisableAccountForm/DisableAccountForm";
import useGA4event from "../../Utils/useGA4events";
import useMixPanelEvent from "../../Utils/MixPanel";

export default function SignInForm(props) {
    const gaEventTrack = useGA4event("Sign In Page");
    const mixpanelTrack = useMixPanelEvent();

    const location = useLocation();

    const { user, setUser } = useContext(UserContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setErrors] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [errorText, setErrorText] = useState("Something went wrong. Please try again.");
    const [loading, setLoading] = useState(false);
    const [redirectUri, setRedirectUri] = useState(null);
    const [showEnableAccountModal, setShowEnableAccountModal] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [advisorProfileRedirect] = useState(JSON.parse(window.localStorage.getItem("advisorProfileRedirect")));

    useEffect(async () => {
        const queryParams = new URLSearchParams(location.search);
        const tempRedirectUri = queryParams.get("redirectUri");
        if (tempRedirectUri) {
            setRedirectUri(tempRedirectUri);
        }
        const usersession = await fetchAuthSession();
        if (usersession.tokens && !user) {
            console.log("signing user out..");
            await signOut();
        } else if (usersession.tokens && user) {
            await handlePostSignin(user);
        }
    }, []);

    useEffect(() => {
        if (email === "" || password === "") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [email, password]);

    async function setUserInfo() {
        const [user, session, userAttributes] = await Promise.all([
            getCurrentUser(),
            fetchAuthSession(),
            fetchUserAttributes(),
        ]);
        const userGroups = session.tokens.accessToken.payload["cognito:groups"];
        const userId = userAttributes["custom:id"];
        const userEmail = userAttributes.email;
        const userName = user.username;

        let currentUserInfo;
        try {
            if (userGroups.includes("Advisor")) {
                currentUserInfo = await advisorProfileService.getOne(userId);
                currentUserInfo.id = currentUserInfo.professionalId;
            } else if (userGroups.includes("FirmAdmin")) {
                currentUserInfo = await firmAdminService.getOne(userId);
            } else {
                currentUserInfo = await consumerProfileService.getOne(userEmail);
                currentUserInfo.id = currentUserInfo.consumerId;
            }

            const userObj = {
                groups: userGroups,
                email: userEmail,
                userName,
                id: currentUserInfo.id,
                firmID: currentUserInfo.firmId,
                firstName: currentUserInfo.firstName,
                lastName: currentUserInfo.lastName,
                displayName: currentUserInfo.displayName,
                phoneNumber: currentUserInfo.phoneNumber,
                hasLoggedIn: currentUserInfo.hasLoggedIn,
                accountEnabled: currentUserInfo.accountEnabled,
                favoriteAdvisors: currentUserInfo.favoriteAdvisors,
                advisorPrompts: currentUserInfo.advisorPrompts,
            };
            setUser(userObj);
            return userObj;
        } catch {
            throw new Error("Something went wrong. Please try again.");
        }
    }

    async function setUserSignedInOnce(type, id) {
        const myInit = {
            body: {
                columnData: [
                    {
                        hasLoggedIn: true,
                    },
                ],
            },
        };
        setUser({
            ...user,
            hasLoggedIn: true,
        });
        try {
            if (type === "advisor") {
                await advisorProfileService.update(id, myInit);
            } else if (type === "firmAdmin") {
                await firmAdminService.update(id, myInit);
            } else {
                await consumerProfileService.update(id, myInit);
            }
        } catch (error) {
            console.log(error);
            throw new Error("Something went wrong. Please try again.");
        }
    }

    async function signInUser(event) {
        event.preventDefault();
        setErrors("");
        setLoading(true);

        try {
            setDisabled(true);
            setShowErrorBanner(false);
            await signIn({ username: email, password });
            const user = await setUserInfo();
            await handlePostSignin(user);
        } catch (err) {
            console.log(err);
            setErrorText(err.message);
            setShowErrorBanner(true);
        } finally {
            setDisabled(false);
            setLoading(false);
        }
    }

    async function handleCloseEnableAccountForm() {
        setShowEnableAccountModal(false);
        if (user.groups.includes("Advisor")) {
            if (user.hasLoggedIn) {
                window.location = redirectUri
                    ? `/app${redirectUri}`
                    : `/app/advisor-profile/${user.id}/${user.firstName}-${user.lastName}/`;
            } else {
                await setUserSignedInOnce("advisor", user.id);
                window.location = `/app/confirmInfo/${user.id}`;
            }
        } else if (user.groups.includes("FirmAdmin")) {
            if (user.hasLoggedIn) {
                window.location = redirectUri ? `/app${redirectUri}` : `/app/dashboard`; //TODO FIX TO FIRM ADMIN LOGIN PAGE
            } else {
                await setUserSignedInOnce("firmAdmin", user.id);
                window.location = `/app/dashboard`; //TODO FIX TO FIRM ADMIN LOGIN PAGE
            }
        } else {
            if (user.hasLoggedIn) {
                window.location = redirectUri ? `/app${redirectUri}` : `/app/dashboard`;
            } else {
                await setUserSignedInOnce("consumer", user.id);
                window.location = `/app/details/${user.id}/${user.email}`;
            }
        }
    }

    async function handlePostSignin(userInfo) {
        if (!userInfo.accountEnabled) {
            setShowEnableAccountModal(true);
        } else {
            if (userInfo.groups.includes("Advisor")) {
                gaEventTrack("Sign In", "Advisor");
                mixpanelTrack("Advisor sign in", "Email", userInfo.email);
                if (userInfo.hasLoggedIn) {
                    if (!userInfo.advisorPrompts || userInfo.advisorPrompts?.length === 0) {
                        window.location = "/app/profile-detail-updates";
                        return;
                    }
                    window.location = redirectUri
                        ? `/app${redirectUri}`
                        : `/app/advisor-profile/${userInfo.id}/${userInfo.firstName}-${userInfo.lastName}/`;
                } else {
                    await setUserSignedInOnce("advisor", userInfo.id);
                    window.location = `/app/confirmInfo/${userInfo.id}`;
                }
            } else if (userInfo.groups.includes("FirmAdmin")) {
                gaEventTrack("Sign In", "Firm Admin");
                mixpanelTrack("Firm Admin sign in", "Email", userInfo.email);
                if (userInfo.hasLoggedIn) {
                    window.location = redirectUri ? `/app${redirectUri}` : `/app/firmAdmin-dashboard`;
                } else {
                    await setUserSignedInOnce("firmAdmin", userInfo.id);
                    window.location = `/app/firmAdmin-dashboard`;
                }
            } else {
                gaEventTrack("Sign In", "Consumer");
                mixpanelTrack(
                    props.assessmentPayload ? `Consumer sign in - Assessment` : "Consumer sign in",
                    "Email",
                    userInfo.email,
                );
                if (props.assessmentResults) {
                    //update assessment answers for consumer
                    const consumerAssessment = {
                        body: {
                            assessmentPayload: props.assessmentResults,
                        },
                    };
                    const updateAssessment = await consumerProfileService.getassessmentResults(
                        userInfo.id,
                        consumerAssessment,
                    );
                    console.log(updateAssessment);
                }
                if (advisorProfileRedirect && userInfo.hasLoggedIn) {
                    window.location = `${advisorProfileRedirect}`;
                } else if (userInfo.hasLoggedIn) {
                    window.location = redirectUri ? `/app${redirectUri}` : `/app/dashboard`;
                } else {
                    await setUserSignedInOnce("consumer", userInfo.id);
                    window.location = `/app/details/${userInfo.id}/${userInfo.email}`;
                }
            }
        }
    }

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            {showForgotPasswordModal && (
                <FormModal
                    onClose={() => setShowForgotPasswordModal(false)}
                    show={showForgotPasswordModal}
                    title={"Reset Password"}
                >
                    <ForgotPasswordForm></ForgotPasswordForm>
                </FormModal>
            )}
            {user && (
                <FormModalNotCloseable
                    show={showEnableAccountModal}
                    title={"Your Account is Disabled - Re-enable it to log in."}
                >
                    <DisableAccountForm
                        user={user}
                        enable={true}
                        onClose={() => handleCloseEnableAccountForm()}
                    ></DisableAccountForm>
                </FormModalNotCloseable>
            )}

            <div className="form-container">
                <img loading="lazy" className="info-image" src="/app/sign-up-email.svg" alt="/sign-up-email.svg" />
                <h1 className="register-header">Welcome back!</h1>
                {!props.assessmentResults && (
                    <p className="register-paragraph">
                        First time here? <Link to="/signup">Join AdvisorFinder</Link> or{" "}
                        <Link to="/advisor-apply">apply as an advisor.</Link>
                    </p>
                )}
                <form className="register-form" onSubmit={signInUser}>
                    <div className="input-container">
                        <input
                            className="input full"
                            id="email"
                            placeholder="Email"
                            style={{ maxWidth: "400px" }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="input-container">
                        <input
                            className="input full"
                            id="password"
                            placeholder="Password"
                            type="password"
                            style={{ maxWidth: "400px" }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="forgotPasword" onClick={() => setShowForgotPasswordModal(true)}>
                        Forgot Password?
                    </div>
                    {error !== "" && (
                        <p className="error-message" style={{ justifyContent: "center", paddingTop: "10px" }}>
                            {error}
                        </p>
                    )}
                    <div className="input-container">
                        <Button color="clear-button large" type="submit" disabled={disabled}>
                            Sign In
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                </form>
                {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errorText}</ErrorBanner>}
            </div>
        </>
    );
}
