import React, { useState, useEffect } from "react";
import { resetPassword, confirmResetPassword, resendSignUpCode } from "aws-amplify/auth";
import styles from "./ForgotPasswordForm.module.scss";
import { Check } from "react-feather";
import Button from "../../Button/Button";
import ErrorBanner from "../../ErrorBanner/ErrorBanner";
import validateConfirmPassword from "../../../Utils/validateConfirmPassword";
import OtpCodeInput from "../../OtpCodeInput/OtpCodeInput";

export default function ForgotPasswordForm(props) {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [showOtpCode, setShowOtpCode] = useState(false);
    const [codeResent, setCodeResent] = useState("");

    const [email, setEmail] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    function cancelButton() {
        props.onClose();
    }

    useEffect(() => {
        if (!showOtpCode && email) {
            setSubmitDisabled(false);
        } else if (showOtpCode && password && confirmPassword) {
            const validate = validateConfirmPassword({ password, confirmPassword });
            if (Object.keys(validate).length === 0) {
                setSubmitDisabled(false);
                setErrors(validate);
            } else {
                setErrors(validate);
            }
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }, [email, password, otpCode, confirmPassword]);

    async function onSubmit() {
        setSubmitDisabled(true);
        setShowErrorBanner(false);

        try {
            if (!showOtpCode) {
                await resetPassword({ username: email });
                setShowOtpCode(true);
            } else {
                await confirmResetPassword({ username: email, newPassword: password, confirmationCode: otpCode });
                setSubmitDisabled(false);
                props.onClose();
            }
        } catch (error) {
            console.log(error);
            setErrors(error.message.toString());
            setShowErrorBanner(true);
            setSubmitDisabled(false);
        }
    }

    async function resendConfirmationCode() {
        try {
            setCodeResent("");
            await resendSignUpCode({ username: email });
            setCodeResent("Code Resent");
        } catch (err) {
            console.log("error resending code: ", err);
            setCodeResent("Error resending code");
        }
    }

    return (
        <>
            <div className={styles.intoFormContainer}>
                {!showOtpCode && (
                    <div className="input-container justify-left mobile-padding">
                        <label htmlFor="resetEmail">Email</label>
                        <input
                            className="input full"
                            placeholder="Email"
                            id="resetEmail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                )}
                {showOtpCode && (
                    <>
                        <div className={`input-container justify-left mobile-padding ${styles.otpCodeContainer}`}>
                            <p className="register-paragraph">
                                We've just sent you an email to {email}. Please check your email for a one-time code and
                                enter below.
                            </p>
                            <OtpCodeInput otp={otpCode} setOtp={setOtpCode} />
                            <div className="input-container" style={{ marginBottom: "5px" }}>
                                <Button color="clear-button no-border" onClick={resendConfirmationCode}>
                                    Resend code
                                </Button>
                            </div>
                            {codeResent && <p style={{ color: "green" }}>{codeResent}</p>}
                        </div>
                        <div className="input-container justify-left mobile-padding">
                            <label htmlFor="newPassword">New Password</label>
                            <input
                                className="input full"
                                placeholder="New Password"
                                id="newPassword"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div style={{ color: "grey", opacity: "90%", textAlign: "left" }}>
                            Must consist of 8+ characters, upper and lowercase characters, 1 digit and 1 special symbol
                        </div>
                        <div className="input-container justify-left mobile-padding">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <input
                                className="input full"
                                placeholder="Confirm Password"
                                id="confirmPassword"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        {errors.password && <p className="error-message">{errors.password}</p>}
                    </>
                )}
            </div>
            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>
                    Cancel
                </Button>
                <Button
                    className="modal-cancel-button"
                    color="gradient-button"
                    onClick={onSubmit}
                    disabled={submitDisabled}
                >
                    <Check size={20} />
                    Confirm
                </Button>
            </div>
            {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
        </>
    );
}
