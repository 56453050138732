import ReactGA from "react-ga4";

export default function useGA4event(category = "Default") {
    const event = (action = "default", label = "default") => {
        ReactGA.event({
            category: category,
            action: action,
            label: label,
        });
    };
    return event;
}
