import styles from "./Credentials.module.scss";
import { Edit2 } from "react-feather";
import { React } from "react";

export const CredentialsCard = ({ credential, showCredentialImg, onEdit, children }) => {
    return (
        <div className={styles.service_edit}>
            <Edit2
                size={16}
                className={styles.editPencil}
                onClick={() => {
                    onEdit(credential);
                }}
            ></Edit2>
            {children}
            <div className={styles.serviceImg}>
                <img loading="lazy" src={showCredentialImg(credential.id)} alt="credentialsImg"></img>
            </div>
            <div className={styles.serviceText_edit}>
                <h3 className={styles.serviceName}>{credential.value}</h3>
            </div>
        </div>
    );
};
