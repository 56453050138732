import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router";
import { ArrowRight } from "react-feather";
import { ReviewsCarousel } from "../../components/ReviewsCarousel/ReviewsCarousel";
import validateConsumerSignUp from "../../Utils/validateConsumerSignUp";
import ErrorBanner from "../../components/ErrorBanner/ErrorBanner";
import advisorProfileService from "../../services/advisorProfile.service";
import useMixPanelEvent from "../../Utils/MixPanel";
import "../Register/Register.scss";
import consumerProfileService from "../../services/consumerProfile.service";
import firmAdminService from "../../services/firmAdmin.service";
import { UserContext } from "../../Utils/UserContext";
import ClipLoader from "react-spinners/ClipLoader";
import SEO from "../../components/SEO/seo";

function ConsumerApply() {
    const mixpanelTrack = useMixPanelEvent();
    const { setUser } = useContext(UserContext);

    const [email, setEmail] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [errorBannerText, setErrorBannerText] = useState("Something went wrong. Please try again.");
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [policyAgreement, setPolicyAgreement] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (email === "" || !policyAgreement) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [email, policyAgreement]);

    async function handleSubmit(event) {
        event.preventDefault();
        const validate = validateConsumerSignUp({ email });

        if (Object.keys(validate).length === 0 && policyAgreement) {
            try {
                setDisabled(true);
                setLoading(true);
                setShowErrorBanner(false);
                // check if email exisits in professionals DB
                const [consumerResponse, response, firmAdminResponse] = await Promise.all([
                    consumerProfileService.checkEmail(email),
                    advisorProfileService.checkEmail(email),
                    firmAdminService.checkEmail(email),
                ]);

                if (!response && !consumerResponse && !firmAdminResponse) {
                    // email doesnt exist
                    mixpanelTrack("Consumer sign up", "Email", email);
                    await signUpUser();
                    mixpanelTrack("Consumer sign in", "Email", email);
                    navigate(`/assessment`, { replace: true });
                } else if (response) {
                    setErrorBannerText(
                        "You have already applied as a advisor with this email. Please try a different email",
                    );
                    setShowErrorBanner(true);
                }
            } catch (error) {
                setShowErrorBanner(true);
                throw new Error(error);
            } finally {
                setDisabled(false);
                setLoading(false);
            }
        } else {
            setErrors(validate);
        }
    }

    async function signUpUser() {
        try {
            const consumerEmail = {
                body: {
                    email,
                },
            };

            const response = await consumerProfileService.create(consumerEmail);
            const userObj = {
                groups: ["Consumer"],
                email: email,
                id: response.id,
                hasLoggedIn: true,
                accountEnabled: true,
            };

            setUser(userObj);
        } catch (error) {
            console.log("error signing up:", error);
            throw new Error(error);
        }
    }

    return (
        <>
            <SEO
                title={`Sign Up for AdvisorFinder | Find a Financial Advisor`}
                description={
                    "Join AdvisorFinder today to connect with financial advisors based on your unique needs. Whether you’re looking for investment management or financial planning, signing up is your first step. Create your account now and find an advisor."
                }
                name="AdvisorFinder"
                type="website"
                canonical={`https://advisorfinder.com/app/signup`}
            />

            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            <div className="page-container">
                <div className="register-container left">
                    <div className="logo-container">
                        <a href="https://www.advisorfinder.com" rel="noopener">
                            <img
                                loading="lazy"
                                src="/app/AdFi-logo-name.svg"
                                alt="/AdFi-logo-name.svg"
                                className="register-logo"
                            />
                        </a>
                    </div>
                    <div className="form-container">
                        <h1 className="register-header">Let's Get Started</h1>
                        <p className="register-paragraph" style={{ marginBottom: "10px" }}>
                            Already have an account? <Link to="/signin">Sign in now</Link> or{" "}
                            <Link to="/advisor-apply">apply as an advisor.</Link>
                        </p>
                        <div>
                            <Button onClick={() => navigate(`/advisor-apply`)}>Financial Advisors apply here</Button>
                        </div>
                        <div className="line-container">
                            <hr width={150} className="signup-line" />
                            <p className="signup-line">or</p>
                            <hr width={150} className="signup-line" />
                        </div>
                        <form className="register-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <input
                                    className="input full"
                                    id="email"
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
                                />
                            </div>
                            {errors.email && (
                                <p style={{ paddingTop: "10px" }} className="error-message">
                                    {errors.email}
                                </p>
                            )}
                            <div className="checkbox-container">
                                <label className="optionRow">
                                    <input
                                        type="checkbox"
                                        value="betaAgreement"
                                        checked={policyAgreement}
                                        onChange={() => setPolicyAgreement(!policyAgreement)}
                                    />
                                    <span className="checkmark"></span>
                                    <span>
                                        By submitting, I agree to the{" "}
                                        <a
                                            href="https://www.advisorfinder.com/terms-of-service"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Terms of Service
                                        </a>
                                    </span>
                                </label>
                            </div>
                            <div className="input-container">
                                <Button color="clear-button" type="submit" disabled={disabled}>
                                    Join the AdFi Platform
                                    <ArrowRight size={20} />
                                </Button>
                            </div>
                        </form>
                        {showErrorBanner && (
                            <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errorBannerText}</ErrorBanner>
                        )}
                    </div>
                </div>
                <div className="register-container right">
                    <ReviewsCarousel />
                </div>
            </div>
        </>
    );
}

export default ConsumerApply;
