import React, { useState, useEffect } from "react";
import { getUrl } from "aws-amplify/storage";
import styles from "./Credentials.module.scss";
import { ChevronUp, Plus, Edit2, CheckCircle } from "react-feather";
import FormModal from "../../Modals/FormModal";
import CredentialForm from "../../Forms/CredentialForm/CredentialForm";
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import { SortableList } from "../SotableList/SortableList";
import { CredentialsCard } from "./CredentialsCard";

const classnames = require("classnames");

export default function Credentials(props) {
    const editMode = props.editMode;
    const data = props.data;
    const professionalId = props.professionalId;

    const [showMore, setShowMore] = useState(true);
    const [showServiceDetails, setShowServiceDetails] = useState(false);
    const [selectedCredential, setSelectedCredential] = useState({});
    const [credentialImgArray, setCredentialImgArray] = useState(Array(data.length).fill(""));
    const [credentials, setCredentials] = useState(data);
    const [showUpdateButton, setShowUpdateButton] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const firmAdminAuth = props.firmAdminAuth;

    useEffect(async () => {
        setCredentials(data);
        await getCredentialsImg(data);
    }, [data]);

    useEffect(() => {
        credentials.forEach((item, index) => {
            if (item.sortingPriority !== index) {
                item.sortingPriority = index;
                setSubmitSuccess(false);
                setShowUpdateButton(true);
            }
        });
    }, [credentials]);

    useEffect(() => {
        if (editMode) setShowMore(true);
    }, [editMode]);

    function handleClickService(credential) {
        setSelectedCredential(credential);
        setShowServiceDetails(true);
    }

    async function getCredentialsImg(data) {
        const promises = await data.map(async (value) => {
            const id = value.id;
            const credentialsName = value.value
                .trim()
                .replaceAll(/[^a-zA-Z0-9 -_]/g, "")
                .replaceAll(" ", "_");
            let url = "";
            try {
                url = (await getUrl({ key: `credentials/${id}_${credentialsName}.png` })).url.href;
                return { id, url };
            } catch (e) {
                console.error(e);
            }
        });

        let results = await Promise.all(promises);
        let tempArr = [...credentialImgArray];
        results.forEach((item) => {
            const index = data.findIndex((x) => x.id === item.id); //find the index of data
            tempArr[index] = item.url; // set the url
        });

        setCredentialImgArray(tempArr);
        return results;
    }

    function showCredentialImg(id) {
        const index = data.findIndex((x) => x.id === id);
        return credentialImgArray[index];
    }

    async function updateOrder() {
        await onSubmit();
    }

    async function onSubmit() {
        setSubmitDisabled(true);
        let columnData = [
            {
                credentials: credentials,
            },
        ];

        const myInit = {
            body: {
                columnData,
            },
        };

        try {
            await advisorProfileService.update(professionalId, myInit, firmAdminAuth);
            props.setData(credentials);
            setSubmitSuccess(true);
            setShowUpdateButton(false);
        } catch (error) {
            console.log(error);
        } finally {
            setSubmitDisabled(false);
        }
    }

    return (
        <>
            {showServiceDetails && (
                <FormModal onClose={() => setShowServiceDetails(false)} show={showServiceDetails} noPadding={true}>
                    <CredentialForm service={selectedCredential}></CredentialForm>
                </FormModal>
            )}
            <div className={styles.servicesContainer_edit}>
                <div className={styles.servicesHeader_edit}>
                    <ChevronUp
                        className={classnames(styles.showMoreArrow, showMore && styles.open)}
                        onClick={() => setShowMore(!showMore)}
                        size={20}
                        color="rgba(45, 43, 65, 1)"
                    />
                    <h2>Credentials and Designations</h2>
                    <div className={styles.countNumber}>{data.length}</div>
                    {editMode && showUpdateButton && (
                        <div>
                            <Button
                                className={styles.submitOrderButton}
                                color="clear-button"
                                disabled={submitDisabled}
                                onClick={() => updateOrder()}
                            >
                                Update Order
                            </Button>
                        </div>
                    )}
                    {submitSuccess && (
                        <div>
                            <CheckCircle size={16} color="var(--adfi_green500)" />
                        </div>
                    )}
                    {editMode && (
                        <div className={styles.addIcons} onClick={props.onClick}>
                            <Plus size={16} color="rgba(129, 128, 141, 1)" />
                        </div>
                    )}
                </div>
                {!editMode && (
                    <div className={classnames(styles.dataContainer_edit, !showMore && styles.clamp)}>
                        {data.map((service, index) => (
                            <div
                                className={editMode ? styles.service_edit : `${styles.service_edit} ${styles.pointer}`}
                                key={index}
                                onClick={() => (editMode ? null : handleClickService(service))}
                            >
                                {editMode && (
                                    <Edit2
                                        size={16}
                                        className={styles.editPencil}
                                        onClick={() => {
                                            props.onEdit(service);
                                        }}
                                    ></Edit2>
                                )}
                                <div className={styles.serviceImg}>
                                    <img loading="lazy" src={showCredentialImg(service.id)} alt="credentialsImg"></img>
                                </div>
                                <div className={styles.serviceText_edit}>
                                    <h3 className={styles.serviceName}>{service.value}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {editMode && (
                    <div className={classnames(styles.dataContainer_edit, !showMore && styles.clamp)}>
                        <SortableList
                            items={credentials}
                            onChange={setCredentials}
                            renderItem={(credential) => (
                                <SortableList.Item id={credential.id} className={styles.service_edit}>
                                    <CredentialsCard
                                        credential={credential}
                                        showCredentialImg={showCredentialImg}
                                        onEdit={props.onEdit}
                                    >
                                        <SortableList.DragHandle className={styles.dragHandle} />
                                    </CredentialsCard>
                                </SortableList.Item>
                            )}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
