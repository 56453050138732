import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import { confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowRight } from "react-feather";
import validator from "validator";
import { ReviewsCarousel } from "../../components/ReviewsCarousel/ReviewsCarousel";
import { useParams } from "react-router-dom";
import ErrorBanner from "../../components/ErrorBanner/ErrorBanner";
import "../Register/Register.scss";
import ClipLoader from "react-spinners/ClipLoader";
import OtpCodeInput from "../../components/OtpCodeInput/OtpCodeInput";

function OtpCode() {
    const location = useLocation();

    let { user, email } = useParams();
    let navigate = useNavigate();
    const [otp, setOtp] = useState("");

    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState("");
    const [codeResent, setCodeResent] = useState("");
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const resendCode = queryParams.get("resendCode");
        if (resendCode) {
            resendConfirmationCode();
        }
    }, []);

    useEffect(() => {
        if (otp === "" || otp.length !== 6) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [otp]);

    function validateOTP(code) {
        if (!validator.isNumeric(code) || code.length !== 6) {
            setError("Invalid code");
        }
    }

    async function handleSubmit(event) {
        setLoading(true);
        event.preventDefault();
        setError("");
        validateOTP(otp);
        if (error === "") {
            try {
                setDisabled(true);
                setShowErrorBanner(false);
                await confirmSignUpUser(otp);
                navigate("/signin");
            } catch {
                setError("Incorrect Code");
                setShowErrorBanner(true);
            } finally {
                setLoading(false);
                setDisabled(false);
            }
        }
    }

    async function confirmSignUpUser(code) {
        try {
            await confirmSignUp({
                username: email,
                confirmationCode: code,
                options: {
                    clientMetadata: { userGroup: user },
                },
            });
        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }

    async function resendConfirmationCode() {
        try {
            setCodeResent("");
            await resendSignUpCode({ username: email });
            setCodeResent("Code Resent");
        } catch (err) {
            console.log("error resending code: ", err);
            setCodeResent(`Error resending code - ${err.message}`);
        }
    }

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            <div className="page-container">
                <div className="register-container left">
                    <div className="logo-container">
                        <a href="https://www.advisorfinder.com" rel="noopener">
                            <img
                                loading="lazy"
                                src="/app/AdFi-logo-name.svg"
                                alt="/AdFi-logo-name.svg"
                                className="register-logo"
                            />
                        </a>
                    </div>
                    <div className="form-container success">
                        <img loading="lazy" src="/app/sign-up-email.svg" alt="/sign-up-email.svg" />
                        <h1 className="register-header">Almost there!</h1>
                        <p className="register-paragraph">
                            We've just sent you an email to {email}. Please check your email for a one-time code and
                            enter below.
                        </p>
                        <form autoComplete="off" className="register-form" onSubmit={handleSubmit}>
                            <OtpCodeInput otp={otp} setOtp={setOtp} />
                            {error && (
                                <p
                                    className="error-message"
                                    style={{
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingTop: "10px",
                                    }}
                                >
                                    {error}
                                </p>
                            )}
                            <div className="input-container">
                                <Button color="clear-button" type="submit" disabled={disabled}>
                                    Verify your Email
                                    <ArrowRight size={20} />
                                </Button>
                            </div>
                            <div className="input-container" style={{ marginBottom: "5px" }}>
                                <Button color="clear-button no-border" onClick={resendConfirmationCode}>
                                    Resend code
                                </Button>
                            </div>
                            {codeResent && <p style={{ color: "green" }}>{codeResent}</p>}
                        </form>
                        {showErrorBanner && (
                            <ErrorBanner onClick={() => setShowErrorBanner(false)}>
                                Something went wrong. Please try again.
                            </ErrorBanner>
                        )}
                    </div>
                </div>
                <div className="register-container right">
                    <ReviewsCarousel />
                </div>
            </div>
        </>
    );
}

export default OtpCode;
