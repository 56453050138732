import React, { useEffect, useState } from "react";
import styles from "./WelcomeToAdfi.module.scss";
import { getUrl } from "aws-amplify/storage";
import Button from "../../../components/Button/Button";
import { ArrowRight, HelpCircle } from "react-feather";

export default function WelcomeToAdfi(props) {
    const [imgOneUrl, setImgOneUrl] = useState("");
    const [imgTwoUrl, setImgTwoUrl] = useState("");
    const [imgThreeUrl, setImgThreeUrl] = useState("");
    const [imgOneMobileUrl, setImgOneMobileUrl] = useState("");
    const [imgTwoMobileUrl, setImgTwoMobileUrl] = useState("");
    const [imgThreeMobileUrl, setImgThreeMobileUrl] = useState("");
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

    useEffect(() => {
        getUrl({ key: `welcomeImages/welcome_desktop_1.png` }).then((res) => {
            setImgOneUrl(res.url.href);
        });
        getUrl({ key: `welcomeImages/welcome_desktop_2.png` }).then((res) => {
            setImgTwoUrl(res.url.href);
        });
        getUrl({ key: `welcomeImages/welcome_desktop_3.png` }).then((res) => {
            setImgThreeUrl(res.url.href);
        });
        getUrl({ key: `welcomeImages/welcome_mobile_1.png` }).then((res) => {
            setImgOneMobileUrl(res.url.href);
        });
        getUrl({ key: `welcomeImages/welcome_mobile_2.png` }).then((res) => {
            setImgTwoMobileUrl(res.url.href);
        });
        getUrl({ key: `welcomeImages/welcome_mobile_3.png` }).then((res) => {
            setImgThreeMobileUrl(res.url.href);
        });

        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const updateMedia = () => {
        setDesktop(window.innerWidth > 768);
    };

    return (
        <>
            <div className={styles.formContainer}>
                <div className={styles.modelContainer}>
                    <div className={styles.textContainer}>
                        {currentSlide === 0 && (
                            <>
                                <div className={styles.titleText}>Welcome to AdvisorFinder!</div>
                                <div className={styles.textValue}>
                                    AdvisorFinder makes it easy to search, browse, and schedule meetings with financial
                                    advisors who have backgrounds, expertise, and personalities that you’re looking for.
                                    <br />
                                    <br />
                                    Click <b>Next</b> for a brief platform walk-through.
                                </div>
                            </>
                        )}
                        {currentSlide === 1 && (
                            <>
                                <div className={styles.titleText}>The Advisor Profile</div>
                                <div className={styles.textValue}>
                                    Every financial advisor on AdvisorFinder has their own <b>Advisor Profile.</b>{" "}
                                    Select an advisor to view more information and ways to contact them.
                                </div>
                            </>
                        )}
                        {currentSlide === 2 && (
                            <>
                                <div className={styles.titleText}>Filters</div>
                                <div className={styles.textValue}>
                                    The <b>Filters Button</b> is where you'll be able to search for financial advisors
                                    based on various criteria, such as what you need help with and the industry you work
                                    in.
                                </div>
                            </>
                        )}
                        {isDesktop ? (
                            <div className={styles.welcomeImgDesktop}>
                                {currentSlide === 0 && <img id="pic1" src={imgOneUrl} alt="welcome_img_1"></img>}
                                {currentSlide === 1 && <img id="pic2" src={imgTwoUrl} alt="welcome_img_2"></img>}
                                {currentSlide === 2 && <img id="pic3" src={imgThreeUrl} alt="welcome_img_3"></img>}
                            </div>
                        ) : (
                            <div className={styles.welcomeImgMobile}>
                                {currentSlide === 0 && <img id="pic1" src={imgOneMobileUrl} alt="welcome_img_1"></img>}
                                {currentSlide === 1 && <img id="pic2" src={imgTwoMobileUrl} alt="welcome_img_2"></img>}
                                {currentSlide === 2 && (
                                    <img id="pic3" src={imgThreeMobileUrl} alt="welcome_img_3"></img>
                                )}
                            </div>
                        )}

                        <div className="carousel-buttons-container">
                            <span
                                onClick={() => setCurrentSlide(0)}
                                className={`circle ${currentSlide === 0 ? "activePuprle" : ""}`}
                            />
                            <span
                                onClick={() => setCurrentSlide(1)}
                                className={`circle ${currentSlide === 1 ? "activePuprle" : ""}`}
                            />
                            <span
                                onClick={() => setCurrentSlide(2)}
                                className={`circle ${currentSlide === 2 ? "activePuprle" : ""}`}
                            />
                        </div>
                        {currentSlide < 2 && (
                            <Button
                                onClick={() => setCurrentSlide(currentSlide + 1)}
                                className={styles.buttonText}
                                color="gradient-button"
                            >
                                Next
                                <ArrowRight size={20} />
                            </Button>
                        )}
                        {currentSlide >= 2 && (
                            <div className={styles.buttonContainer}>
                                <a
                                    href="https://advisorfinder.com/app/assessment"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button color="clear-button">
                                        <HelpCircle size={20} />
                                        Do you need help searching?
                                    </Button>
                                </a>

                                <Button onClick={() => props.onClose()} color="gradient-button">
                                    Search for Advisors
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
