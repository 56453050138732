import React, { createContext, useContext, useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { AlignJustify } from "react-feather";

const SortableItemContext = createContext({
    attributes: {},
    listeners: undefined,
    ref() {},
});

export function SortableItem({ children, id }) {
    const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
        id,
    });
    const context = useMemo(
        () => ({
            attributes,
            listeners,
            ref: setActivatorNodeRef,
        }),
        [attributes, listeners, setActivatorNodeRef],
    );
    const style = {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <SortableItemContext.Provider value={context}>
            <div ref={setNodeRef} style={style}>
                {children}
            </div>
        </SortableItemContext.Provider>
    );
}

export function DragHandle({ className }) {
    const { attributes, listeners, ref } = useContext(SortableItemContext);

    return (
        <button className={className} style={{ background: "white" }} {...attributes} {...listeners} ref={ref}>
            <AlignJustify size={16}></AlignJustify>
        </button>
    );
}
