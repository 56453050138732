import React from "react";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./pages/SignIn/SignIn";
import "@aws-amplify/ui-react/styles.css";
import { HelmetProvider } from "react-helmet-async";
import Apply from "./pages/AdvisorApply/Apply";
import Register from "./pages/Register/Register";
import { UserContext } from "./Utils/UserContext";
import { RequireAuth } from "./Utils/RequireAuth";
import { useLocalStorage } from "./Utils/useLocalStorage";
import { Authenticator } from "@aws-amplify/ui-react";
import NavBar from "../src/components/NavBar/NavBar";
import OtpCode from "../src/pages/OtpCode/OtpCode";
import AdvisorProfile from "./pages/AdvisorProfile/AdvisorProfile";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import ConsumerProfile from "./pages/ConsumerProfile/ConsumerProfile";
import Store from "./Utils/GlobalStore/Store";
import ConsumerApply from "../src/pages/ConsumerApply/ConsumerApply";
import ConsumerDetails from "../src/pages/ConsumerDetails/ConsumerDetails";
import AdminDashboard from "../src/pages/AdminDashboard/AdminDashboard";
import Marketplace from "../src/pages/Marketplace/Marketplace";
import AdvisorConfirmInfo from "../src/pages/AdvisorConfirmInfo/AdvisorConfirmInfo";
import AdvisorDashboard from "../src/pages/AdvisorDashboard/AdvisorDashboard";
import AdvisorConfirmMeeting from "./pages/AdvisorConfirmMeeting/AdvisorConfirmMeeting";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import FirmAdminApply from "./pages/FirmAdminApply/FirmAdminApply";
import FirmAdminDashboard from "./pages/FirmAdminDashboard/FirmAdminDashboard";
import StateSearchPage from "./pages/Marketplace/StateSearchPage";
import ServiceSearchPage from "./pages/Marketplace/ServiceSearchPage";
import LifeStageSearchPage from "./pages/Marketplace/LifeStageSearchPage";
import IndustrySearchPage from "./pages/Marketplace/IndustrySearchPage";
import Assessment from "./pages/Assessment/Assessment";
import AssessmentResults from "./pages/Assessment/AssessmentResults";
import ConsumerRegister from "./pages/ConsumerRegister/ConsumerRegister";
import AdvisorPersonalityForm from "../src/components/Forms/AdvisorPersonality/AdvisorPersonalityForm";

import SEO from "./components/SEO/seo";
import ReactGA from "react-ga4";

function App() {
    ReactGA.initialize("G-74QX60JW3Z");

    const [user, setUser] = useLocalStorage("user", null);

    return (
        <div className="App">
            <HelmetProvider>
                <SEO
                    title="Find a Financial Advisor | AdvisorFinder Marketplace"
                    description="Find a financial advisor you want to work with on AdvisorFinder. Filter by location, what services the advisor provides and their typical clientele. Book a free intro call with a financial advisor. It’s fast, easy, and free."
                    name="AdvisorFinder"
                    type="website"
                    canonical="https://advisorfinder.com/app/"
                />

                <Authenticator.Provider>
                    <UserContext.Provider value={{ user, setUser }}>
                        <Store>
                            <BrowserRouter basename="/app">
                                <Routes>
                                    <Route
                                        exact
                                        path="/"
                                        element={
                                            <NavBar>
                                                <Marketplace />
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route exact path="/signin" element={<SignIn />}></Route>
                                    <Route
                                        exact
                                        path="/confirmInfo/:professionalId"
                                        element={
                                            <RequireAuth>
                                                <AdvisorConfirmInfo />
                                            </RequireAuth>
                                        }
                                    ></Route>
                                    <Route exact path="/register/:professionalId/:email" element={<Register />}></Route>
                                    <Route
                                        exact
                                        path="/consumer/register/:consumerId/:email"
                                        element={<ConsumerRegister />}
                                    ></Route>
                                    <Route exact path="/advisor-apply" element={<Apply />}></Route>
                                    <Route exact path="/firmAdmin-apply" element={<FirmAdminApply />}></Route>
                                    <Route exact path="/register/verify/:user/:email/" element={<OtpCode />}></Route>
                                    <Route
                                        path="/profile-detail-updates"
                                        element={
                                            <NavBar>
                                                <AdvisorPersonalityForm />
                                            </NavBar>
                                        }
                                    />
                                    <Route
                                        path="/advisor-profile/:professionalId/:advisorName"
                                        element={
                                            <NavBar>
                                                <AdvisorProfile />
                                            </NavBar>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/advisor-profile/:professionalId/:advisorName/:modal"
                                        element={
                                            <NavBar>
                                                <AdvisorProfile />
                                            </NavBar>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/consumer-profile"
                                        element={
                                            <NavBar>
                                                <RequireAuth>
                                                    <ConsumerProfile />
                                                </RequireAuth>
                                            </NavBar>
                                        }
                                    />
                                    <Route exact path="/signup" element={<ConsumerApply />}></Route>
                                    <Route
                                        exact
                                        path="/details/:id/:email"
                                        element={
                                            <RequireAuth>
                                                <ConsumerDetails />
                                            </RequireAuth>
                                        }
                                    ></Route>
                                    <Route
                                        exact
                                        path="/dashboard"
                                        element={
                                            <NavBar>
                                                <RequireAuth>
                                                    <AssessmentResults />
                                                </RequireAuth>
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route
                                        exact
                                        path="/advisor-dashboard"
                                        element={
                                            <NavBar>
                                                <RequireAuth>
                                                    <AdvisorDashboard />
                                                </RequireAuth>
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route
                                        exact
                                        path="/account-settings"
                                        element={
                                            <NavBar>
                                                <RequireAuth>
                                                    <AccountSettings />
                                                </RequireAuth>
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route
                                        exact
                                        path="/admin-dashboard"
                                        element={
                                            <NavBar>
                                                <RequireAuth>
                                                    <AdminDashboard />
                                                </RequireAuth>
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route
                                        exact
                                        path="/firmAdmin-dashboard"
                                        element={
                                            <NavBar>
                                                <RequireAuth>
                                                    <FirmAdminDashboard></FirmAdminDashboard>
                                                </RequireAuth>
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route
                                        exact
                                        path="/meetingConfirm"
                                        element={
                                            <NavBar>
                                                <RequireAuth>
                                                    <AdvisorConfirmMeeting />
                                                </RequireAuth>
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route
                                        path="/marketplace"
                                        element={
                                            <NavBar>
                                                <Marketplace />
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route path="/assessment" element={<Assessment />}></Route>
                                    <Route
                                        exact
                                        path="/find-financial-advisor/state/:stateSearch"
                                        element={
                                            <NavBar>
                                                <StateSearchPage />
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route
                                        exact
                                        path="/find-financial-advisor/service/:serviceSearch"
                                        element={
                                            <NavBar>
                                                <ServiceSearchPage />
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route
                                        exact
                                        path="/find-financial-advisor/life-stage/:lifeStageSearch"
                                        element={
                                            <NavBar>
                                                <LifeStageSearchPage />
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route
                                        exact
                                        path="/find-financial-advisor/industry/:industrySearch"
                                        element={
                                            <NavBar>
                                                <IndustrySearchPage />
                                            </NavBar>
                                        }
                                    ></Route>
                                    <Route path="/404" element={<NotFoundPage />}></Route>
                                    <Route path="*" element={<NotFoundPage />}></Route>
                                </Routes>
                            </BrowserRouter>
                        </Store>
                    </UserContext.Provider>
                </Authenticator.Provider>
            </HelmetProvider>
        </div>
    );
}

export default App;
