import React, { useRef } from "react";
import styles from "./AdvisorProfile.module.scss";
import Button from "../../components/Button/Button";
import SplitButton from "../../components/Button/SplitButton";
import { User, Eye, Edit2, Share, Shield, HelpCircle, Mail, Calendar } from "react-feather";
import useMixPanelEvent from "../../Utils/MixPanel";

export default function AdvisorProfileHeader(props) {
    const {
        myProfile,
        advisor,
        editMode,
        isDesktop,
        firmAdminAuth,
        handleShareProfile,
        completenessScore,
        setShowCompletenessScoreModal,
        showScheduleCallButton,
        completenessStyle,
        setShowScheduleCallModal,
        setShowScheduleCallInfoClicked,
        setEditMode,
        setShowContactAdvisorModal,
    } = props;
    const mixpanelTrack = useMixPanelEvent();
    const buttonRef = useRef(null);

    const scheduleCall = (infoClicked) => {
        mixpanelTrack("Schedule Call Button Clicked", "Advisor", `${advisor.firstName} ${advisor.lastName}`);
        if (infoClicked) {
            setShowScheduleCallInfoClicked(true);
        } else {
            setShowScheduleCallModal(true);
        }
    };

    function movePurple(mode) {
        setEditMode(mode);
        if (mode) {
            buttonRef.current.style.transform = `translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
        } else {
            buttonRef.current.style.transform = `translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
        }
    }

    return (
        <>
            {myProfile && (
                <>
                    <div className={styles.myProfileHeader}>
                        {isDesktop && (
                            <span className={styles.profileTextContainer}>
                                <div className={styles.iconContainer}>
                                    <User size={24} color={"var(--adfi_blue500)"} />
                                </div>
                                <h1 className={styles.profileHeaderText}>
                                    {firmAdminAuth ? `${advisor.displayName}'s Profile` : `My Profile`}
                                </h1>
                            </span>
                        )}

                        <div className={styles.toggleWrapper}>
                            <div className={styles.profileToggle}>
                                <div ref={buttonRef} className={styles.togglePurple}></div>
                                <div
                                    className={`${styles.toggleText} ${editMode ? styles.toggleTextHover : ""}`}
                                    style={{ color: !editMode ? "#fff" : "var(--adfi_blue500)" }}
                                    onClick={() => movePurple(false)}
                                >
                                    {isDesktop ? <div>Preview</div> : <Eye size={20}></Eye>}
                                </div>
                                <div
                                    className={`${styles.toggleText} ${!editMode ? styles.toggleTextHover : ""}`}
                                    style={{ color: !editMode ? "var(--adfi_blue500)" : "#fff" }}
                                    onClick={() => movePurple(true)}
                                >
                                    {isDesktop ? <div>Edit View</div> : <Edit2 size={20}></Edit2>}
                                </div>
                            </div>
                        </div>
                        <div className={styles.rightSideButtons}>
                            <Button color="clear-button white" onClick={() => handleShareProfile()}>
                                <Share size={20} />
                            </Button>
                            {showScheduleCallButton() && isDesktop && (
                                <SplitButton
                                    color="clear-button-split white"
                                    onClick={() => scheduleCall()}
                                    secondaryOnClick={() => scheduleCall(true)}
                                >
                                    <Calendar size={20} />
                                    Schedule a call
                                </SplitButton>
                            )}
                            {showScheduleCallButton() && !isDesktop && (
                                <Button color="clear-button white" onClick={() => scheduleCall()}>
                                    <Calendar size={20} />
                                    <span className={styles.scheduleButtonText}>Schedule a call</span>
                                </Button>
                            )}
                            {showScheduleCallButton() && (
                                <Button color="gradient-button" onClick={() => setShowContactAdvisorModal(true)}>
                                    <Mail size={20} />
                                    {isDesktop && <>Contact</>}
                                </Button>
                            )}
                        </div>
                    </div>
                    {editMode && (
                        <div style={{ maxWidth: "1400px", width: "100%" }}>
                            <span className={styles.editDisclosure}>
                                <Shield />
                                By making any updates to your profile, you certify that it has been approved by the
                                necessary compliance personnel at your firm.
                            </span>
                        </div>
                    )}
                    {completenessScore && (
                        <div className={styles.completenessScoreContainer}>
                            <div className={styles.completenessScoreBox}>
                                <div className={styles.mobileContainer}>
                                    <div className={styles.mobileContainerText}>
                                        <span className={styles.completenessScoreNumber}>{completenessScore}%</span>
                                        <span className={styles.completenessScoreText}>Profile completeness</span>
                                    </div>
                                    <div className={styles.progressBarContainer}>
                                        <div className={styles.progressBar} style={completenessStyle}></div>
                                    </div>
                                </div>
                                <div
                                    className={styles.howItWorksContainer}
                                    onClick={() => setShowCompletenessScoreModal(true)}
                                >
                                    <HelpCircle size={16} />
                                    <div>How It works?</div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
            {!myProfile && (isDesktop || showScheduleCallButton()) && (
                <div className={styles.profileHeader}>
                    {isDesktop && (
                        <span className={styles.profileTextContainer}>
                            <span className={styles.iconContainer}>
                                <User size={24} color={"var(--adfi_blue500)"} />
                            </span>
                            <h1 className={styles.profileHeaderText}>
                                {advisor.displayName} {isDesktop && "| Financial Advisor"}
                            </h1>
                        </span>
                    )}

                    <span className={styles.rightSideButtons}>
                        {showScheduleCallButton() && (
                            <Button color="clear-button white" onClick={() => handleShareProfile()}>
                                <Share size={20} />
                            </Button>
                        )}
                        {showScheduleCallButton() && isDesktop && (
                            <SplitButton
                                color="clear-button-split white"
                                onClick={() => scheduleCall()}
                                secondaryOnClick={() => scheduleCall(true)}
                            >
                                <Calendar size={20} />
                                Schedule a call
                            </SplitButton>
                        )}
                        {showScheduleCallButton() && !isDesktop && (
                            <Button color="clear-button white" onClick={() => scheduleCall()}>
                                <Calendar size={20} />
                                <span className={styles.scheduleButtonText}>Schedule a call</span>
                            </Button>
                        )}
                        {showScheduleCallButton() && (
                            <Button color="gradient-button" onClick={() => setShowContactAdvisorModal(true)}>
                                <Mail size={20} />
                                {isDesktop && <>Contact</>}
                            </Button>
                        )}
                    </span>
                </div>
            )}
        </>
    );
}
