import React, { useState, useEffect, useContext } from "react";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router";
import { Eye } from "react-feather";
import validateConsumerSignUp from "../../Utils/validateConsumerSignUp";
import ErrorBanner from "../../components/ErrorBanner/ErrorBanner";
import advisorProfileService from "../../services/advisorProfile.service";
import useMixPanelEvent from "../../Utils/MixPanel";
import styles from "./Assessment.module.scss";
import consumerProfileService from "../../services/consumerProfile.service";
import firmAdminService from "../../services/firmAdmin.service";
import { UserContext } from "../../Utils/UserContext";
import SignInForm from "../SignIn/SignInForm";

function ConsumerLogin(props) {
    const mixpanelTrack = useMixPanelEvent();
    const { user, setUser } = useContext(UserContext);

    const [email, setEmail] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [errorBannerText, setErrorBannerText] = useState("Something went wrong. Please try again.");
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [policyAgreement, setPolicyAgreement] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showSignIn, setShowSignIn] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const assessmentPayload = props.assessmentPayload;

    const navigate = useNavigate();

    useEffect(async () => {
        console.log(user);
        setLoading(true);

        const consumerAssessment = {
            body: {
                assessmentPayload,
            },
        };
        if (user && user.id) {
            setIsActive(true);
            const updateAssessment = await consumerProfileService.getassessmentResults(user.id, consumerAssessment);
            console.log(updateAssessment);
            navigate(`/dashboard`, { replace: true });
            setLoading(false);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (isActive) {
            const items = document.querySelectorAll("#item");
            items.forEach((item, index) => {
                setTimeout(
                    () => {
                        item.classList.add(styles.checked);
                    },
                    2000 + index * 1000,
                ); // Adjust the timing as needed
            });
        } else {
            const items = document.querySelectorAll("#item");
            items.forEach((item) => {
                item.classList.remove(styles.checked);
            });
        }
    }, [isActive]);

    useEffect(() => {
        if (email === "" || !policyAgreement) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [email, policyAgreement]);

    async function handleSubmit(event) {
        event.preventDefault();
        const validate = validateConsumerSignUp({ email });

        if (Object.keys(validate).length === 0 && policyAgreement) {
            try {
                setDisabled(true);
                setShowErrorBanner(false);
                // check if email exisits in professionals DB
                const [consumerResponse, response, firmAdminResponse] = await Promise.all([
                    consumerProfileService.checkEmail(email),
                    advisorProfileService.checkEmail(email),
                    firmAdminService.checkEmail(email),
                ]);

                if (!response && !consumerResponse && !firmAdminResponse) {
                    // email doesnt exist
                    mixpanelTrack("Consumer sign up - Assessment", "Email", email);
                    const userObj = await signUpUser();
                    mixpanelTrack("Consumer sign in - Assessment", "Email", email);
                    await handleLoadingScreen(userObj);
                } else if (response) {
                    setErrorBannerText(
                        "You have already applied as a advisor with this email. Please try a different email",
                    );
                    setShowErrorBanner(true);
                } else if (consumerResponse) {
                    setErrorBannerText("You already signed up with this email. Please try a different email or log in");
                    setShowErrorBanner(true);
                } else if (firmAdminResponse) {
                    setErrorBannerText(
                        "You have already applied as a advisor with this email. Please try a different email",
                    );
                    setShowErrorBanner(true);
                }
            } catch (error) {
                setShowErrorBanner(true);
                throw new Error(error);
            } finally {
                setDisabled(false);
            }
        } else {
            setErrors(validate);
        }
    }

    async function signUpUser() {
        setLoading(true);
        setIsActive(true);
        try {
            const consumerEmail = {
                body: {
                    email,
                    assessmentResults: assessmentPayload,
                },
            };

            mixpanelTrack("Assessment Submit", "Assessment Answers", assessmentPayload);

            const response = await consumerProfileService.create(consumerEmail);
            const userObj = {
                groups: ["Consumer"],
                email: email,
                id: response.id,
                hasLoggedIn: true,
                accountEnabled: true,
                assessmentResults: assessmentPayload,
            };

            setUser(userObj);
            return userObj;
        } catch (error) {
            console.log("error signing up:", error);
            throw new Error(error);
        }
    }

    async function handleLoadingScreen(userObj) {
        const consumerAssessment = {
            body: {
                assessmentPayload,
            },
        };
        if (userObj && userObj.id) {
            const updateAssessment = await consumerProfileService.getassessmentResults(userObj.id, consumerAssessment);
            const items = document.querySelectorAll("#item");
            items.forEach((item) => {
                item.classList.add(styles.checked);
            });
            setLoading(false);
            console.log(updateAssessment);
            navigate(`/dashboard`, { replace: true });
        }
    }

    return (
        <>
            {loading && (
                <div className={styles.spinnerContainer}>
                    <div className={styles.loadingContainer}>
                        <img
                            loading="lazy"
                            src="/app/AdFi-logo-name.svg"
                            alt="/AdFi-logo-name.svg"
                            className="register-logo"
                        />
                        <div className={styles.content}>
                            <h2>Analyzing your responses...</h2>
                            <p className={styles.loadingSubheader}>Please wait while the advisors load</p>
                            <div className={styles.progressBarLoading}></div>
                            <ul className={styles.checklist}>
                                <li id="item" className={styles.checkItem}>
                                    Checking your preferences
                                </li>
                                <li id="item" className={styles.checkItem}>
                                    Analyzing your preferences
                                </li>
                                <li id="item" className={styles.checkItem}>
                                    Understanding your needs
                                </li>
                                <li id="item" className={styles.checkItem}>
                                    Reviewing locations
                                </li>
                                <li id="item" className={styles.checkItem}>
                                    Searching for advisors
                                </li>
                                <li id="item" className={styles.checkItem}>
                                    Double-checking a few things
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            {!loading && (
                <>
                    {!showSignIn && (
                        <div className={styles.industryContainer}>
                            <form className="register-form" onSubmit={handleSubmit}>
                                <span className={styles.emailLoginLogo}>
                                    <a href="https://www.advisorfinder.com" rel="noopener">
                                        <img
                                            loading="lazy"
                                            src="/app/AssessmentLanding.png"
                                            alt="/app/AssessmentLanding.png"
                                        />
                                    </a>
                                </span>
                                <h1>Almost there!</h1>
                                <h2>Please enter your email to view your list of financial advisors</h2>
                                <div className="input-container">
                                    <input
                                        className="input full"
                                        type="email"
                                        id="email"
                                        placeholder="name@gmail.com"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
                                    />
                                </div>
                                {errors.email && (
                                    <p style={{ paddingTop: "10px" }} className="error-message">
                                        {errors.email}
                                    </p>
                                )}

                                <h2 style={{ marginBottom: "20px", marginTop: "10px" }}>
                                    Already have an account?{" "}
                                    <div className={styles.signInLink} onClick={() => setShowSignIn(true)}>
                                        Sign in now
                                    </div>
                                </h2>
                                <div
                                    className={styles.contactType}
                                    onClick={() => setPolicyAgreement(!policyAgreement)}
                                >
                                    <input
                                        type="checkbox"
                                        className={styles.inputHorizontalOffset}
                                        checked={policyAgreement}
                                        onChange={() => setPolicyAgreement(!policyAgreement)}
                                    />
                                    <span>
                                        By submitting, I agree to the{" "}
                                        <a
                                            href="https://www.advisorfinder.com/terms-of-service"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Terms of Service
                                        </a>
                                    </span>
                                </div>
                                <div className="input-container">
                                    <Button color="clear-button" type="submit" disabled={disabled}>
                                        <Eye size={20} />
                                        View Advisors
                                    </Button>
                                </div>
                            </form>
                            {showErrorBanner && (
                                <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errorBannerText}</ErrorBanner>
                            )}
                        </div>
                    )}
                    {showSignIn && (
                        <div>
                            <SignInForm assessmentResults={assessmentPayload}></SignInForm>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default ConsumerLogin;
