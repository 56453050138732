import React from "react";
import styles from "./ConfirmForm.module.scss";
import { Info, Check } from "react-feather";
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import firmAdminService from "../../../services/firmAdmin.service";
const classnames = require("classnames");

export default function ConfirmForm(props) {
    function cancelButton() {
        props.onClose();
    }

    async function onSubmit() {
        const columnData = [
            {
                appStatus: props.status,
            },
        ];

        const myInit = {
            body: {
                columnData,
            },
        };

        try {
            if (props.isAdvisor) {
                await advisorProfileService.update(props.advisorApp.professionalId, myInit);
                const newList = props.unverifiedAdvisors.filter((advisor) => {
                    return advisor.professionalId !== props.advisorApp.professionalId;
                });
                props.setUnverifiedAdvisors(newList);
            } else {
                await firmAdminService.update(props.advisorApp.id, myInit);
                const newList = props.unverifiedFirmAdmin.filter((firmAdmin) => {
                    return firmAdmin.id !== props.advisorApp.id;
                });
                props.setUnverifiedFirmAdmin(newList);
            }
            props.onClose();
        } catch (error) {
            console.log(error);
        }
    }

    function confirmationText() {
        if (props.status === "denied") {
            return `Are you sure you want to DENY ${props.advisorApp.firstName} ${props.advisorApp.lastName}`;
        }

        return `Are you sure you want to APPROVE ${props.advisorApp.firstName} ${props.advisorApp.lastName}`;
    }

    return (
        <>
            {props.advisorApp && (
                <div className={styles.intoFormContainer}>
                    <div
                        className={classnames(
                            styles.bannerContainer,
                            props.status === "verified" ? styles.greenBackground : styles.redBackground,
                        )}
                    >
                        <Info
                            size={20}
                            color={props.status === "verified" ? "var(--adfi_green500)" : "var(--adfi_red500)"}
                        />{" "}
                        <span className={styles.bannerText}>{confirmationText()}</span>
                    </div>
                </div>
            )}
            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>
                    Cancel
                </Button>
                <Button className="modal-cancel-button" color="gradient-button" onClick={onSubmit}>
                    <Check size={20} />
                    Confirm
                </Button>
            </div>
        </>
    );
}
