const isNotLoggedInAdvisor = () => {
    const locaUser = JSON.parse(localStorage.getItem("user"));
    const isConsumer = locaUser ? locaUser.groups.includes("Consumer") : false;

    if (isConsumer || !locaUser) {
        return true;
    }
    return false;
};

export default isNotLoggedInAdvisor;
