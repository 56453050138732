import React from "react";
import "./Button.scss";
import { Info } from "react-feather";

export default function SplitButton(props) {
    const color = props.color ? props.color : "gradient-button"; //clear-button
    const flex = props.flex ? "flex" : "";
    const isDisabled = props.disabled || false;
    const type = props.type || "button";
    const boxShadow = props.boxShadow ? "box-shadow" : "";

    return (
        <span className="splitButtonsContainer">
            <button
                className={`${color}  ${flex} ${boxShadow} ${props.className} buttonLeftBorderRadius nowrap`}
                disabled={isDisabled}
                type={type}
                onClick={props.onClick}
            >
                <div className="splitButtonChildren">
                    <div className="buttonFirstHalf">{props.children}</div>
                </div>
            </button>
            <button
                className={`${color}  ${flex} ${boxShadow} ${props.className} buttonRightBorderRadius nowrap`}
                disabled={isDisabled}
                type={type}
                onClick={props.secondaryOnClick}
            >
                <div className="splitButtonChildren">
                    <div className="buttonSecondHalf">
                        <Info size={20} />
                    </div>
                </div>
            </button>
        </span>
    );
}
