import React, { useState, useEffect } from "react";
import styles from "./NameForm.module.scss";
import { Check } from "react-feather";
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import consumerProfileService from "../../../services/consumerProfile.service";
import ErrorBanner from "../../../components/ErrorBanner/ErrorBanner";
import firmAdminService from "../../../services/firmAdmin.service";

export default function NameForm(props) {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [displayName, setDisplayName] = useState(props.user.displayName);
    const [firstName, setFirstName] = useState(props.user.firstName);
    const [lastName, setLastName] = useState(props.user.lastName);
    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const isAdvisor = props.user.groups.includes("Advisor");
    const firmAdminAuth = props.firmAdminAuth;

    function cancelButton() {
        props.onClose();
    }

    useEffect(() => {
        if (!isAdvisor) {
            if (firstName && lastName) {
                setSubmitDisabled(false);
            } else {
                setSubmitDisabled(true);
            }
        } else {
            if (displayName) {
                setSubmitDisabled(false);
            } else {
                setSubmitDisabled(true);
            }
        }
    }, [displayName, firstName, lastName]);

    async function onSubmit() {
        setSubmitDisabled(true);
        setShowErrorBanner(false);

        try {
            if (props.user.groups.includes("Advisor")) {
                const columnData = [
                    {
                        displayName,
                    },
                ];
                const myInit = {
                    body: {
                        columnData,
                    },
                };
                await advisorProfileService.update(props.user.id, myInit, firmAdminAuth);
            } else if (props.user.groups.includes("FirmAdmin")) {
                const columnData = [
                    {
                        firstName,
                    },
                    {
                        lastName,
                    },
                ];
                const myInit = {
                    body: {
                        columnData,
                    },
                };
                await firmAdminService.update(props.user.id, myInit);
            } else {
                const columnData = [
                    {
                        firstName,
                    },
                    {
                        lastName,
                    },
                ];
                const myInit = {
                    body: {
                        columnData,
                    },
                };
                await consumerProfileService.update(props.user.id, myInit);
            }
            props.setUser({
                ...props.user,
                displayName,
                firstName,
                lastName,
            });
            setSubmitDisabled(false);
            props.onClose();
        } catch (error) {
            console.log(error);
            setErrors(error.toString());
            setShowErrorBanner(true);
            setSubmitDisabled(false);
        }
    }

    return (
        <>
            <div className={styles.intoFormContainer}>
                {!isAdvisor && (
                    <>
                        <div className="input-container justify-left mobile-padding">
                            <label htmlFor="firstName">First Name</label>
                            <input
                                className="input full"
                                placeholder="First Name"
                                type="text"
                                id="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        {errors.firstName && (
                            <p className="error-message" style={{ marginTop: "10px" }}>
                                {errors.firstName}
                            </p>
                        )}
                        <div className="input-container justify-left mobile-padding">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                className="input full"
                                placeholder="Last Name"
                                type="text"
                                id="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </>
                )}
                {isAdvisor && (
                    <>
                        <div className="input-container justify-left mobile-padding">
                            <label htmlFor="displayName">Profile Name</label>
                            <input
                                className="input full"
                                placeholder="Name"
                                type="text"
                                id="displayName"
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                            />
                        </div>
                        {errors.firstName && (
                            <p className="error-message" style={{ marginTop: "10px" }}>
                                {errors.firstName}
                            </p>
                        )}
                        {showErrorBanner && (
                            <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>
                        )}
                    </>
                )}
            </div>
            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>
                    Cancel
                </Button>
                <Button
                    className="modal-cancel-button"
                    color="gradient-button"
                    onClick={onSubmit}
                    disabled={submitDisabled}
                >
                    <Check size={20} />
                    Confirm
                </Button>
            </div>
        </>
    );
}
