import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ArrowRight, CheckSquare } from "react-feather";
import validateAdvisorApply from "../../Utils/validateAdvisorApply";
import "../Register/Register.scss";
import advisorProfileService from "../../services/advisorProfile.service";
import consumerProfileService from "../../services/consumerProfile.service";
import ErrorBanner from "../../components/ErrorBanner/ErrorBanner";
import useGA4event from "../../Utils/useGA4events";
import useMixPanelEvent from "../../Utils/MixPanel";
import firmAdminService from "../../services/firmAdmin.service";
import ClipLoader from "react-spinners/ClipLoader";
import SEO from "../../components/SEO/seo";

function Apply() {
    const gaEventTrack = useGA4event("Apply Feedback");
    const mixpanelTrack = useMixPanelEvent();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [crd, setCrd] = useState("");
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [errorBannerText, setErrorBannerText] = useState("Something went wrong. Please try again.");
    const [submitSuccess] = useState(false);
    const [betaAgreement, setBetaAgreement] = useState(false);
    const [feedbackConfirmed, setFeedbackConfirmed] = useState(false);
    const feedbackOptions = ["LinkedIn", "Twitter", "Facebook", "Google Search", "Word of Mouth", "Email", "Other"];

    useEffect(() => {
        if (firstName === "" || lastName === "" || email === "" || zipCode === "" || phoneNumber === "" || crd === "") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [firstName, lastName, email, zipCode, phoneNumber, crd]);

    async function handleSubmit(event) {
        event.preventDefault();
        const validate = validateAdvisorApply({ firstName, lastName, email, zipCode, phoneNumber, crd });

        if (Object.keys(validate).length === 0 && betaAgreement) {
            try {
                setDisabled(true);
                setLoading(true);
                setShowErrorBanner(false);
                const [consumerResponse, response, firmAdminResponse] = await Promise.all([
                    consumerProfileService.checkEmail(email),
                    advisorProfileService.checkEmail(email),
                    firmAdminService.checkEmail(email),
                ]);
                if (!consumerResponse) {
                    if (!response && !firmAdminResponse) {
                        // email doesnt exist
                        mixpanelTrack("Advisor sign up", "Email", email);
                        await apply();
                        window.location.href = "https://www.advisorfinder.com/for-financial-advisors/welcome";
                    } else if (response) {
                        setErrorBannerText("You have already applied as an advisor with this email.");
                        setShowErrorBanner(true);
                    }
                } else if (consumerResponse) {
                    setErrorBannerText(
                        "You have already applied as a consumer with this email. Please try a different email",
                    );
                    setShowErrorBanner(true);
                }
            } catch (error) {
                // set try again error
                console.error(error);
                setShowErrorBanner(true);
            } finally {
                setDisabled(false);
                setLoading(false);
            }
        } else {
            setErrors(validate);
        }
    }

    async function apply() {
        try {
            const advisorInfo = {
                body: {
                    firstName,
                    lastName,
                    email,
                    zipCode,
                    phoneNumber,
                    crd,
                    secUrl: `https://adviserinfo.sec.gov/individual/summary/${crd}`,
                    betaAgreement,
                },
            };
            await advisorProfileService.create(advisorInfo);
        } catch (error) {
            console.log("Error creating application: ", error);
            throw new Error(error);
        }
    }

    const handleFeedback = (option) => {
        setFeedbackConfirmed(true);
        gaEventTrack("Discovery Type", { option });
        mixpanelTrack("Apply Feedback", "Discovery Type", { option });
    };

    return (
        <>
            <SEO
                title={`Financial Advisors Apply Here | AdvisorFinder`}
                description={`Apply to join AdvisorFinder as a financial advisor and elevate your online presence. Start growing your business with our digital platform and make it easy for clients who are seeking your expertise to find you. Complete the quick application to join today.`}
                name="AdvisorFinder"
                type="website"
                canonical={`https://advisorfinder.com/app/advisor-apply`}
            />
            <div className="page-container"></div>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            <div className="page-container">
                <div className="register-container left">
                    <div className="logo-container">
                        <a href="https://www.advisorfinder.com" rel="noopener">
                            <img
                                loading="lazy"
                                src="/app/AdFi-logo-name.svg"
                                alt="/AdFi-logo-name.svg"
                                className="register-logo"
                            />
                        </a>
                    </div>
                    {submitSuccess ? (
                        <div className="form-container success">
                            <img src="/app/sign-up-email.svg" alt="/sign-up-email.svg" />
                            <h1 className="register-header">Thank you!</h1>
                            <p className="register-paragraph" style={{ marginBottom: "20px" }}>
                                We have received your application and will review it soon. Check your email for status
                                updates concerning your application.
                            </p>

                            {feedbackConfirmed ? (
                                <div style={{ marginTop: "70px" }}>
                                    <h2 style={{ marginBottom: "20px" }}>Thanks for letting us know!</h2>
                                    <CheckSquare size={60} color="green" />
                                </div>
                            ) : (
                                <div>
                                    <h2 style={{ marginBottom: "10px" }}>How did you hear about us?</h2>
                                    <div className="feedbackContainer">
                                        {feedbackOptions.map((option, index) => {
                                            return (
                                                <div
                                                    className="feedbackOption"
                                                    key={index}
                                                    onClick={() => handleFeedback(option)}
                                                >
                                                    <span>{option}</span>
                                                    <CheckSquare size={20} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="form-container apply">
                            <h1 className="register-header">Professional Application</h1>
                            <p className="register-paragraph">
                                Do you already have an account? <Link to="/signin">Login Now</Link>
                            </p>
                            <form className="register-form" onSubmit={handleSubmit}>
                                <div className="input-container half-container">
                                    <input
                                        className="input half"
                                        type="text"
                                        id="firstName"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    <input
                                        className="input half"
                                        type="text"
                                        id="lastName"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                                {errors.name && <p className="error-message">{errors.name}</p>}
                                <div className="input-container mobile-padding">
                                    <input
                                        className="input full"
                                        id="email"
                                        placeholder="Enter email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
                                    />
                                </div>
                                {errors.email && <p className="error-message">{errors.email}</p>}
                                <div className="input-container">
                                    <input
                                        className="input full"
                                        id="zipCode"
                                        placeholder="Zip Code"
                                        type="number"
                                        value={zipCode}
                                        onChange={(e) => setZipCode(e.target.value)}
                                    />
                                </div>
                                {errors.zipCode && <p className="error-message">{errors.zipCode}</p>}
                                <div className="input-container">
                                    <input
                                        className="input full"
                                        id="phoneNumber"
                                        placeholder="Enter phone number"
                                        type="tel"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </div>
                                {errors.phoneNumber && <p className="error-message">{errors.phoneNumber}</p>}
                                <div className="input-container">
                                    <input
                                        className="input full"
                                        id="CRD"
                                        placeholder="Individual CRD Number (Firm CRD’s not accepted)"
                                        type="number"
                                        value={crd}
                                        onChange={(e) => setCrd(e.target.value)}
                                    />
                                </div>
                                {errors.crd && <p className="error-message">{errors.crd}</p>}
                                <div className="checkbox-container">
                                    <label className="optionRow">
                                        <input
                                            type="checkbox"
                                            value="betaAgreement"
                                            checked={betaAgreement}
                                            onChange={() => setBetaAgreement(!betaAgreement)}
                                        />
                                        <span className="checkmark"></span>
                                        <span>
                                            I have read the{" "}
                                            <a
                                                href="https://www.advisorfinder.com/license-agreement"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                License Agreement
                                            </a>{" "}
                                            and agree to the detailed terms
                                        </span>
                                    </label>
                                </div>
                                <div className="input-container">
                                    <Button color="clear-button" type="submit" disabled={disabled || !betaAgreement}>
                                        Apply to AdvisorFinder <ArrowRight size={20} />
                                    </Button>
                                </div>
                            </form>
                            {showErrorBanner && (
                                <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errorBannerText}</ErrorBanner>
                            )}
                        </div>
                    )}
                </div>
                <div className="register-container right">
                    <div className="right-side-text">
                        <h2 className="register-header">Welcome to the modern way of growing your business</h2>
                        <p className="register-paragraph">
                            You’re one step away from beginning your digital business development journey. We're excited
                            to help you elevate your entire online presence and start having future clients come to you!
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Apply;
