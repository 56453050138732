import React, { useEffect, useState } from "react";
import styles from "./Assessment.module.scss";
import Button from "../../components/Button/Button";
import { ArrowRight, ArrowLeft, Info } from "react-feather";
import validator from "validator";
import useIsScreenWidth from "../../hooks/useIsScreenWidth";

function AssessmentLocation(props) {
    const { setCurrentStage, setAssessmentSelection, preFill, mixpanelTrack } = props;
    const [zipCode, setZipCode] = useState();
    const [selectedMeetingType, setSelectedMeetingType] = useState();
    const [disabled, setDisabled] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [isError, setIsError] = useState(false);
    const [meetingType, setMeetingType] = useState([
        { name: "In-person", id: "IN_PERSON", value: false },
        { name: "Mostly virtual, but I want someone close to me", id: "MOSTLY_VIRTUAL", value: false },
        { name: "Fully virtual", id: "VIRTUAL", value: false },
    ]);

    useEffect(() => {
        if (meetingType[0].value === true || meetingType[1].value === true) {
            if (isChecked && zipCode?.toString().length === 5) {
                return setDisabled(false);
            }
        } else {
            if (isChecked) {
                setZipCode("");
                return setDisabled(false);
            }
        }

        setDisabled(true);
    }, [isChecked, meetingType, zipCode]);

    useEffect(() => {
        if (preFill["q4"]) {
            let i = 0;
            while (i < meetingType.length) {
                if (meetingType[i].id === preFill["q4"].meetingType) {
                    handleToggle(i);
                }
                i++;
            }
            setZipCode(preFill["q4"].zipCode);
            setDisabled(false);
        }
        mixpanelTrack("Assessment Q4", "Page View", true);
    }, []);

    const validate = () => {
        if (meetingType[0].value === true || meetingType[1].value === true) {
            if (!validator.isNumeric(zipCode) || zipCode.toString().length !== 5) {
                return setIsError(true);
            }
        }

        setIsError(false);
        setAssessmentSelection("q4", { zipCode: zipCode, meetingType: selectedMeetingType });
        nextStep();
    };

    const handleToggle = (i) => {
        let tempState = [...meetingType];
        for (let x = 0; x < tempState.length; x++) {
            tempState[x].value = false;
            if (x === i) {
                tempState[x].value = true;
                setSelectedMeetingType(tempState[x].id);
                setMeetingType(tempState[x].id);
            }
        }
        setIsChecked(true);
        setMeetingType(tempState);
    };

    const nextStep = () => {
        console.log(selectedMeetingType);
        mixpanelTrack("Assessment Q4", "User Response", selectedMeetingType);
        setCurrentStage(5);
    };

    return (
        <>
            <div className={styles.industryContainer}>
                <h1>How would you prefer to meet a financial advisor?</h1>
                <div className={styles.contactTypeContainer}>
                    {meetingType.map((type, i) => {
                        return (
                            <div key={i} className={styles.contactType} onClick={() => handleToggle(i)}>
                                <input
                                    type="checkbox"
                                    className={styles.inputHorizontalOffset}
                                    value={type.name}
                                    checked={type.value}
                                    onChange={() => handleToggle(i)}
                                />
                                {type.name}
                            </div>
                        );
                    })}
                </div>
                {(meetingType[0].value === true || meetingType[1].value === true) && (
                    <div className={styles.zipCodeContainer}>
                        <label htmlFor="zipCode">Enter your ZIP code</label>
                        <input
                            className="input full"
                            placeholder="Enter five digit zip code"
                            id="zipcode"
                            value={zipCode}
                            onChange={(e) => {
                                setZipCode(e.target.value);
                            }}
                        />
                        {isError && <span className="error-message">Please enter a valid 5 digit zipcode</span>}
                    </div>
                )}
                {useIsScreenWidth(720) && (
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                            marginTop: "30px",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Button color="clear-button white" onClick={() => setCurrentStage(3)}>
                            <ArrowLeft size={20} />
                            Back
                        </Button>
                        <Button onClick={() => validate()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.infoMessageContainer}>
                <Info size={20} color="#ABAAB3" />{" "}
                <span>
                    This will help you narrow down your choices of financial advisors based on how you want to meet
                    them. Remember, nearly all financial advisors work with clients who live in different states as
                    them.
                </span>
            </div>
            {!useIsScreenWidth(720) && (
                <div className={styles.mobileBottomNav}>
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Button color="clear-button white" onClick={() => setCurrentStage(3)}>
                            <ArrowLeft size={20} />
                            Back
                        </Button>
                        <Button onClick={() => validate()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default AssessmentLocation;
