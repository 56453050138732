import styles from "./Disclosure.module.scss";
import { React } from "react";

export default function AdvisorDisclosure(props) {
    const advisor = props.advisor;

    return (
        <>
            {advisor.disclosureText && (
                <div className={styles.disclosureContainer}>
                    <span className={styles.disclosureHeader}>Advisor Disclosure</span>
                    <div className={styles.disclosureText}>
                        {advisor.disclosureText}
                        {advisor.finraMember && (
                            <>
                                {" "}
                                Member{" "}
                                <a href="https://www.finra.org/#/" target="_blank" rel="noreferrer">
                                    FINRA
                                </a>
                                /
                                <a href="https://www.sipc.org/" target="_blank" rel="noreferrer">
                                    SIPC
                                </a>
                                .
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
