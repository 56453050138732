import { get, put, post } from "aws-amplify/api";

const advisorProfileService = {
    update,
    getOne,
    checkEmail,
    getMultiple,
    getSpecialties,
    getClientele,
    getPricing,
    getServices,
    getAssetRanges,
    getCredentials,
    getAll,
    getMeetingRequests,
    updateMeetingRequest,
    getLeads,
    create,
    marketPlaceSearch,
    getMarketplaceMappings,
    universityOptions,
    getCompletenessScore,
    getPromptOptions,
};

const myAPI = "ProfessionalsAPI";
const path = "/advisors";

async function getMultiple(params) {
    return handleAPICall(
        get({ apiName: myAPI, path: `${path}/getAll/active/${params ? `?limit=${params}&offset=0` : ""}` }),
    );
}

async function getAll(type, params) {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/getAll/${type}/${params ? params : ""}` }));
}

async function getOne(params) {
    try {
        return handleAPICall(get({ apiName: myAPI, path: `${path}/${params}` }));
    } catch (e) {
        console.error(e);
    }
}

async function checkEmail(params) {
    try {
        return handleAPICall(get({ apiName: myAPI, path: `${path}/userExists/${params}` }));
    } catch (e) {
        console.log(e);
    }
}

async function getSpecialties() {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/advisorStaticInfo/specialties` }));
}

async function getClientele() {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/advisorStaticInfo/clientele` }));
}

async function getPricing() {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/advisorStaticInfo/pricing` }));
}

async function getServices() {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/advisorStaticInfo/services` }));
}

async function getAssetRanges() {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/advisorStaticInfo/assetRanges` }));
}

async function getCredentials() {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/advisorStaticInfo/credentials` }));
}

async function getPromptOptions() {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/advisorStaticInfo/prompts` }));
}

async function getMeetingRequests(id) {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/meetingRequest/${id}` }));
}

async function updateMeetingRequest(params, myInit) {
    return handleAPICall(
        put({ apiName: myAPI, path: `${path}/meetingRequest/${params}`, options: addCurrentUserInfo(myInit) }),
    );
}

async function getLeads(id) {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/leads/${id}` }));
}

async function update(params, myInit, isFirmAdmin = false) {
    return handleAPICall(
        put({ apiName: myAPI, path: `${path}/${params}`, options: addCurrentUserInfo(myInit, isFirmAdmin) }),
    );
}

async function create(myInit) {
    return handleAPICall(post({ apiName: myAPI, path, options: myInit }));
}

async function marketPlaceSearch(myInit) {
    return handleAPICall(put({ apiName: myAPI, path: `${path}/marketplace`, options: myInit }));
}

async function getMarketplaceMappings() {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/marketplace/mappings` }));
}

async function universityOptions(name) {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/universityOptions?name=${name}` }));
}

async function getCompletenessScore(id) {
    return handleAPICall(get({ apiName: myAPI, path: `${path}/getCompletenessScore/${id}` }));
}

async function handleAPICall(fnc) {
    const { body } = await fnc.response;
    const json = body.json();
    return json;
}

function addCurrentUserInfo(myInit, isFirmAdmin = false) {
    const user = JSON.parse(localStorage.getItem("user"));
    myInit.body.currentUserId = isFirmAdmin ? user.firmID : user.id;
    myInit.body.userGroups = user.groups;
    myInit.body.firmID = isFirmAdmin ? user.firmID : undefined;
    return myInit;
}

export default advisorProfileService;
