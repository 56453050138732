import React, { useState } from "react";
import styles from "./AssessmentResults.module.scss";
import { MapPin, Star, Send } from "react-feather";
import Button from "../../components/Button/Button";
import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import { useLocalStorage } from "../../Utils/useLocalStorage";
import useMixPanelEvent from "../../Utils/MixPanel";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import consumerProfileService from "../../services/consumerProfile.service";

const classnames = require("classnames");

function AssessmentCard(props) {
    const mixpanelTrack = useMixPanelEvent();
    const [user, setUser] = useLocalStorage("user", null);
    const { advisor } = props;
    const { firmId, professionalId, cognitoUsername } = advisor;
    const profilePicture = useGetProfilePicture(firmId && firmId > 0 ? professionalId : cognitoUsername);

    const [isFavorite, setIsFavorite] = useState(user?.favoriteAdvisors?.includes(professionalId));

    async function setFavoriteAdvisor(event, professionalId) {
        event.stopPropagation();
        const existingFavorite = user.favoriteAdvisors ?? [];
        const removeIndex = existingFavorite.findIndex((id) => id === professionalId);
        if (removeIndex !== -1) {
            existingFavorite.splice(removeIndex, 1);
            setIsFavorite(false);
        } else {
            existingFavorite.push(professionalId);
            setIsFavorite(true);
        }
        const myInit = {
            body: {
                columnData: [{ favoriteAdvisors: existingFavorite }],
            },
        };
        await consumerProfileService.update(user.id, myInit);
        user.favoriteAdvisors = existingFavorite;
        setUser(user);
    }

    const handleContactButton = (event) => {
        event.stopPropagation();
        window.open(
            `/app/advisor-profile/${advisor.professionalId}/${advisor.firstName}-${advisor.lastName}/contact=true`,
        );
        mixpanelTrack("CTA Button Clicked", "Data", {
            "Advisor Name": `${advisor.firstName} ${advisor.lastName} - ${advisor.professionalId}`,
            Screen: "Assessment Results",
            CTA: "Contact", // Change this based on the actual CTA text
        });
    };

    return (
        <>
            <div
                className={styles.assessmentCardContainer}
                onClick={() =>
                    window.open(
                        `/app/advisor-profile/${advisor.professionalId}/${advisor.firstName}-${advisor.lastName}`,
                    )
                }
            >
                <div className={styles.assessmentCardImgContainer}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div className={styles.locationContainer}>
                            <MapPin size={15} /> {advisor.city}, {advisor.state}
                        </div>
                        <div
                            className={styles.favoriteContainer}
                            onClick={(e) => setFavoriteAdvisor(e, professionalId)}
                        >
                            {isFavorite ? <Star size={20} color="yellow" fill="yellow" /> : <Star size={20} />}
                        </div>
                    </div>
                    <img
                        fetchPriority="high"
                        className={classnames(
                            styles.profilePicture,
                            !(profilePicture === "/app/images/profilePictureDefault.svg")
                                ? styles.gradientBorder
                                : styles.pupleBorder,
                        )}
                        src={profilePicture ?? "/app/images/profilePictureDefault.svg"}
                        alt="profile_picture"
                    />
                </div>
                <div className={styles.assessmentCardImgContainerBottom} />
                <div className={styles.assessmentCardContentContainer}>
                    <span style={{ textAlign: "center" }}>
                        <h1>{advisor.displayName}</h1>
                        <h2>
                            Works at <b>{advisor.companyName}</b>
                        </h2>
                    </span>
                    <div>
                        <Button color="gradient-button fullWidth" size="thin" onClick={(e) => handleContactButton(e)}>
                            <Send size={20} />
                            <span className="shrinkText">Contact</span>
                        </Button>
                    </div>
                    <span style={{ height: "90px" }}>
                        <p className={styles.tagsHeader}>Services Provided</p>
                        <div className={styles.tagsContainer}>
                            {advisor.services?.slice(0, 3).map((client, index) => (
                                <div key={index} className={styles.tag}>
                                    <b>{client.value}</b>
                                </div>
                            ))}
                            {advisor.services?.length > 3 && (
                                <div key={4} className={styles.tag}>
                                    <b>+{advisor.services?.length - 3}</b>
                                </div>
                            )}
                        </div>
                    </span>
                    <span style={{ height: "90px" }}>
                        <p className={styles.tagsHeader}>Typical Clientele</p>
                        <div className={styles.tagsContainer}>
                            {advisor.clientele?.slice(0, 3).map((client, index) => (
                                <div key={index} className={styles.tag}>
                                    <b>{client.value}</b>
                                </div>
                            ))}
                            {advisor.clientele?.length > 3 && (
                                <div key={4} className={styles.tag}>
                                    <b>+{advisor.clientele?.length - 3}</b>
                                </div>
                            )}
                        </div>
                    </span>
                    {advisor.averageAccountSize && (
                        <div className={styles.footerContainer}>
                            <span className={styles.footerStat}>
                                Average Account Size:
                                <span className={styles.spacingDots}>
                                    ....................................................................
                                </span>
                            </span>
                            <span>
                                {" "}
                                <b>{advisor.averageAccountSize}</b>
                            </span>
                        </div>
                    )}
                    {advisor.yearsOfExperience && (
                        <div className={styles.footerContainer}>
                            <span className={styles.footerStat}>
                                Years of Experience:
                                <span className={styles.spacingDots}>
                                    ....................................................................
                                </span>
                            </span>
                            <span style={{ whiteSpace: "nowrap" }}>
                                {" "}
                                <b>{advisor.yearsOfExperience}</b>
                            </span>
                        </div>
                    )}
                    {advisor.clientNumber && (
                        <div className={styles.footerContainer}>
                            <span className={styles.footerStat}>
                                Number of Clients:
                                <span className={styles.spacingDots}>
                                    ....................................................................
                                </span>
                            </span>
                            <span>
                                {" "}
                                <b>{advisor.clientNumber}</b>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default AssessmentCard;
