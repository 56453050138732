import React from "react";
import styles from "./Marketplace.module.scss";
import { XCircle } from "react-feather";
import { states, statesFull } from "../../constants/states";

function CurrentFilters(props) {
    const { currentFiltersArray, clearSpecficFilter } = props;

    const stage = props.lifeStageFilters;
    const type = props.industryFilters;
    const help = props.helpWithFilters;

    let stateIndex = states.findIndex((x) => x === currentFiltersArray[0]);

    const stateFilter = currentFiltersArray[0] && (
        <span className={styles.currentFilter}>
            Located in {statesFull[stateIndex]}
            <XCircle className={styles.xButton} onClick={() => clearSpecficFilter("state")} />
        </span>
    );
    const industryType = currentFiltersArray[1] !== 0 && (
        <span className={styles.currentFilter}>
            Specializes in {type[currentFiltersArray[1] - 1]?.value}
            <XCircle className={styles.xButton} onClick={() => clearSpecficFilter("industryType")} />
        </span>
    );
    const lifeStage = currentFiltersArray[2] !== 0 && (
        <span className={styles.currentFilter}>
            Works with {stage[currentFiltersArray[2] - 1]?.value}
            <XCircle className={styles.xButton} onClick={() => clearSpecficFilter("lifeStage")} />
        </span>
    );
    const helpWith =
        help &&
        currentFiltersArray[3]?.length > 0 &&
        currentFiltersArray[3]?.map((filter, index) => {
            const displayValue = help.find((item) => item.id === filter)?.value;
            if (displayValue)
                return (
                    <span key={index} className={styles.currentFilter}>
                        Helps with {help.find((item) => item.id === filter)?.value}
                        <XCircle className={styles.xButton} onClick={() => clearSpecficFilter("helpWith", filter)} />
                    </span>
                );
        });

    return (
        <div className={styles.currentFiltersContainer}>
            {stateFilter}
            {industryType}
            {lifeStage}
            {helpWith}
        </div>
    );
}

export default CurrentFilters;
