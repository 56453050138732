import validator from "validator";

function validateContactAdvisor(values) {
    let errors = {};

    if (!validator.matches(values.fullName, "^[a-zA-Z-,.' -]*$")) {
        errors.fullName = "Enter valid name";
    }

    if (!validator.isEmail(values.email)) {
        errors.email = "Enter valid email";
    }
    if (values.meetingDetails.length > 1000) {
        errors.meetingDetails = "Please limit your message to 1000 characters or less";
    }

    return errors;
}

export default validateContactAdvisor;
