import React, { useState } from "react";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import ScheduleCallInfoInput from "./ScheduleCallInfoInput";
import ScheduleCallComplete from "./ScheduleCallComplete";
import consumerProfileService from "../../../services/consumerProfile.service";
import useMixPanelEvent from "../../../Utils/MixPanel";
import PropTypes from "prop-types";
import { advisorProp } from "../../../Utils/commonPropType";

const ScheduleCallNoUserForm = (props) => {
    const mixpanelTrack = useMixPanelEvent();

    const [infoEntryComplete, setInfoEntryComplete] = useState(false);
    const [calendlyComplete, setCalendlyComplete] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [submited, setSubmited] = useState(false);
    const advisor = props.advisor;
    const hasCalendly = advisor.calendlyUser && advisor.calendlyUrl.length > 0 ? true : false;

    async function handleInfoInputComplete(data) {
        if (hasCalendly) {
            setInfoEntryComplete(true);
            let meetingInfo = {
                isThirdParty: true,
                advisorName: `${advisor.firstName} ${advisor.lastName}`,
                consumerName: data.fullName,
                consumerEmail: data.email,
            };
            //send email to advisor
            mixpanelTrack("Meeting Requested", "Meeting Info - 3rd Party", meetingInfo);
            setSubmitDisabled(true);
            const myInit = {
                body: {
                    professionalId: advisor.professionalId,
                    advisorEmail: advisor.email,
                    consumerName: data.fullName,
                    consumerEmail: data.email,
                    consumerPhoneNumber: "",
                    meetingFormat: "",
                    isThirdParty: true,
                },
            };
            await consumerProfileService.createMeetingRequest(myInit);
        } else {
            setCalendlyComplete(true);
            await createMeetingRequest(data);
        }
    }

    useCalendlyEventListener({
        onEventScheduled: () => {
            setCalendlyComplete(true);
        },
    });

    async function createMeetingRequest(data) {
        //create meeting request with info given

        let meetingInfo = {
            calendlyUser: hasCalendly,
            advisorName: `${advisor.firstName} ${advisor.lastName}`,
            consumerName: data.fullName,
            consumerEmail: data.email,
        };

        mixpanelTrack("Meeting Requested", "Meeting Info", meetingInfo);
        setSubmitDisabled(true);
        const myInit = {
            body: {
                professionalId: advisor.professionalId,
                advisorEmail: advisor.email,
                consumerName: data.fullName,
                consumerEmail: data.email,
                consumerPhoneNumber: data.phone,
                meetingFocus: data.meetingDetails,
                meetingFormat: data.scheduleType,
                isThirdParty: hasCalendly,
            },
        };

        try {
            await consumerProfileService.createMeetingRequest(myInit);
            setSubmited(true);
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitDisabled(false);
        }
    }

    return (
        <>
            {!infoEntryComplete && !submited && (
                <ScheduleCallInfoInput
                    advisor={props.advisor}
                    submitDisabled={submitDisabled}
                    handleInfoInputComplete={handleInfoInputComplete}
                />
            )}
            {infoEntryComplete && hasCalendly && !calendlyComplete && <InlineWidget url={advisor.calendlyUrl} />}
            {infoEntryComplete && calendlyComplete && <ScheduleCallComplete advisorName={advisor.firstName} />}
            {submited && !hasCalendly && <ScheduleCallComplete advisorName={advisor.firstName} />}
        </>
    );
};

ScheduleCallNoUserForm.propTypes = {
    advisor: PropTypes.shape(advisorProp),
};

export default ScheduleCallNoUserForm;
