import React from "react";
import styles from "./TextArea.module.scss";
import classNames from "classnames";

export default function TextArea(props) {
    const { value, onChange, disabled, characterCounter, maxLength, rows, error } = props;
    const errorStyle = error ? styles.hasError : "";

    const calculateRemainingCharacter = (maxLength, currentLength) => {
        if (!currentLength) {
            return maxLength;
        }
        return maxLength - currentLength.length;
    };

    return (
        <>
            <textarea
                className={classNames(styles.textArea, errorStyle)}
                value={value}
                onChange={onChange}
                disabled={disabled}
                maxLength={maxLength ? maxLength : ""}
                rows={rows ? rows : ""}
            />
            {characterCounter && (
                <h1 className={styles.textAreaCharacterCount}>
                    {calculateRemainingCharacter(maxLength, value)} characters left
                </h1>
            )}
            {error && <div className={styles.errorMessage}>{error}</div>}
        </>
    );
}
