import React, { useState, useEffect } from "react";
import styles from "./PricingForm.module.scss";
import { Check } from "react-feather";
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import TextInput from "../../TextInput/TextInput";
import PropTypes from "prop-types";
import { advisorProp } from "../../../Utils/commonPropType";
import Dropdown from "../../Dropdown/Dropdown";

const PricingForm = (props) => {
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [errors, setErrors] = useState({});

    const advisor = props.advisor;
    const [flatFeeMin, setFlatFeeMin] = useState("");
    const [flatFeeMax, setFlatFeeMax] = useState("");
    const [AUMMin, setAUMMin] = useState("");
    const [AUMMax, setAUMMax] = useState("");
    const [oneTimePlan, setOneTimePlan] = useState("");
    const [hourlyRate, setHourlyRate] = useState("");

    const [showFlatFee, setShowFlatFee] = useState(false);
    const [showAUMFee, setShowAUMFee] = useState(false);
    const [showOneTimeFee, setShowOneTimeFee] = useState(false);
    const [showHourlyFee, setShowHourlyFee] = useState(false);

    const [aumFequency, setAumFequency] = useState(-1);

    const frequencyOptions = [
        { id: "MONTHLY", displayValue: "Monthly" },
        { id: "QUARTERLY", displayValue: "Quarterly" },
        { id: "YEARLY", displayValue: "Yearly" },
    ];

    const firmAdminAuth = props.firmAdminAuth;

    useEffect(() => {
        const advisorAUMResponse = advisor.pricingV2?.find((pricing) => pricing.typeName === "AUM");
        setAUMMax(advisorAUMResponse?.maxRange);
        setAUMMin(advisorAUMResponse?.minRange);
        const advisorFlatResponse = advisor.pricingV2?.find((pricing) => pricing.typeName === "FLAT_FEE");
        setFlatFeeMax(advisorFlatResponse?.maxRange);
        setFlatFeeMin(advisorFlatResponse?.minRange);

        switch (advisorFlatResponse?.frequency) {
            case "MONTHLY":
                setAumFequency("MONTHLY");
                break;
            case "QUARTERLY":
                setAumFequency("MONTHLY");
                break;
            case "YEARLY":
                setAumFequency("MONTHLY");
                break;
        }

        const advisorOneTimeResponse = advisor.pricingV2?.find((pricing) => pricing.typeName === "ONE_TIME");
        setOneTimePlan(advisorOneTimeResponse?.cost);

        const advisorHourlyTimeResponse = advisor.pricingV2?.find((pricing) => pricing.typeName === "HOURLY");
        setHourlyRate(advisorHourlyTimeResponse?.rate);

        if (advisorFlatResponse) {
            setShowFlatFee(true);
        }
        if (advisorAUMResponse) {
            setShowAUMFee(true);
        }
        if (advisorOneTimeResponse) {
            setShowOneTimeFee(true);
        }
        if (advisorHourlyTimeResponse) {
            setShowHourlyFee(true);
        }
    }, [advisor]);

    function cancelButton() {
        props.onClose();
    }

    async function onSubmit() {
        setSubmitDisabled(true);

        const pricingV2 = [];
        if (showAUMFee) {
            const aumPricing = {
                typeName: "AUM",
                minRange: AUMMin ?? undefined,
                maxRange: AUMMax ?? undefined,
            };
            pricingV2.push(aumPricing);
        }
        if (showFlatFee) {
            let frequency = aumFequency;
            const flatFeePricing = {
                typeName: "FLAT_FEE",
                minRange: flatFeeMin ?? undefined,
                maxRange: flatFeeMax ?? undefined,
                frequency: frequency ?? undefined,
            };
            pricingV2.push(flatFeePricing);
        }
        if (showOneTimeFee) {
            const oneTimePricing = {
                typeName: "ONE_TIME",
                cost: oneTimePlan ?? undefined,
            };
            pricingV2.push(oneTimePricing);
        }

        if (showHourlyFee) {
            const hourlyPricing = {
                typeName: "HOURLY",
                rate: hourlyRate ?? undefined,
            };
            pricingV2.push(hourlyPricing);
        }

        const columnData = [
            {
                pricingV2,
            },
        ];
        const myInit = {
            body: {
                columnData,
            },
        };

        try {
            await Promise.all([
                advisorProfileService.update(advisor.professionalId, myInit, firmAdminAuth),
                props.updateCompletenessScore(),
            ]);
            advisor.pricingV2 = pricingV2;
            props.setAdvisor(advisor);
            setSubmitDisabled(false);
            props.onClose();
        } catch (error) {
            setSubmitDisabled(false);
            setErrors(error.toString());
            console.log(error);
        }
    }

    return (
        <>
            <h1 style={{ marginBottom: "15px", textAlign: "left", "font-size": "16px", "font-weight": "normal" }}>
                Please select pricing model to add (you can add one pricing model at a time):
            </h1>

            <div className={styles.pricingModelInputContainer}>
                {showFlatFee && (
                    <div className={styles.checkCircle} onClick={() => setShowFlatFee(false)}>
                        <Check size={20} color={"var(--adfi_white)"}></Check>
                    </div>
                )}
                {!showFlatFee && (
                    <div className={styles.checkCircleUnclicked} onClick={() => setShowFlatFee(true)}></div>
                )}
                <div className={styles.sectionContainer}>
                    <h1 className={styles.questionHeader}>Flat Fee</h1>
                    <span
                        style={{
                            textAlign: "left",
                            "font-size": "14px",
                            "font-weight": "normal",
                        }}
                    >
                        Advisors who charge flat fees for their services charge specific dollar amounts, not percentage
                        points. This can be referred to as a retainer. Flat fees are often used for ongoing services or
                        comprehensive planning.
                    </span>

                    {showFlatFee && (
                        <div className={styles.inputContainer}>
                            <span className={styles.inputLabel}>Flat fee price ranges:</span>
                            <span>from</span>
                            <TextInput
                                placeholder="Minimum ($)"
                                value={flatFeeMin ? "$" + flatFeeMin : ""}
                                error={errors.flatFeeMin}
                                className={styles.input}
                                onChange={(e) => setFlatFeeMin(e.target.value.replace("$", ""))}
                            />
                            <span>to</span>
                            <TextInput
                                placeholder="Maximum ($)"
                                value={flatFeeMax ? "$" + flatFeeMax : ""}
                                error={errors.flatFeeMax}
                                className={styles.input}
                                onChange={(e) => setFlatFeeMax(e.target.value.replace("$", ""))}
                            />
                        </div>
                    )}
                    {showFlatFee && (
                        <div className={styles.inputContainer}>
                            <span className={styles.inputLabel}>Billing frequency:</span>
                            <Dropdown value={aumFequency} onChange={(e) => setAumFequency(e.target.value)}>
                                <option value={-1} disabled={false}>
                                    Select a frequency
                                </option>
                                {frequencyOptions.map((option, i) => (
                                    <option value={option.id} key={i}>
                                        {option.displayValue}
                                    </option>
                                ))}
                            </Dropdown>
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.pricingModelInputContainer}>
                {showAUMFee && (
                    <div className={styles.checkCircle} onClick={() => setShowAUMFee(false)}>
                        <Check size={20} color={"var(--adfi_white)"}></Check>
                    </div>
                )}
                {!showAUMFee && <div className={styles.checkCircleUnclicked} onClick={() => setShowAUMFee(true)}></div>}
                <div className={styles.sectionContainer}>
                    <h1 className={styles.questionHeader}>AUM Fee (%)</h1>
                    <span
                        style={{
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: "normal",
                        }}
                    >
                        Advisors who charge an AUM fee, assets under management fee, charge a percentage fee based on
                        the amount of assets a client has. This fee typically begins at 1% of AUM, but is negotiable
                        between the advisor and client. It is not uncommon for advisors to charge much less than 1%
                        based on the amount of assets a client has.
                    </span>

                    {showAUMFee && (
                        <div className={styles.inputContainer}>
                            <span className={styles.inputLabel}>AUM fee range:</span>
                            <span>from</span>
                            <TextInput
                                placeholder="Minimum (%)"
                                value={AUMMin}
                                error={errors.AUMMin}
                                className={styles.input}
                                onChange={(e) => setAUMMin(e.target.value)}
                            />
                            <span>to</span>
                            <TextInput
                                placeholder="Maximum (%)"
                                value={AUMMax}
                                error={errors.AUMMax}
                                className={styles.input}
                                onChange={(e) => setAUMMax(e.target.value)}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.pricingModelInputContainer}>
                {showOneTimeFee && (
                    <div className={styles.checkCircle} onClick={() => setShowOneTimeFee(false)}>
                        <Check size={20} color={"var(--adfi_white)"}></Check>
                    </div>
                )}
                {!showOneTimeFee && (
                    <div className={styles.checkCircleUnclicked} onClick={() => setShowOneTimeFee(true)}></div>
                )}
                <div className={styles.sectionContainer}>
                    <h1 className={styles.questionHeader}>One-Time Financial Plan</h1>
                    <span
                        style={{
                            textAlign: "left",
                            "font-size": "14px",
                            "font-weight": "normal",
                        }}
                    >
                        Advisors who charge a one-time financial plan fee provide a comprehensive financial analysis and
                        strategy for a set price. This fee covers the creation of a detailed financial plan tailored to
                        the client's specific goals and circumstances.
                    </span>

                    {showOneTimeFee && (
                        <div className={styles.inputContainer}>
                            <span className={styles.inputLabel}>Fee:</span>
                            <TextInput
                                placeholder="Minimum ($)"
                                value={oneTimePlan ? "$" + oneTimePlan : ""}
                                error={errors.oneTimePlan}
                                className={styles.input}
                                onChange={(e) => setOneTimePlan(e.target.value.replace("$", ""))}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.pricingModelInputContainer}>
                {showHourlyFee && (
                    <div className={styles.checkCircle} onClick={() => setShowHourlyFee(false)}>
                        <Check size={20} color={"var(--adfi_white)"}></Check>
                    </div>
                )}
                {!showHourlyFee && (
                    <div className={styles.checkCircleUnclicked} onClick={() => setShowHourlyFee(true)}></div>
                )}
                <div className={styles.sectionContainer}>
                    <h1 className={styles.questionHeader}>Hourly Rate</h1>
                    <span
                        style={{
                            textAlign: "left",
                            "font-size": "14px",
                            "font-weight": "normal",
                        }}
                    >
                        Advisors who charge hourly fees bill clients based on the time spent working on their financial
                        matters. This fee structure is often used for specific tasks or consultations. Hourly rates can
                        vary widely depending on the advisor's experience and expertise. This approach allows clients to
                        pay only for the time and services they need, making it flexible for those who don't require
                        ongoing management.
                    </span>

                    {showHourlyFee && (
                        <div className={styles.inputContainer}>
                            <span className={styles.inputLabel}>Rate:</span>
                            <TextInput
                                placeholder="$/hour"
                                value={hourlyRate ? "$" + hourlyRate : ""}
                                error={errors.hourlyRate}
                                className={styles.input}
                                onChange={(e) => setHourlyRate(e.target.value.replace("$", ""))}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>
                    Cancel
                </Button>
                <Button
                    className="modal-cancel-button"
                    color="gradient-button"
                    onClick={onSubmit}
                    disabled={submitDisabled}
                >
                    <Check size={20} />
                    Confirm
                </Button>
            </div>
        </>
    );
};

PricingForm.propTypes = {
    advisor: PropTypes.shape(advisorProp),
    setAdvisor: PropTypes.func.isRequired,
    firmAdminAuth: PropTypes.bool,
    updateCompletenessScore: PropTypes.func,
    onClose: PropTypes.func,
};

export default PricingForm;
