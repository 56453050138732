import { get, post } from "aws-amplify/api";

const consumerProfileService = {
    update,
    getOne,
    create,
    checkEmail,
    createMeetingRequest,
    createLead,
    getassessmentResults,
};

const apiName = "ConsumersAPI";
const apiPath = "/consumer";

async function create(myInit) {
    const path = apiPath;
    const options = myInit;
    return handleAPICall(post({ apiName, path, options }));
}

async function getOne(params) {
    const path = `${apiPath}/${params}`;
    return handleAPICall(get({ apiName, path }));
}

async function createMeetingRequest(meetingRequest) {
    const path = `${apiPath}/meetingRequest`;
    const options = meetingRequest;
    return handleAPICall(post({ apiName, path, options }));
}

async function createLead(lead) {
    const path = `${apiPath}/lead`;
    const options = lead;
    return handleAPICall(post({ apiName, path, options }));
}

async function checkEmail(params) {
    const path = `${apiPath}/userExists/${params}`;
    return handleAPICall(get({ apiName, path }));
}

async function update(params, myInit) {
    const path = `${apiPath}/update/${params}`;
    const options = addCurrentUserInfo(myInit);
    return handleAPICall(post({ apiName, path, options }));
}

async function getassessmentResults(params, assessmentAnswers) {
    const path = `${apiPath}/assessmentResults/${params}`;
    const options = assessmentAnswers;
    return handleAPICall(post({ apiName, path, options }));
}

async function handleAPICall(fnc) {
    const { body } = await fnc.response;
    const json = body.json();
    return json;
}

function addCurrentUserInfo(myInit) {
    myInit.body.currentUserId = JSON.parse(localStorage.getItem("user")).id;
    return myInit;
}

export default consumerProfileService;
