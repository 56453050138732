import React, { useEffect, useState } from "react";
import styles from "./Specialties.module.scss";
import { ChevronUp, Plus, CheckCircle } from "react-feather";
import { SortableList } from "../SotableList/SortableList";
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import FormModal from "../../Modals/FormModal";
import SpecialitiesExpertiseInfo from "../../Forms/SpecialitiesExpertiseInfo/SpecialitiesExpertiseInfo";
import ContactAdvisorForm from "../../Forms/ContactAdvisorForm/ContactAdvisorForm";

const classnames = require("classnames");

export default function Specialties(props) {
    const editMode = props.editMode;
    const title = props.title;
    const subtext = props.subtext;
    const professionalId = props.professionalId;

    const [data, setData] = useState(props.data);
    const [showMore, setShowMore] = useState(true);
    const [showUpdateButton, setShowUpdateButton] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [showSpecialitiesModal, setShowSpecialitiesModal] = useState();
    const [selectedSpeciality, setSelectedSpeciality] = useState();
    const [showContactAdvisorModal, setShowContactAdvisorModal] = useState(false);
    const firmAdminAuth = props.firmAdminAuth;

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    useEffect(() => {
        if (editMode) {
            data.forEach((item, index) => {
                if (item.sortingPriority !== index) {
                    item.sortingPriority = index;
                    setSubmitSuccess(false);
                    setShowUpdateButton(true);
                }
            });
        }
    }, [data]);

    useEffect(() => {
        if (editMode) setShowMore(true);
    }, [editMode]);

    async function updateOrder() {
        await onSubmit();
    }

    async function onSubmit() {
        setSubmitDisabled(true);
        let columnData;
        if (props.title === "Clientele") {
            columnData = [
                {
                    clientele: data,
                },
            ];
        } else {
            columnData = [
                {
                    specialties: data,
                },
            ];
        }
        const myInit = {
            body: {
                columnData,
            },
        };

        try {
            await advisorProfileService.update(professionalId, myInit, firmAdminAuth);
            props.setData(data);
            setSubmitSuccess(true);
            setShowUpdateButton(false);
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitDisabled(false);
        }
    }

    const showSelectedSpecialities = (modalState, index) => {
        setShowSpecialitiesModal(modalState);
        setSelectedSpeciality(index);
    };

    return (
        <>
            {showSpecialitiesModal && (
                <FormModal
                    onClose={() => setShowSpecialitiesModal(false)}
                    show={showSpecialitiesModal}
                    noPadding={true}
                >
                    <SpecialitiesExpertiseInfo
                        data={data}
                        currentIndex={selectedSpeciality}
                        setSelectedSpeciality={setSelectedSpeciality}
                        setShowContactAdvisorModal={setShowContactAdvisorModal}
                        setShowSpecialitiesModal={setShowSpecialitiesModal}
                    ></SpecialitiesExpertiseInfo>
                </FormModal>
            )}
            {showContactAdvisorModal && (
                <FormModal
                    onClose={() => setShowContactAdvisorModal(false)}
                    show={showContactAdvisorModal}
                    noPadding={true}
                >
                    <ContactAdvisorForm
                        advisor={props.advisor}
                        setShowContactAdvisorModal={setShowContactAdvisorModal}
                    ></ContactAdvisorForm>
                </FormModal>
            )}
            <div className={styles.specialitiesContainer}>
                <div className={styles.specialitiesHeader}>
                    <ChevronUp
                        className={classnames(styles.showMoreArrow, showMore && styles.open)}
                        onClick={() => setShowMore(!showMore)}
                        size={20}
                        color="rgba(45, 43, 65, 1)"
                    />
                    <h2>{title}</h2>
                    <div className={styles.countNumber}>{data.length}</div>
                    {editMode && showUpdateButton && (
                        <div>
                            <Button
                                className={styles.submitOrderButton}
                                color="clear-button"
                                onClick={() => updateOrder()}
                                disabled={submitDisabled}
                            >
                                Update Order
                            </Button>
                        </div>
                    )}
                    {submitSuccess && (
                        <div>
                            <CheckCircle size={16} color="var(--adfi_green500)" />
                        </div>
                    )}
                    {editMode && (
                        <div className={styles.addIcons} onClick={props.onClick}>
                            <Plus size={16} color="rgba(129, 128, 141, 1)" />
                        </div>
                    )}
                </div>
                {editMode && <span className={styles.headerSubText}>{subtext}</span>}

                {!editMode && title === "Specialties and Expertise" && (
                    <div className={classnames(styles.dataContainer, !showMore && styles.clamp)}>
                        {data.map((speciality, index) => (
                            <div
                                className={styles.speciality}
                                key={index}
                                onClick={() => showSelectedSpecialities(true, index)}
                            >
                                {speciality.value}
                            </div>
                        ))}
                    </div>
                )}

                {!editMode && title === "Clientele" && (
                    <div className={classnames(styles.dataContainer, !showMore && styles.clamp)}>
                        {data.map((speciality, index) => (
                            <div className={styles.clientele} key={index}>
                                {speciality.value}
                            </div>
                        ))}
                    </div>
                )}

                {editMode && (
                    <SortableList
                        items={data}
                        onChange={setData}
                        renderItem={(speciality) => (
                            <SortableList.Item id={speciality.id}>
                                <div className={styles.specialitySortable}>
                                    <div style={{ paddingTop: "4px" }}>
                                        <SortableList.DragHandle style={{ backgroundColor: "white" }} />
                                    </div>
                                    <div>{speciality.value}</div>
                                </div>
                            </SortableList.Item>
                        )}
                    />
                )}
            </div>
        </>
    );
}
