import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import styles from "./AdvisorConfirmInfo.module.scss";
import Button from "../../components/Button/Button";
import { ArrowRight, Info } from "react-feather";
import "../Register/Register.scss";
import advisorProfileService from "../../services/advisorProfile.service";
import ErrorBanner from "../../components/ErrorBanner/ErrorBanner";
import { useNavigate } from "react-router";
import ClipLoader from "react-spinners/ClipLoader";
import FormModal from "../../components/Modals/FormModal";
import CalendlyInfo from "../../components/Forms/CalendlyInfo/CalendlyInfo";
import { states } from "../../constants/states";
import validateConfirmInfo from "../../Utils/validateConfirmInfo";

function AdvisorConfirmInfo() {
    const navigate = useNavigate();

    let { professionalId } = useParams();

    const [advisor, setAdvisor] = useState({});

    const [companyName, setCompanyName] = useState(advisor.companyName);
    const [jobTitle, setJobTitle] = useState(advisor.jobTitle);
    const [city, setCity] = useState(advisor.city);
    const [state, setState] = useState(advisor.state);
    const [yearsOfExperience, setYearsOfExperience] = useState(advisor.yearsOfExperience);
    const [averageAccountSize, setAverageAccountSize] = useState(advisor.averageAccountSize);
    const [numberOfClients, setNumberOfClients] = useState(advisor.numberOfClients);
    const [AUM, setAUM] = useState(advisor.AUM);
    const [accountMinimum, setAccountMinimum] = useState(advisor.minAccountSize);
    const [healthInsurance, setHealthInsurance] = useState(null);

    const [url, setUrl] = useState("");

    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showCalendlyInfo, setShowCalendlyInfo] = useState(false);

    const modalText =
        "To give you the best user experience, we want to integrate meetings with your existing calendar seamlessly. By giving us your Calendly URL, future clients of yours can easily schedule a time to meet with you directly from your AdvisorFinder profile. This saves you time by avoiding back-and-forth communication to schedule a meeting. You'll be able to set it up later on!";

    const getAdvisorInfo = useCallback(async () => {
        try {
            const advisorResponse = await advisorProfileService.getOne(professionalId);
            setAdvisor(advisorResponse);
        } catch (error) {
            console.log(error);
            navigate(`/404`, { replace: true });
        }
    }, [navigate, professionalId]);

    useEffect(() => {
        const fetchAdvisorInfo = async () => {
            setLoading(true);
            await getAdvisorInfo();
            setLoading(false);
        };

        fetchAdvisorInfo();
    }, [getAdvisorInfo, professionalId]);

    useEffect(() => {
        const setAdvisorInfo = async () => {
            setCompanyName(advisor.companyName);
            setJobTitle(advisor.jobTitle);
            setCity(advisor.city);
            setState(advisor.state);
            setYearsOfExperience(advisor.yearsOfExperience);
        };

        setAdvisorInfo();
    }, [advisor]);

    useEffect(() => {
        if (!companyName || !jobTitle || !state || !city || !yearsOfExperience || !averageAccountSize) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [averageAccountSize, city, companyName, jobTitle, state, yearsOfExperience]);

    async function handleSubmit(event, submitInfo) {
        event.preventDefault();

        const validate = validateConfirmInfo({
            companyName,
            jobTitle,
            city,
            state,
            yearsOfExperience,
            averageAccountSize,
            AUM,
            numberOfClients,
        });
        if (Object.keys(validate).length !== 0) {
            return setErrors(validate);
        } else {
            setErrors({});
        }

        setLoading(true);
        setDisabled(true);

        let columnData = {};

        if (submitInfo) {
            columnData = [
                {
                    companyName,
                },
                {
                    jobTitle,
                },
                {
                    city,
                },
                {
                    state,
                },
                {
                    yearsOfExperience,
                },
                {
                    averageAccountSize,
                },
                {
                    aum: AUM,
                },
                {
                    clientNumber: numberOfClients,
                },
                {
                    minAccountSize: accountMinimum,
                },
                {
                    hasInsuranceLicense: healthInsurance,
                },
            ];
        } else {
            columnData = [
                {
                    calendlyUrl: url,
                },
                {
                    calendlyUser: 1,
                },
            ];
        }
        const myInit = {
            body: {
                columnData,
            },
        };
        try {
            await advisorProfileService.update(advisor.professionalId, myInit);
            setSubmitSuccess(true);
            // navigate to calendly page
            if (!submitInfo) {
                window.location = `/app/profile-detail-updates/`;
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDisabled(false);
            setLoading(false);
        }
    }

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            <FormModal
                onClose={() => setShowCalendlyInfo(false)}
                show={showCalendlyInfo}
                title={"Speed up your meeting requests"}
            >
                <CalendlyInfo text={modalText}></CalendlyInfo>
            </FormModal>
            <div className="page-container">
                <div className="register-container left">
                    <div className="logo-container">
                        <a href="https://www.advisorfinder.com" rel="noopener">
                            <img
                                loading="lazy"
                                src="/app/AdFi-logo-name.svg"
                                alt="/AdFi-logo-name.svg"
                                className="register-logo"
                            />
                        </a>
                    </div>

                    {submitSuccess ? (
                        <div className="form-container apply">
                            <img src="/app/images/UserIconImg.svg" alt="userIcon"></img>
                            <h1 className="register-header">Please paste your Calendly URL</h1>
                            <p className="register-paragraph">
                                Why do we need your Calendly URL?
                                <span
                                    className={styles.purpleText}
                                    onClick={() => {
                                        setShowCalendlyInfo(true);
                                    }}
                                >
                                    {" "}
                                    Click here to find out why. <Info size={16} />
                                </span>
                            </p>
                            <form className={styles.calendlyForm} onSubmit={(e) => handleSubmit(e, false)}>
                                <div>Step 1: Go to Calendly</div>
                                <div>Step 2: Click the “Copy Link” button in Calendly</div>
                                <div className="input-container justify-left mobile-padding" style={{ width: "75%" }}>
                                    <label htmlFor="url">Calendly URL</label>
                                    <input
                                        className="input full"
                                        id="url"
                                        type="url"
                                        placeholder="Ex: https://calendly.com/{username}/{meetingName}"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                    />
                                </div>
                                {errors.url && <p className="error-message">{errors.url}</p>}
                                <div className={styles.buttonContainer}>
                                    <Button color="gradient-button" type="submit" disabled={disabled}>
                                        Looks good!
                                        <ArrowRight size={20} />
                                    </Button>
                                    <Button
                                        color="clear-button"
                                        onClick={() => (window.location = `/app/profile-detail-updates/`)}
                                    >
                                        Skip for now
                                        <ArrowRight size={20} />
                                    </Button>
                                </div>
                            </form>
                            {showErrorBanner && (
                                <ErrorBanner onClick={() => setShowErrorBanner(false)}>
                                    Something went wrong. Please try again.
                                </ErrorBanner>
                            )}
                        </div>
                    ) : (
                        <div className="form-container apply">
                            <img src="/app/images/UserIconImg.svg" alt="userIcon"></img>
                            <h1 className="register-header">Confirm Information</h1>
                            <form onSubmit={(e) => handleSubmit(e, true)}>
                                <div className="input-container justify-left mobile-padding">
                                    <label htmlFor="companyName">Company Name</label>
                                    <input
                                        className="input full"
                                        id="companyName"
                                        type="text"
                                        placeholder="Company Name"
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                </div>
                                {errors.companyName && <p className="error-message">{errors.companyName}</p>}
                                <div className="input-container justify-left mobile-padding">
                                    <label htmlFor="companyName">Job Title</label>
                                    <input
                                        className="input full"
                                        id="companyName"
                                        type="text"
                                        placeholder="Job Title"
                                        value={jobTitle}
                                        onChange={(e) => setJobTitle(e.target.value)}
                                    />
                                </div>
                                {errors.jobTitle && <p className="error-message">{errors.jobTitle}</p>}
                                <div className="input-container justify-left" style={{ gap: "5px" }}>
                                    <div className={styles.flexColumn}>
                                        <label htmlFor="city">City</label>
                                        <input
                                            className="input"
                                            placeholder="City"
                                            type="text"
                                            style={{ maxWidth: "350px", minWidth: "300px" }}
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                        />
                                        {errors.city && <p className="error-message">{errors.city}</p>}
                                    </div>
                                    <div className={styles.flexColumn}>
                                        <label htmlFor="state">State</label>
                                        <select
                                            className="input"
                                            value={state}
                                            onChange={(e) => setState(e.target.value)}
                                        >
                                            <option value=""></option>
                                            {states.map((key) => (
                                                <option value={key} key={key}>
                                                    {key}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.state && <p className="error-message">{errors.state}</p>}
                                    </div>
                                </div>
                                <div className="input-container justify-left">
                                    <label htmlFor="yearsOfExperience">Years of Experience</label>
                                    <input
                                        className="input full"
                                        id="yearsOfExperience"
                                        type="number"
                                        min="0"
                                        placeholder="Years Of Experience"
                                        value={yearsOfExperience}
                                        onChange={(e) => setYearsOfExperience(e.target.value)}
                                    />
                                </div>
                                {errors.yearsOfExperience && (
                                    <p className="error-message">{errors.yearsOfExperience}</p>
                                )}

                                <div className="input-container justify-left">
                                    <label htmlFor="yearsOfExperience">Average Account Size</label>
                                    <input
                                        className="input full"
                                        id="averageAccountSize"
                                        type="number"
                                        placeholder="Average Account Size"
                                        value={averageAccountSize}
                                        onChange={(e) => setAverageAccountSize(e.target.value)}
                                    />
                                </div>
                                {errors.averageAccountSize && (
                                    <p className="error-message">{errors.averageAccountSize}</p>
                                )}

                                <div className="input-container justify-left">
                                    <label htmlFor="AUM">What is your AUM? (Optional)</label>
                                    <input
                                        className="input full"
                                        id="aum"
                                        type="number"
                                        placeholder="Enter your AUM"
                                        value={AUM}
                                        onChange={(e) => setAUM(e.target.value)}
                                    />
                                </div>
                                {errors.AUM && <p className="error-message">{errors.AUM}</p>}

                                <div className="input-container justify-left">
                                    <label htmlFor="numberOfClients">How many clients do you have? (Optional)</label>
                                    <input
                                        className="input full"
                                        id="numberOfClients"
                                        type="number"
                                        placeholder="How many clients do you have?"
                                        value={numberOfClients}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => setNumberOfClients(e.target.value)}
                                    />
                                </div>
                                {errors.numberOfClients && <p className="error-message">{errors.numberOfClients}</p>}

                                <div className="input-container justify-left">
                                    <label htmlFor="minAccountSize">Do you have an account minimum? (Optional)</label>
                                    <input
                                        className="input full"
                                        type="number"
                                        placeholder="Please enter your minimum account size"
                                        id="minimumAccountSize"
                                        value={accountMinimum}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setAccountMinimum(e.target.value);
                                        }}
                                    />
                                </div>

                                <div
                                    className="input-container justify-left"
                                    style={{
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "left",
                                    }}
                                >
                                    <label htmlFor="healthInsurance">
                                        Do you have your Health and/or Life Insurance License? (Optional)
                                    </label>
                                    <div className={styles.contactType} onClick={() => setHealthInsurance(true)}>
                                        <input
                                            type="checkbox"
                                            className={styles.inputHorizontalOffset}
                                            value={healthInsurance}
                                            checked={healthInsurance}
                                            onChange={() => setHealthInsurance(true)}
                                        />
                                        Yes
                                    </div>
                                    <div className={styles.contactType} onClick={() => setHealthInsurance(false)}>
                                        <input
                                            type="checkbox"
                                            className={styles.inputHorizontalOffset}
                                            value={healthInsurance}
                                            checked={!healthInsurance}
                                            onChange={() => setHealthInsurance(false)}
                                        />
                                        No
                                    </div>
                                </div>

                                <div className="input-container">
                                    <Button color="gradient-button" type="submit" disabled={disabled}>
                                        Looks good!
                                        <ArrowRight size={20} />
                                    </Button>
                                </div>
                            </form>
                            {showErrorBanner && (
                                <ErrorBanner onClick={() => setShowErrorBanner(false)}>
                                    Something went wrong. Please try again.
                                </ErrorBanner>
                            )}
                        </div>
                    )}
                </div>
                <div className="register-container right">
                    <div className="right-side-text">
                        <h1 className="register-header">Welcome to the modern way of growing your business</h1>
                        <p className="register-paragraph">
                            You're one step away from beginning your digital business development journey. We're excited
                            to help you elevate your entire online presence and start having future clients come to you!
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdvisorConfirmInfo;
