import validator from "validator";

function validateAdvisorPersonality(values) {
    let errors = {};

    if (!values.primaryPrompt) {
        errors.primaryPrompt = "*This prompt is required";
    }

    if (!values.clienteleText) {
        errors.clienteleText = "*This prompt is required";
    }

    if (values.flatFeeMin || values.flatFeeMax) {
        if (!validator.isNumeric(values.flatFeeMin)) {
            errors.flatFeeMin = "*Please enter a valid number";
        }

        if (!validator.isNumeric(values.flatFeeMax)) {
            errors.flatFeeMax = "*Please enter a valid number";
        }

        // if (!values.monthlyChecked && !values.quarterlyChecked && !values.yearlyChecked) {
        //     errors.flatFeeBillingType = "*If a flat fee range is input, please indicate billing frequency";
        // }
    }

    if (values.AUMMin || values.AUMMax) {
        if (!validator.isNumeric(values.AUMMin)) {
            errors.AUMMin = "*Please enter a valid percentage";
        }
        if (!validator.isNumeric(values.AUMMax)) {
            errors.AUMMax = "*Please enter a valid percentage";
        }
    }

    if (values.oneTimePlan && !validator.isNumeric(values.oneTimePlan)) {
        errors.oneTimePlan = "*Please enter a valid number";
    }

    if (values.hourlyRate && !validator.isNumeric(values.hourlyRate)) {
        errors.hourlyRate = "*Please enter a valid number";
    }

    if (!values.averageAccountSize) {
        errors.averageAccountSize = "*Please enter a valid number, this is required";
    } else if (values.averageAccountSize && !validator.isNumeric(values.averageAccountSize)) {
        errors.averageAccountSize = "*Please enter a valid number";
    }

    if (!values.numberOfClients) {
        errors.numberOfClients = "*Please enter a valid number, this is required";
    } else if (values.numberOfClients && !validator.isNumeric(values.numberOfClients)) {
        errors.numberOfClients = "*Please enter a valid number";
    }

    return errors;
}

export default validateAdvisorPersonality;
