import validator from "validator";

function validateScheduleCall(values) {
    let errors = {};

    if (!validator.matches(values.fullName, "^[a-zA-Z-,.' -]*$")) {
        errors.fullName = "Enter valid name";
    }

    if (!validator.isEmail(values.email)) {
        errors.email = "Enter valid email";
    }

    if (!validator.isMobilePhone(values.phone)) {
        errors.phone = "Enter valid phone number";
    }

    if (values.textAreaContent.length > 1000) {
        errors.textAreaContent = "Enter valid text length";
    }

    return errors;
}

export default validateScheduleCall;
